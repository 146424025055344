import React from "react";
import {
  // Button,
  HeaderContainer,
  HeaderDomain,
  HeaderTextContainer,
  HeaderTitle,
  ModalContent,
  ModalContentImage
} from "./modal-content-styles";

export const ModalContentAndroid: React.FC<{}> = () => {
  const hostname = window.location.hostname;

  return (
    <ModalContent>
      <HeaderContainer>
        <ModalContentImage
          alt="my trip companion logo"
          src="/assets/images/my-tc-logo.png"
        />
        <HeaderTextContainer>
          <HeaderTitle>My Trip Companion</HeaderTitle>
          <HeaderDomain>{hostname}</HeaderDomain>
        </HeaderTextContainer>
      </HeaderContainer>
    </ModalContent>
  );
};
