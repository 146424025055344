import React from "react";
import FocusTrap from "focus-trap-react";

interface FocusTrapElementsProps {
  children: React.ReactNode;
  fallbackFocus?: HTMLElement;
}

const FocusTrapElements = ({
  children,
  fallbackFocus = {} as HTMLElement
}: FocusTrapElementsProps) => {
  return (
    <FocusTrap
      data-testid="focustrap"
      focusTrapOptions={{
        fallbackFocus: fallbackFocus, // only for test purposes
        initialFocus: false,
        clickOutsideDeactivates: true
      }}
    >
      {children}
    </FocusTrap>
  );
};

export default FocusTrapElements;
