import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import capitalize from "lodash/capitalize";
import { useTranslate } from "@customer_interactions/i18n";
import { Analytics } from "@customer_interactions/application-logger";
import { serviceToTripStage } from "constant";
import {
  CardContainer,
  ImageContainer,
  TripCardText,
  TripCardTitle,
  GetSupportContainer,
  DropdownContent,
  OpenDropdownButton,
  LabelSpanButton,
  PlusSpanButton
} from "./trip-section-styles";
import { AssistanceService } from "features/configuration/configuration-types";
import { useOutsideClick } from "hooks/use-outside-click";
import FocusTrapElements from "app/components/focus-trap/focus-trap";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { getPrefixKey } from "utils/route-utils";
import { useAuth0 } from "@auth0/auth0-react";
import { TripCardSubSection } from "./trip-card-subsection";

const TripCard: FC<AssistanceService> = ({ id, subsections }) => {
  const t = useTranslate();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const routeName = getPrefixKey(location.pathname);
  const tripStage = serviceToTripStage[id];
  const tripStageCapitalized = capitalize(tripStage);
  const tripStageImgName = `${tripStage?.toLowerCase()}-trip`;
  const [isOpen, setIsOpen] = useState(false);
  const { htmlNodeRef: supportContainerRef } = useOutsideClick(() =>
    setIsOpen(false)
  );

  const closeDropDown = () => {
    setIsOpen(false);
  };

  const onClickDropdownTrigger = (tripstage: string) => {
    Analytics.sendEvent(
      `[customer_space${
        isAuthenticated ? "" : "_pre"
      }_home]trip_${tripstage}_button-click`,
      "Customer_Space"
    );
    setIsOpen(!isOpen);
  };

  return (
    <CardContainer data-testid={`trip-card-container-${id}`}>
      <ImageContainer
        data-testid={`trip-image-container-${id}`}
        imageName={tripStageImgName}
      />
      <TripCardTitle data-testid={`trip-card-title-${id}`}>
        {t(`[${routeName}_Trip]${tripStageCapitalized}_Trip_Title`)}
      </TripCardTitle>
      <TripCardText data-testid={`trip-card-text-${id}`}>
        {t(`[${routeName}_Trip]${tripStageCapitalized}_Trip_Text`)}
      </TripCardText>
      <GetSupportContainer
        ref={supportContainerRef}
        data-testid={`support-container-${tripStageCapitalized}`}
        onKeyDown={e => callbackOnKeyboardEvent(e, "Escape", closeDropDown)}
      >
        <OpenDropdownButton
          data-testid={`trip-card-dropdown-trigger-${id}`}
          onClick={() => onClickDropdownTrigger(tripStage)}
        >
          <LabelSpanButton>
            {t(`[Home_Trip]${tripStageCapitalized}_Trip_Button`)}
          </LabelSpanButton>
          <PlusSpanButton>+</PlusSpanButton>
        </OpenDropdownButton>

        <div>
          {isOpen && !!subsections?.length && (
            <FocusTrapElements fallbackFocus={supportContainerRef.current!}>
              <DropdownContent data-testid={`trip-card-dropdown-content-${id}`}>
                {subsections?.map(subsection => (
                  <TripCardSubSection
                    isAuthenticated={isAuthenticated}
                    tripStageCapitalized={tripStageCapitalized}
                    sectionId={id}
                    subsection={subsection}
                    key={subsection.id}
                  />
                ))}
              </DropdownContent>
            </FocusTrapElements>
          )}
        </div>
      </GetSupportContainer>
    </CardContainer>
  );
};

export default TripCard;
