import { ActionStandard } from "app/store/types";
import { handle } from "utils/reducer-handler";
import { Mapping } from "utils/types";
import { SET_TOASTER } from "./toaster-actions";
import { ToasterState } from "./toaster-types";

const initialState = {
  show: false
};

const toasterStateHandlers: Mapping<Function> = {
  [SET_TOASTER]: (_state: ToasterState, action: ActionStandard<ToasterState>) =>
    action.payload
};

const reducer = (state = initialState, action: ActionStandard<boolean>) =>
  handle(state, action, toasterStateHandlers);

export default reducer;
