import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const ArrowDownIcon = ({ theme, ...props }: IconProps) => (
  <svg
    name="arrow-down-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.175 9L12 12.7085L15.825 9L17 10.1417L12 15L7 10.1417L8.175 9Z"
      fill={theme.blue["300"]}
    />
  </svg>
);

export default withTheme(ArrowDownIcon);
