import React from "react";
import {
  DescriptionCard,
  DescriptionCardText,
  DescriptionCardTitle
} from "./help-center-styles";

type HelpCenterCardProps = {
  title: string;
  description: string;
};

export const HelpCenterCard = ({ title, description }: HelpCenterCardProps) => (
  <DescriptionCard>
    <DescriptionCardTitle>{title}</DescriptionCardTitle>
    <DescriptionCardText>{description}</DescriptionCardText>
  </DescriptionCard>
);
