import { useTranslate } from "@customer_interactions/i18n";

import React from "react";
import { useFaqs } from "./use-faqs";
import { Spinner } from "app/components/loading-screen/spinner";
import { FaqsErrorContainer, FaqsErrorText } from "./faqs-styles";
import AlertIcon from "app/components/icons/alert-icon";
import { selectUseAlternativeHomepage } from "features/configuration/selectors/configuration-selectors";
import { useSelector } from "react-redux";

export const FaqsPage = () => {
  const t = useTranslate();
  const { currentCustomLanguage, isLoaded, error, trackers, theme } = useFaqs();
  const isRoutingPage = useSelector(selectUseAlternativeHomepage);

  if (error) {
    return (
      <FaqsErrorContainer>
        <AlertIcon />
        <FaqsErrorText>{t("[faq]_content_error")}</FaqsErrorText>
      </FaqsErrorContainer>
    );
  }

  return isLoaded ? (
    <faqs-landing
      data-testid="faqs-landing"
      contact_us={t("[Landing_footer]Contact_us_url")}
      language_code={currentCustomLanguage}
      theme={JSON.stringify(theme)}
      ga_trackings={JSON.stringify(trackers)}
      sections={isRoutingPage ? undefined : JSON.stringify(["customer_space"])}
      disable_focus_on_load={true}
    ></faqs-landing>
  ) : (
    <Spinner />
  );
};
