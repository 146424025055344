import React, { useRef } from "react";
import { Analytics } from "@customer_interactions/application-logger";
import { useTranslate } from "@customer_interactions/i18n";
import { subsectionIdToTagName } from "constant";
import CTAButton from "app/components/shared/cta-button/cta-button";
import { AssistanceServiceSubsection } from "features/configuration/configuration-types";
import {
  AssistanceServiceLoginButton,
  SubsectionItemCard,
  SubsectionItemText,
  SubsectionItemTitle
} from "./assistance-service-subsection-item-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoginModal } from "../shared/login-modal/use-login-modal";
import { withLoginModal } from "../shared/login-modal/withLoginModal";
import LoginIcon from "../icons/login-icon";
import { trackFeatureNavigationEvent } from "features/analytics-events/track-feature-navigation-event";
import { isUrlThatRequireLogin } from "pages/home/constants";

type SubsectionItemProps = AssistanceServiceSubsection & {
  sectionId: string;
};

export const SubsectionItem = ({
  id,
  external,
  sectionId
}: SubsectionItemProps) => {
  const t = useTranslate();
  const { shouldRequireLogin } = useLoginModal();

  const description = t(
    `[Home_Assistance_service]${subsectionIdToTagName[id]}_text`
  );
  const title = t(
    `[Home_Assistance_service]${subsectionIdToTagName[id]}_title`
  );
  const url = t(
    `[Home_Assistance_service]${subsectionIdToTagName[id]}_button_url`
  );

  const trackSubsectionNavigation = () => {
    trackFeatureNavigationEvent({
      zone: "assistance services",
      elementClicked: id,
      serviceCategory: sectionId,
      requiresLogin: isUrlThatRequireLogin(url),
      isAuthenticated
    });
  };

  const requiresLogin = shouldRequireLogin(url);

  const buttonName = `subsection-item-button-${id}`;
  const { isAuthenticated } = useAuth0();
  const containerRef = useRef(null);
  const onClickButton = () => {
    Analytics.sendEvent(
      `[customer_space${isAuthenticated ? "" : "_pre"}_home]${id}_button-click`,
      "Customer_Space"
    );

    trackSubsectionNavigation();
  };
  const onLoginButtonClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e && e.preventDefault();
    Analytics.sendEvent(
      `[customer_space_pre_home] login_button-click`,
      "Customer_Space"
    );

    trackSubsectionNavigation();
  };

  const ButtonWithLoginModal = withLoginModal(AssistanceServiceLoginButton);

  return (
    <SubsectionItemCard data-testid={`subsection-item-container-${id}`}>
      <SubsectionItemTitle data-testid={`subsection-item-title-${id}`}>
        {title}
      </SubsectionItemTitle>
      <SubsectionItemText data-testid={`subsection-item-text-${id}`}>
        {description}
      </SubsectionItemText>
      {requiresLogin ? (
        <ButtonWithLoginModal
          translationKey="[Home_Assistance_service]Login_button"
          onClick={onLoginButtonClick}
          to={url}
          name={buttonName}
          innerRef={containerRef}
        >
          <LoginIcon inverted />
        </ButtonWithLoginModal>
      ) : (
        <CTAButton
          onClick={onClickButton}
          name={buttonName}
          to={url}
          isExternal={external}
          translationKey={`[Home_Assistance_service]${subsectionIdToTagName[id]}_button`}
          variant="outlined"
        />
      )}
    </SubsectionItemCard>
  );
};
