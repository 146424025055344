import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";

export const trackStartAuthenticationJourneyFromZone = (
  zone: "header" | "popup - log in"
) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.START_AUTHENTICATION_JOURNEY,
    element_clicked: "log in",
    zone
  });
};
