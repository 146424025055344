import { State } from "../../app/store/types";
import { RequireLoginState } from "./require-login-reducer";

export const selectRequireLoginSlice = (state: State): RequireLoginState =>
  state.requireLogin;

export const selectShowModal = (state: State): boolean =>
  selectRequireLoginSlice(state).showModal;

export const selectUrl = (state: State): string =>
  selectRequireLoginSlice(state).url;
