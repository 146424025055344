import { useState, useEffect, useCallback } from "react";

export const useSafetyAndAlerts = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowsSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  const RISKLINE_MOBILE_BREAKPOINT = 765;

  const isMobileForRiskline: boolean = width <= RISKLINE_MOBILE_BREAKPOINT;

  useEffect(() => {
    window.addEventListener("resize", handleWindowsSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowsSizeChange);
    };
  }, []);

  const widgetHeight = isMobileForRiskline ? "1220px" : "650px";

  return { widgetHeight };
};
