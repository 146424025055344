import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";

type NavigationEventOptions = {
  zone: string;
  elementClicked: string;
  serviceCategory: string;
  isAuthenticated?: boolean;
  requiresLogin?: boolean;
};

const mapCategory = {
  travel: "information",
  medical: "medical support",
  claim: "online claim"
};

const mapElementClicked = {
  country: "Country Information",
  tips: "Travel Tips",

  nurse: "nurse",
  speak: "Speak to a Doctor",
  locate: "Find a health professional",
  emergency: "Find public emergency services",

  notify: "Log your claim"
};

function getElementClicked(
  elementClickedName: string,
  isAuthenticated: boolean = false,
  requiresLogin: boolean = false
) {
  if (!requiresLogin) {
    return elementClickedName;
  }

  if (!isAuthenticated) {
    return `${elementClickedName} - not logged`;
  }

  return `${elementClickedName} - logged`;
}

export const trackFeatureNavigationEvent = ({
  zone,
  elementClicked,
  serviceCategory,
  requiresLogin,
  isAuthenticated
}: NavigationEventOptions) => {
  const elementClickedName = getElementClicked(
    mapElementClicked[elementClicked as keyof typeof mapElementClicked],
    isAuthenticated,
    requiresLogin
  );

  Analytics.sendEventData({
    event: EVENT_NAMES.NAVIGATION,
    zone,
    element_clicked: elementClickedName,
    service_category: mapCategory[serviceCategory as keyof typeof mapCategory]
  });
};
