import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import { getQueryParamValue } from "utils/query-params-utils";
import { sanitizeObject } from "utils/sanitize-object";
import {
  GET_URL_PARAMETERS,
  setUrlParameters
} from "../actions/url-parameters-actions";
import { IUrlParameters } from "../url-parameters";

const LOCAL_STORAGE_KEY = "urlParams";
const BRAND = "Brand";
const POLICY_NUMBER = "policyNumber";
const PHONE_NUMBER = "phoneNumber";

export function* getUrlParameters(): SagaIterator {
  try {
    const queryParamsFromLS = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEY) || "{}"
    );
    const storedQueryParams: Partial<IUrlParameters> = sanitizeObject({
      brand: queryParamsFromLS?.brand,
      policyNumber: queryParamsFromLS?.policyNumber,
      phoneNumber: queryParamsFromLS?.phoneNumber
    });

    const queryParams: Partial<IUrlParameters> = sanitizeObject({
      brand: getQueryParamValue(BRAND),
      policyNumber: getQueryParamValue(POLICY_NUMBER),
      phoneNumber: getQueryParamValue(PHONE_NUMBER)
    });

    const mergedQueryParams = { ...storedQueryParams, ...queryParams };

    const hasAnyDefinedProp = Object.keys(mergedQueryParams).length > 0;

    if (!hasAnyDefinedProp) {
      return;
    }

    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(mergedQueryParams)
    );
    yield put(setUrlParameters(mergedQueryParams));
  } catch (error) {
    console.log(error);
  }
}

export default function* main() {
  yield takeLatest(GET_URL_PARAMETERS, getUrlParameters);
}
