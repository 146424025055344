import { ActionStandard } from "app/store/types";
import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";
import { SET_URL_PARAMETERS } from "../actions/url-parameters-actions";
import { IUrlParameters } from "../url-parameters";

const urlParametersStateHandlers: Mapping<Function> = {
  [SET_URL_PARAMETERS]: (
    _state: Partial<IUrlParameters>,
    _action: ActionStandard<Partial<IUrlParameters>>
  ) => ({
    ..._state,
    ..._action.payload
  })
};

export const urlParametersReducer = (
  state: Partial<IUrlParameters> = {},
  action: ActionStandard<Partial<IUrlParameters>>
) => {
  return handle(state, action, urlParametersStateHandlers);
};
