import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ToasterContainer,
  ToasterIcon,
  ToasterMessage,
  getIconByCategory
} from "./toaster-styles";
import { selectToasterState } from "./toaster-selectors";
import { ANIMATION, setToaster, TOASTER_CATEGORY } from ".";

export const setSuccessToaster = (message: string) =>
  setToaster({
    show: true,
    category: TOASTER_CATEGORY.SUCCESS,
    message
  });

export const Toaster = () => {
  const dispatch = useDispatch();
  const { show, category = "", message = "" } = useSelector(selectToasterState);

  const onClose = useCallback(() => {
    dispatch(setToaster({ show: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!show) {
      return;
    }

    setTimeout(onClose, ANIMATION.DEFAULT_DELAY_DURATION);
  }, [show, onClose]);

  return show ? (
    <ToasterContainer
      data-testid="toaster"
      category={category}
      onClick={onClose}
    >
      <ToasterIcon>{getIconByCategory(category)}</ToasterIcon>
      <ToasterMessage>{message}</ToasterMessage>
    </ToasterContainer>
  ) : null;
};

export default Toaster;
