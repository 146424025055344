import React from "react";
import { useCulturaGuides } from "./use-cultura-guides";
import {
  CulturaGuidesDisclaimerContainer,
  CulturaGuidesDisclaimerTitle,
  CulturaGuidesDisclaimerText,
  CulturaGuidesContainer
} from "./cultura-guides-styles";
import { useTranslate } from "@customer_interactions/i18n";
import { useTheme } from "styled-components";
import { Theme } from "styles";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "culturago-culture-guides": unknown;
    }
  }
}

const CultureGuides: React.FC = () => {
  const t = useTranslate();
  const theme = useTheme() as Theme;
  const { isLoaded, languagueCode, brandId } = useCulturaGuides();

  if (!isLoaded) {
    return null;
  }

  return (
    <CulturaGuidesContainer>
      <culturago-culture-guides
        style={{
          width: "100%",
          display: "block"
        }}
        brand-id={brandId}
        language-code={languagueCode}
        font-family-heading={theme.primaryFontFamily}
        font-family-body={theme.secondaryFontFamily}
      />
      <CulturaGuidesDisclaimerContainer>
        <CulturaGuidesDisclaimerTitle>
          {t("[Country_Guides]Disclaimer_bold")}
        </CulturaGuidesDisclaimerTitle>
        <CulturaGuidesDisclaimerText>
          {t("[Country_Guides]Disclaimer_text")}
        </CulturaGuidesDisclaimerText>
      </CulturaGuidesDisclaimerContainer>
    </CulturaGuidesContainer>
  );
};

export default CultureGuides;
