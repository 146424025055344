import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const SherpaContainerMobile = css`
  margin: 40px auto;
`;

export const SherpaContainer = styled.div`
  margin: 0 129px;
  ${getCssForMobileView(SherpaContainerMobile)}
`;
