import React from "react";
import SpinnerIcon from "../icons/spinner-icon";
import { SpinnerContainer, Rotate } from "./loading-styles";

export const Spinner = () => (
  <SpinnerContainer data-testid="loading-spinner">
    <Rotate>
      <SpinnerIcon />
    </Rotate>
  </SpinnerContainer>
);
