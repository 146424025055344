import { ActionStandard } from "app/store/types";
import { IUrlParameters } from "../url-parameters";

const URL_PARAMETERS = "URL_PARAMETERS";
export const GET_URL_PARAMETERS: string = `${URL_PARAMETERS}/GET_URL_PARAMETERS`;
export const SET_URL_PARAMETERS: string = `${URL_PARAMETERS}/SET_URL_PARAMETERS`;

export const getUrlParameters = (): ActionStandard<Partial<
  IUrlParameters
>> => ({
  type: GET_URL_PARAMETERS
});

export const setUrlParameters = (
  payload: Partial<IUrlParameters>
): ActionStandard<Partial<IUrlParameters>> => ({
  type: SET_URL_PARAMETERS,
  payload
});
