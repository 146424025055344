import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const ArrowUpIcon = ({ theme, ...props }: IconProps) => (
  <svg
    name="arrow-up-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.825 15L12 11.2915L8.175 15L7 13.8583L12 9L17 13.8583L15.825 15Z"
      fill={theme.blue["300"]}
    />
  </svg>
);
export default withTheme(ArrowUpIcon);
