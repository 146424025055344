import { State } from "app/store/types";

export const selectUrlParameters = (state: State) => state.urlParameters;

export const selectUrlParametersPolicyNumber = (state: State): string =>
  selectUrlParameters(state).policyNumber || "";

export const selectUrlParametersBrand = (state: State): string =>
  selectUrlParameters(state).brand || "";

export const selectUrlParametersPhoneNumber = (state: State): string =>
  selectUrlParameters(state).phoneNumber || "";
