import { useCallback, useEffect, useState } from "react";

export const useScriptLoader = (
  url: string,
  id: string = "external-script"
) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const load = useCallback(() => {
    const existingScript = document.getElementById(id);
    if (existingScript) {
      setIsLoaded(true);
    } else {
      const script = document.createElement("script");
      script.src = url;
      script.id = id;
      script.onload = () => {
        setIsLoaded(true);
      };
      script.onerror = () => {
        setError(true);
      };

      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      load();
    }
  }, [isLoaded, load]);

  return { isLoaded, error };
};
