import { routes } from "constant";
import { RedirectUrlsMap } from "features/configuration/configuration-types";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import {
  selectUrlParametersBrand,
  selectUrlParametersPolicyNumber
} from "features/url-parameters/selectors/url-parameters-selectors";
import { useSelector } from "react-redux";
import { getURIOrigin } from "utils/route-utils";
import { getWindowOrigin } from "utils/window-utils";

type LogoutParameters = {
  returnTo: string;
  client_id: string;
};

const RETURN_TO_SEARCH_PARAM = "returnTo";
const CLIENT_ID_SEARCH_PARAM = "client_id";

// logout and return to FREND auth platform
const buildFrendLogoutParameters = (clientId: string): LogoutParameters => ({
  returnTo: `${getURIOrigin()}${routes.ROOT}`,
  client_id: clientId
});

// logout from FREND and also logout from SSO provider
const buildLogoutParametersWithSSOCredentials = (
  clientId: string,
  ssoUrl?: string,
  ssoClientId?: string
): LogoutParameters | null => {
  const windowOrigin = getWindowOrigin();

  if (!ssoUrl || !ssoClientId) {
    return null;
  }

  const returnToUrl = new URL(`${ssoUrl}/logout`);
  returnToUrl.searchParams.append(
    RETURN_TO_SEARCH_PARAM,
    windowOrigin + routes.ROOT
  );
  returnToUrl.searchParams.append(CLIENT_ID_SEARCH_PARAM, ssoClientId);

  return {
    returnTo: returnToUrl.toString(),
    client_id: clientId
  };
};

// logout from FREND and go to brand specific URL
const buildLogoutParametersWithSSOReturnUrls = (
  brand: string,
  logoutReturnUrls: RedirectUrlsMap,
  clientId: string,
  queryParameters = ""
): LogoutParameters | null => {
  const brandUrl = logoutReturnUrls[brand];
  if (!brandUrl) {
    return null;
  }

  return {
    returnTo: `${brandUrl}${queryParameters}`,
    client_id: clientId
  };
};

export function useBuildLogoutParameters(): LogoutParameters | null {
  const { auth0ClientId, ssoConfig } = useSelector(selectConfiguration);
  const brandName = useSelector(selectUrlParametersBrand);

  if (!ssoConfig) {
    return buildFrendLogoutParameters(auth0ClientId);
  }

  if (ssoConfig?.logoutReturnUrls) {
    const logOutReturnUrls = ssoConfig?.logoutReturnUrls;
    const policyNumberParameter = useSelector(selectUrlParametersPolicyNumber);
    const queryParameters = policyNumberParameter
      ? `?pno=${policyNumberParameter}`
      : "";
    return buildLogoutParametersWithSSOReturnUrls(
      brandName,
      logOutReturnUrls,
      auth0ClientId,
      queryParameters
    );
  }

  return buildLogoutParametersWithSSOCredentials(
    auth0ClientId,
    ssoConfig?.ssoUrl,
    ssoConfig?.clientId
  );
}
