import { Analytics } from "@customer_interactions/application-logger";
import {
  EVENT_NAMES,
  GLOBAL_DIGITAL_ASSET,
  getCountry,
  getEnvironmentName
} from "./common";
import { State } from "app/store/types";

export const trackCompletedAuthenticationJourneyEvent = (
  authentification_type: "registration" | "log in",
  state: State
) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.COMPLETED_AUTHENTICATION_JOURNEY,
    authentification_type,
    global_digital_asset: GLOBAL_DIGITAL_ASSET,
    instance_environment: getEnvironmentName(),
    country: getCountry(state)
  });
};
