import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const AssistanceContainerMobileStyles = css`
  flex-direction: column;
  order: unset;
  background-color: transparent;
  padding: 0px;
`;

export const AssistanceContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  width: 100vw;
  ${getCssForMobileView(AssistanceContainerMobileStyles)}
`;

export const AssistanceMainContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  ${getCssForMobileView(css`
    margin: 0px auto 30px;
  `)}
`;
