import { ActionStandard } from "app/store/types";

import { QueryParameters } from "../query-parameters-types";

export const SET_QUERY_PARAMETERS = "SET_QUERY_PARAMETERS";

export const setQueryParameters = (
  payload: Partial<QueryParameters>
): ActionStandard<Partial<QueryParameters>> => ({
  type: SET_QUERY_PARAMETERS,
  payload
});
