import React, { useEffect } from "react";
import { I18nProvider } from "@customer_interactions/i18n";
import { StylesProvider } from "app/components/styles-provider";
import { ApplicationRouter } from "app/components/application-router";
import { initApp } from "app/app-actions";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { selectCurrentLanguage } from "features/language/selectors/language-selector";
import { HeadTags } from "app/components/head-tags/head-tags";
import { Toaster } from "features/toaster";
import { LANGUAGE_DEBUG_MODE_CODE, useDebugMode } from "features/debug-mode";

const App = () => {
  const dispatch = useDispatch();
  const { isDebuggable, isDebugMode } = useDebugMode();
  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(() => {
    dispatch(initApp());
  }, []);

  useEffect(() => {
    window.sessionStorage.removeItem("LoadingSubtitle");
  }, []);

  return (
    <I18nProvider
      languageCode={
        isDebuggable && isDebugMode ? LANGUAGE_DEBUG_MODE_CODE : currentLanguage
      }
      path={`${location.origin}/locales`}
    >
      <HeadTags />
      <StylesProvider>
        <BrowserRouter>
          <ApplicationRouter />
          <Toaster />
        </BrowserRouter>
      </StylesProvider>
    </I18nProvider>
  );
};

export default App;
