import { useState, useEffect } from "react";
import { LiveChat } from "./live-chat";
import {
  selectLiveChatEnabled,
  selectLiveChatWidgetId
} from "features/configuration/selectors/configuration-selectors";
import { useSelector } from "react-redux";
import {
  getChatZoneByRoute,
  trackStartChat
} from "features/analytics-events/track-start-chat-event";
import { useLocation } from "react-router-dom";

const chatUrl = process.env.REACT_APP_LIVE_CHAT_URL!;

type LiveChatStatus =
  | "idle"
  | "loading"
  | "ready"
  | "authenticated"
  | "error"
  | "loaded";

const isEmpty = (str: string) => str.length === 0;

type LiveChatHook = {
  status: LiveChatStatus;
  isEnabled: boolean;
  widgetId: string;
};

export const useLiveChat = (): LiveChatHook => {
  const isEnabled = useSelector(selectLiveChatEnabled);
  const widgetId = useSelector(selectLiveChatWidgetId);
  const [status, setStatus] = useState<LiveChatStatus>("idle");
  const location = useLocation();

  useEffect(() => {
    return () => {
      LiveChat.stop(document.head);
    };
  }, []);

  useEffect(() => {
    if (isEnabled && status === "idle") {
      if (isEmpty(widgetId)) {
        console.warn(
          "LiveChat is enabled but widgetId is missing in the configuration."
        );
        return;
      }

      setStatus("loading");

      LiveChat.init(
        document.head,
        chatUrl,
        widgetId,
        () => {
          setStatus("loaded");
        },
        error => {
          if (error) {
            setStatus("error");
          } else {
            setStatus("ready");
          }
        }
      );
    }
  }, [isEnabled, widgetId, status]);

  useEffect(() => {
    const liveChatButton = document.querySelector(".lc-widget-button-icon");

    const onChatClicked = () => {
      trackStartChat("chat_icon", getChatZoneByRoute(location?.pathname));
    };

    if (liveChatButton) {
      liveChatButton.addEventListener("click", onChatClicked);
    }

    return () => {
      if (liveChatButton) {
        liveChatButton.removeEventListener("click", onChatClicked);
      }
    };
  }, [status, location]);

  return { status, isEnabled, widgetId };
};
