import { Analytics } from "@customer_interactions/application-logger";
import { useTranslate } from "@customer_interactions/i18n";
import { selectDigitalCardConfig } from "features/configuration/selectors/configuration-selectors";
import { useWindowSize } from "hooks/use-window-size";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPrefixKey } from "utils/route-utils";
import { Timeline } from "../shared/timeline/timeline";
import {
  CardSection,
  DigitalCardContainer,
  DigitalCardImage,
  DigitalCardQrCode,
  DigitalCardTitle,
  DownloadButton,
  DownloadContainer,
  DownloadLink,
  DownloadText,
  MainContainer,
  StepsContainer,
  StepSubtitle,
  StepTitle,
  StepsContainerHeader
} from "./digital-card-styles";

const DigitalCard = () => {
  const t = useTranslate();
  const { isMobile } = useWindowSize();
  const { pathname } = useLocation();
  const routeName = getPrefixKey(pathname);
  const { imageURL, qrImageURL } = useSelector(selectDigitalCardConfig);
  const stepItems = [
    {
      title: t(`[${routeName}_TravelCard]Step1`),
      text: t(`[${routeName}_TravelCard]Step1-minitext`)
    },
    {
      title: t(`[${routeName}_TravelCard]Step2`),
      text: t(`[${routeName}_TravelCard]Step2-minitext`)
    },
    {
      title: t(`[${routeName}_TravelCard]Step3`),
      text: t(`[${routeName}_TravelCard]Step3-minitext`)
    }
  ];
  const showQrCode = Boolean(qrImageURL);

  const handleDownloadClick = () => {
    Analytics.sendEvent(
      "[customer_space_home] travelcard-download-click",
      "Customer_Space"
    );
  };

  return (
    <MainContainer data-testid="digital-card-main-container">
      <CardSection>
        <DigitalCardContainer>
          <DigitalCardTitle>
            {t(`[${routeName}_TravelCard]Title`)}
          </DigitalCardTitle>
          <DigitalCardImage
            alt={t("[accessibility]Alt-TravelCard-Image")}
            data-testid="digital-card-image"
            src={imageURL}
          />
        </DigitalCardContainer>
      </CardSection>
      <CardSection>
        <StepsContainer data-testid="digital-card-steps-container">
          <StepsContainerHeader>
            <StepTitle>{t(`[${routeName}_TravelCard]Text`)}</StepTitle>
            <StepSubtitle>
              {t(`[${routeName}_TravelCard]Subtitle`)}
            </StepSubtitle>
          </StepsContainerHeader>
          <Timeline items={stepItems} />
          {isMobile ? (
            <DownloadButton
              to={t("[Home_TravelCard]Download-url")}
              onClick={handleDownloadClick}
              target="_blank"
              data-testid="download-digital-card-mobile"
            >
              {t("[Home_TravelCard]Download-button")}
            </DownloadButton>
          ) : (
            <DownloadContainer data-testid="download-text-container">
              {showQrCode && (
                <DigitalCardQrCode
                  alt={t("[accessibility]Alt-TravelCard-QrCode")}
                  src={qrImageURL}
                />
              )}
              <DownloadText>
                {t("[Home_TravelCard]Download-text")}
                &nbsp;
                <DownloadLink
                  onClick={handleDownloadClick}
                  to={t("[Home_TravelCard]Download-url")}
                  target="_blank"
                  data-testid="download-digital-card-desktop"
                >
                  {t("[Home_TravelCard]Download-url-label")}
                </DownloadLink>
              </DownloadText>
            </DownloadContainer>
          )}
        </StepsContainer>
      </CardSection>
    </MainContainer>
  );
};

export default DigitalCard;
