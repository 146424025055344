import { subsectionIdToTagName } from "constant";
import { AssistanceServiceSubsection } from "features/configuration/configuration-types";
import LoginIcon from "app/components/icons/login-icon";
import { useTranslate } from "@customer_interactions/i18n";
import { Analytics } from "@customer_interactions/application-logger";
import React from "react";
import { MenuItem as Item } from "./menu-expanded-styles";
import useMenuHeader from "hooks/use-menu-header";
import { useWindowSize } from "hooks/use-window-size";
import { SubItem } from "app/components/header-mobile/menu-mobile/menu-mobile-assistance-service/menu-mobile-assistance-service-styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoginModal } from "app/components/shared/login-modal/use-login-modal";
import { withLoginModal } from "app/components/shared/login-modal/withLoginModal";
import { trackFeatureNavigationEvent } from "features/analytics-events/track-feature-navigation-event";
import { isUrlThatRequireLogin } from "pages/home/constants";

export interface MenuExpandedItemProps {
  sectionId: string;
  subsection: AssistanceServiceSubsection;
}

export const MenuItem = ({ sectionId, subsection }: MenuExpandedItemProps) => {
  const { isMobile } = useWindowSize();
  const t = useTranslate();
  const dataTestId = `home-${isMobile ? "mobile-" : ""}menu-subsection-${
    subsection.id
  }`;
  const { closeMenu } = useMenuHeader();
  const { isAuthenticated } = useAuth0();
  const { shouldRequireLogin } = useLoginModal();

  const title = t(
    `[Home_Assistance_service]${subsectionIdToTagName[subsection.id]}_title`
  );
  const url = t(
    `[Home_Assistance_service]${
      subsectionIdToTagName[subsection.id]
    }_button_url`
  );

  const requiresLogin = shouldRequireLogin(url);

  const onClick = () => {
    Analytics.sendEvent(
      `[customer_space${isAuthenticated ? "" : "_pre"}_home]menu_${
        subsection.id
      }_button-click`,
      "Customer_Space"
    );

    trackFeatureNavigationEvent({
      zone: "menu",
      elementClicked: subsection.id,
      serviceCategory: sectionId,
      isAuthenticated,
      requiresLogin: isUrlThatRequireLogin(url)
    });

    closeMenu();
  };

  const buttonType = requiresLogin ? "secondary" : "primary";
  const loginIcon = requiresLogin ? <LoginIcon /> : undefined;

  const ItemWithLoginModal = isMobile
    ? withLoginModal(SubItem)
    : withLoginModal(Item);
  return (
    <>
      <ItemWithLoginModal
        key={subsection.id}
        data-testid={dataTestId}
        isExternalService={subsection.external}
        onClick={onClick}
        to={url}
        variant={buttonType}
      >
        {title}
        {loginIcon}
      </ItemWithLoginModal>
    </>
  );
};
