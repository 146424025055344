import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { useSelector } from "react-redux";
import { getBrowserName, getOsName } from "utils/user-agent";

export const useIsInstallModalSupported = () => {
  const osName = getOsName();
  const browserName = getBrowserName();
  const { manifestId } = useSelector(selectConfiguration);
  return !!osName && !!browserName && !!manifestId;
};
