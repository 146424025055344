import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
  config: { attributes: true, subtree: true }
};

export const useMutationObserver = (
  targetEl: HTMLObjectElement | null,
  callback: (mutationList: MutationRecord[]) => void,
  options = DEFAULT_OPTIONS
) => {
  const [observer, setObserver] = useState<MutationObserver>();
  useEffect(() => {
    const obs: MutationObserver = new MutationObserver(callback);
    setObserver(obs);
  }, [callback, options, setObserver]);

  useEffect(() => {
    const { config } = options;
    if (observer && targetEl) {
      observer.observe(targetEl, config);

      return () => {
        observer.disconnect();
      };
    }
  }, [targetEl, options, observer]);
};
