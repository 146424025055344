import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

interface PageHeaderContainerProps {
  URLDesktop: string;
  URLMobile: string;
}

const getPageHeaderContainerMobile = (URLMobile: string) =>
  getCssForMobileView(css`
    background: url("${URLMobile}") no-repeat center
      center;
    background-size: cover;
    color: ${({ theme }) => theme.grey["800"]};
    padding-left: 0px;
    padding-right: 0px;
  `);

export const PageHeaderContainer = styled.header<PageHeaderContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 260px;
  padding-left: 202px;
  padding-right: 133px;
  color: ${({ theme }) => theme.white};
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 29.53%,
      rgba(0, 0, 0, 0.25) 76.61%
    ),
    url("${({ URLDesktop }) => URLDesktop}") no-repeat center center;
  background-size: cover;
  mix-blend-mode: darken;
  opacity: 0.95;
  ${({ URLMobile }) => getPageHeaderContainerMobile(URLMobile)};
`;

const HeaderTitleMobile = getCssForMobileView(css`
  font-size: 36px;
  line-height: 42px;
`);

export const HeaderTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  ${HeaderTitleMobile}
`;

const HeaderSubtitleMobile = getCssForMobileView(css`
  color: ${({ theme }) => theme.grey["800"]};
`);

export const HeaderSubtitle = styled.h3`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  ${HeaderSubtitleMobile}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 90%;
  top: 100px;
  margin: auto;
  position: relative;
  padding: 15px;
`;

export const HeaderTextContainer = styled.div`
  flex: 1;
`;
