import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { LinkResolver } from "app/components/link-resolver/link-resolver";

const FaqsButtonContainerMobile = css`
  margin-right: 0px;
  padding: 22px;
  box-shadow: inset 0px -1px 0px #f0f0f0;
`;

export const FaqsButtonContainer = styled(LinkResolver)`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.blue["300"]};
  margin-right: 32px;
  text-decoration: none;
  ${getCssForMobileView(FaqsButtonContainerMobile)}
`;

export const FaqsButtonText = styled.div`
  margin-left: 16px;
`;
