import styled from "styled-components";

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  padding: 8px 16px;
`;

export const ModalContent = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 0;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const HeaderDomain = styled.span`
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
`;

export const InstructionsContainer = styled.ol`
  padding: 4px 0 16px 16px;
`;

export const ModalContentImage = styled.img``;

export const ScreenshotsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  & > img {
    width: calc(100% / 3 - 8px);
    object-fit: contain;
  }
`;
