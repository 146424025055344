import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const AssistanceServiceMainSection = styled.section``;

const DescriptionMobile = css`
  font-size: 16px;
  line-height: 21px;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  ${getCssForMobileView(DescriptionMobile)};
`;
