import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const BagaggeIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="52"
    height="50"
    viewBox="0 0 52 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.0836 37.5012H16.9125V18.6568H20.0836V37.5012ZM26.9543 37.5012H23.7832V18.6568H26.9543V37.5012ZM33.825 37.5012H30.6539V18.6568H33.825V37.5012ZM35.939 12.3753H31.7109V6.09382C31.7109 4.94222 30.7596 4 29.5969 4H21.1406C19.9779 4 19.0266 4.94222 19.0266 6.09382V12.3753H14.7984C12.473 12.3753 10.5703 14.2597 10.5703 16.5629V39.595C10.5703 41.8982 12.473 43.7827 14.7984 43.7827C14.7984 44.9343 15.7498 45.8765 16.9125 45.8765C18.0752 45.8765 19.0266 44.9343 19.0266 43.7827H31.7109C31.7109 44.9343 32.6623 45.8765 33.825 45.8765C34.9877 45.8765 35.939 44.9343 35.939 43.7827C38.2645 43.7827 40.1672 41.8982 40.1672 39.595V16.5629C40.1672 14.2597 38.2645 12.3753 35.939 12.3753ZM22.1976 7.14074H28.5398V12.3753H22.1976V7.14074ZM35.939 39.595H14.7984V16.5629H35.939V39.595Z"
      fill={theme.teal["200"]}
    />
  </svg>
);

export default withTheme(BagaggeIcon);
