import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@customer_interactions/i18n";
import {
  FlexContainer,
  Title,
  Text,
  MailboxIconStyled,
  TextContainer,
  ImageContainer,
  Strong,
  ButtonContainer
} from "./email-not-verified-styles";
import { Analytics } from "@customer_interactions/application-logger";
import { routes } from "constant";
import { useHistory } from "react-router-dom";
import { Button } from "app/components/shared/button";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";

export const GO_TO_HOME_EVENT_ZONE = "confirm email address";
export const GO_TO_HOME_EVENT_ELEMENT = "go to home page";

const EmailNotVerified = () => {
  const { user } = useAuth0();
  const t = useTranslate();
  const history = useHistory();
  const buttonText = t("[verify_mail]home_button");

  const handleGoHomeClick = () => {
    Analytics.sendEvent(
      `[Customer_space_verify] home_Button-click`,
      "Customer_Space"
    );

    trackNavigationEvent(GO_TO_HOME_EVENT_ZONE, GO_TO_HOME_EVENT_ELEMENT);

    history.replace(routes.HOME);
  };

  return (
    <FlexContainer>
      <ImageContainer>
        <MailboxIconStyled />
        <Title>{t("[verify_mail]title")}</Title>
      </ImageContainer>
      <TextContainer>
        <Text data-testid="email-not-verified-text">
          {t("[verify_mail]text_part_1")}
          <Strong>{` ${user?.email || ""} `}</Strong>
          {t("[verify_mail]text_part_2")}
        </Text>
        <Text>{t("[verify_mail]text_valid_time")}</Text>

        <ButtonContainer>
          <Button
            onClick={handleGoHomeClick}
            data-testid="go-home-button"
            variant="outlined"
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </TextContainer>
    </FlexContainer>
  );
};

export default EmailNotVerified;
