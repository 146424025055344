import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const WorldIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM7.19912 14.343C4.03912 13.951 1.59912 11.263 1.59912 7.99896C1.59912 7.50296 1.66312 7.03096 1.76712 6.56696L5.59912 10.399V11.199C5.59912 12.079 6.31912 12.799 7.19912 12.799V14.343ZM11.2002 11.1994C11.9202 11.1994 12.5122 11.6634 12.7202 12.3114C13.7602 11.1754 14.4002 9.66343 14.4002 7.99943C14.4002 5.31943 12.7442 3.02343 10.4002 2.07143V2.39943C10.4002 3.27943 9.68022 3.99943 8.80022 3.99943H7.20022V5.59943C7.20022 6.03943 6.84022 6.39943 6.40022 6.39943H4.80022V7.99943H9.60022C10.0402 7.99943 10.4002 8.35943 10.4002 8.79943V11.1994H11.2002Z"
      fill={theme.blue["300"]}
    />
  </svg>
);

export default withTheme(WorldIcon);
