import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "constant";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import {
  setShowModal as updateShowModal,
  setUrl as updateUrl
} from "features/require-login/require-login-actions";
import {
  selectShowModal,
  selectUrl
} from "features/require-login/require-login-selectors";
import { isUrlThatRequireLogin } from "pages/home/constants";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const useLoginModal = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const currentLanguage = useSelector(selectCurrentLanguageIsoCode);
  const showModal = useSelector(selectShowModal);
  const url = useSelector(selectUrl);
  const history = useHistory();
  const dispatch = useDispatch();
  const isEmailVerified = user?.email_verified;

  const shouldRequireLogin = (urlToCheck: string) => {
    const theUrlRequiresLogin = isUrlThatRequireLogin(urlToCheck);
    return theUrlRequiresLogin && (!isAuthenticated || !isEmailVerified);
  };
  const modalUrlRequiresLogin = shouldRequireLogin(url);

  const setShowModal = (show: boolean) => dispatch(updateShowModal(show));

  const setUrl = (newUrl: string) => dispatch(updateUrl(newUrl));

  const redirectToSecureUrl = () => {
    if (!isAuthenticated) {
      loginWithRedirect({ lang: currentLanguage });
    }
    if (modalUrlRequiresLogin && isAuthenticated && !isEmailVerified) {
      history.push(routes.EMAIL_NOT_VERIFIED);
    }
  };
  return {
    showModal,
    url,
    setShowModal,
    setUrl,
    shouldRequireLogin,
    redirectToSecureUrl
  };
};
