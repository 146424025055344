import styled, { keyframes } from "styled-components";

const scaleUp = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const FloatingContainer = styled.div`
  position: fixed;
  right: 2vw;
  bottom: 3vh;
  width: 64px;
  height: 64px;
  z-index: 10;
`;

export const FloatingCircle = styled.button`
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: #1cc54e;
  border-radius: 100%;
  width: 64px;
  height: 64px;
`;

export const FloatingBanner = styled.div`
  position: absolute;
  bottom: 0;
  right: calc(100% + 20px);
  background: white;
  width: 225px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  transform: scale(0);
  transform-origin: bottom right;
  animation: ${scaleUp} 0.5s ease-out forwards;
  animation-delay: 1s;

  .triangle {
    position: absolute;
    bottom: 20px;
    right: -15px;
    filter: drop-shadow(5px 0px 2px rgba(0, 0, 0, 0.1));
  }
`;

export const CloseFloatingIcon = styled.button`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -30px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  transform-origin: center;
  transform: scale(0);
  animation: ${scaleUp} 0.3s ease-out forwards;
  animation-delay: 1.8s;
  width: 24px;
  height: 24px;
  border-radius: 100%;
`;

export const FloatingBannerText = styled.button`
  color: ${({ theme }) => theme.grey["800"]};
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  padding: 20px 15px;
`;
