import React from "react";
import { CardContainer, CardContent, MainText, MainTitle } from "./card-styles";

interface CardProps {
  title: string;
  text: string;
}

export const Card = ({ title, text }: CardProps) => {
  return (
    <CardContainer>
      <CardContent>
        <MainTitle>{title}</MainTitle>
        <MainText>{text}</MainText>
      </CardContent>
    </CardContainer>
  );
};
