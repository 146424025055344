import React from "react";
import { useSelector } from "react-redux";
import { GlobalStyles, useGlobalStyle } from "styles";
import { ThemeProvider } from "styled-components";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";

interface Props {
  children: React.ReactNode;
}

export const StylesProvider: React.FC<Props> = ({ children }) => {
  const configuration = useSelector(selectConfiguration);
  const { theme } = useGlobalStyle(configuration);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />
      {children}
    </ThemeProvider>
  );
};
