import React, { ComponentType, FC } from "react";
import { useLoginModal } from "./use-login-modal";
import { trackDisplayEvent } from "features/analytics-events/track-display-event";

interface OriginalProps {
  to: string;
  onClick: (e?: any) => void;
  [key: string]: any;
}

export function withLoginModal<T extends OriginalProps>(
  WrappedComponent: ComponentType<OriginalProps>
) {
  const HOC: FC<T> = (props: T) => {
    const { to: originalTo, onClick: externalClick, ...rest } = props;
    const { setShowModal, setUrl, shouldRequireLogin } = useLoginModal();
    const requiresLogin = shouldRequireLogin(originalTo);
    const to = requiresLogin ? undefined : originalTo;
    const onClick = (e?: any) => {
      externalClick && externalClick(e);
      setUrl(originalTo);
      if (requiresLogin) {
        trackDisplayEvent("popup", "log in");
        setShowModal(true);
      }
    };

    return <WrappedComponent {...rest} to={to!} onClick={onClick} />;
  };

  return HOC;
}
