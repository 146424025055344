import { ActionStandard } from "app/store/types";
import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { QueryParameters } from "../query-parameters-types";
import { SET_QUERY_PARAMETERS } from "../actions/query-paramenters-actions";
import { queryParametersInitialState } from "../query-parameters-initial-state";

const queryParametersStateHandlers: Mapping<Function> = {
  [SET_QUERY_PARAMETERS]: (
    state: QueryParameters,
    action: ActionStandard<Partial<QueryParameters>>
  ) => ({
    ...state,
    ...action.payload
  })
};

export const queryParametersReducer = (
  state: QueryParameters = queryParametersInitialState,
  action: ActionStandard<QueryParameters>
) => {
  return handle(state, action, queryParametersStateHandlers);
};
