import styled from "styled-components";

export const DisclaimerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const DisclaimerText = styled.div`
  width: 80%;
`;
