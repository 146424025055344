import React from "react";
import useExternalGoBackButton from "hooks/use-external-go-back-button";

import { GoBackButton } from "../go-back-button/go-back-button";

export const ExternalGoBackButton = () => {
  const {
    shouldShowButton,
    handleOnClick,
    href,
    label
  } = useExternalGoBackButton();
  return shouldShowButton ? (
    <GoBackButton
      href={href}
      label={label}
      handleOnClick={handleOnClick}
      show={shouldShowButton}
    />
  ) : null;
};
