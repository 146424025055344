import React, { useEffect } from "react";
import { useAssistanceServices } from "hooks/use-assistance-services";
import { MobileMenuAssistanceService } from "./menu-mobile-assistance-service/menu-mobile-assistance-service";
import {
  MenuContainer,
  LoginButtonContainerNotLogged
} from "./menu-mobile-styles";
import { AccountDropdown } from "app/components/account-dropdown/account-dropdown";
import { ContactUs } from "app/components/contact-us/contact-us";
import { FaqsButton } from "app/components/faqs-button/faqs-button";
import { LoginButton } from "app/components/shared/login-button/login-button";
import useMenuHeader from "hooks/use-menu-header";
import { PWAInstallationButton } from "app/components/pwa-installation-button";

export const MobileMenu = () => {
  const { assistanceServices } = useAssistanceServices();
  const { shouldShowMenuOptions } = useMenuHeader();

  useEffect(() => {
    const scrollY = window.scrollY;
    const element = document.getElementById("root");
    if (element) {
      element.style.overflow = "hidden";
    }
    return () => {
      if (element) {
        element.style.overflow = "unset";
      }
      window.scroll(0, scrollY);
    };
  }, []);

  return (
    <MenuContainer data-testid="mobile-menu-container">
      <div>
        {shouldShowMenuOptions ? (
          <AccountDropdown />
        ) : (
          <LoginButtonContainerNotLogged>
            <LoginButton />
          </LoginButtonContainerNotLogged>
        )}
        {assistanceServices?.map(service => (
          <MobileMenuAssistanceService
            key={service.id}
            id={service.id}
            subsections={service.subsections}
          />
        ))}
        <FaqsButton />
        <ContactUs />
        <PWAInstallationButton />
      </div>
    </MenuContainer>
  );
};
