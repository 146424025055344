import React from "react";
import {
  BaseSection,
  BaseSectionLeft,
  BaseSectionSubTitle,
  BaseSectionTitle
} from "pages/home/home-styles";
import { WebComponentContainer } from "./customer-satisfaction-section-styles";
import CustomerSatifaction from "app/components/customer-satisfaction/customer-satisfaction";

interface CustomerSatifactionSectionProps {
  title: string;
  text: string;
}

export const CustomerSatifactionSection = ({
  title,
  text
}: CustomerSatifactionSectionProps) => {
  return (
    <BaseSection>
      <BaseSectionLeft>
        <BaseSectionTitle>{title}</BaseSectionTitle>
        <BaseSectionSubTitle>{text}</BaseSectionSubTitle>
      </BaseSectionLeft>
      <WebComponentContainer>
        <CustomerSatifaction />
      </WebComponentContainer>
    </BaseSection>
  );
};
