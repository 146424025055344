const PARTNER_NAME_BY_ID: Record<string, string> = {
  "Principal-US": "Principal",
  "Metlife-Employees-US": "MetLife Employees",
  "USAble-Life-US": "USAble Life",
  "Pacific-Guardian-US": "Pacific Guardian",
  "PALIG-US": "PALIG",
  "New-York-Life-Presidio-US": "New York Life – Presidio",
  "New-York-Life-US": "New York Life",
  "Arch-CISI-US": "Arch - CISI",
  "Travelers-US": "Travelers",
  "Equitable-US": "Equitable",
  "Blue-Shield-of-California-US": "Blue Shield of California"
};

export const getPartnerNameById = (partnerId: string) =>
  PARTNER_NAME_BY_ID[partnerId];
