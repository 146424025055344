import { Action } from "redux";

import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { SET_INSTALLATION_MODAL_DISPLAYED_ON_FIRST_VISIT } from "./installation-modal-action-types";
import { ActionStandard } from "app/store/types";

const initialState: boolean = false;

const installationModalDisplayedOnFirstVisitHandler: Mapping<Function> = {
  [SET_INSTALLATION_MODAL_DISPLAYED_ON_FIRST_VISIT]: (
    state: boolean,
    action: ActionStandard<boolean>
  ) => action.payload
};

export const installationModalDisplayedOnFirstVisitReducer = (
  state: boolean = initialState,
  action: Action
) => {
  return handle(state, action, installationModalDisplayedOnFirstVisitHandler);
};
