import { useEffect, useState } from "react";

export const useScrollThreshold = (threshold: number) => {
  const [isReached, setIsReached] = useState<boolean>((): boolean => {
    const offset = window.scrollY;
    return offset > threshold;
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsReached(offset > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isReached };
};
