import { useTranslate } from "@customer_interactions/i18n";
import React, { useEffect } from "react";
import {
  GenericPage,
  MiniText,
  Title,
  Text,
  Link,
  Button
} from "app/components/shared/generic-page/generic-page-styles";
import { Analytics } from "@customer_interactions/application-logger";

const analyticsCategory = "under_maintenance";

const UnderMaintenance = () => {
  const t = useTranslate();

  useEffect(() => {
    Analytics.pageView(`\/${analyticsCategory}`);
    Analytics.sendEvent("display_under_maintenance", analyticsCategory);
  }, []);

  const handleClickRefresh = () => {
    Analytics.sendEvent("click_refresh_page", analyticsCategory);
    window.location.reload();
  };

  const handleClickFaqs = () => {
    Analytics.sendEvent("click_FAQ_page", analyticsCategory);
  };

  return (
    <GenericPage data-testid="under-maintenance">
      <MiniText>{t("[Under_Maintenance]Minitext")}</MiniText>
      <Title>{t("[Under_Maintenance]Title")}</Title>
      <Text>
        {t("[Under_Maintenance]Text")}{" "}
        <Link to={t("[Under_Maintenance]Text_Url")} onClick={handleClickFaqs}>
          {t("[Under_Maintenance]Text_Url_Label")}
        </Link>
      </Text>
      <Button
        to={t("[Under_Maintenance]Button_Url")}
        onClick={handleClickRefresh}
      >
        {t("[Under_Maintenance]Button_Label")}
      </Button>
    </GenericPage>
  );
};

export default UnderMaintenance;
