import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const VideoContainer = styled.div`
  flex: 0 1 50%;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const VideoSectionMobile = css`
  flex-direction: column;
`;

export const VideoSectionStyles = styled.section`
  display: flex;
  ${getCssForMobileView(VideoSectionMobile)}
`;
