import { ActionStandard } from "app/store/types";
import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";
import { CURRENT_LANGUAGE_SUCCESS } from "../actions/language-action-types";

export const languageSuccessHandler = (
  state: string,
  action: ActionStandard<string>
) => action.payload;

const currentLanguageStateHandlers: Mapping<Function> = {
  [CURRENT_LANGUAGE_SUCCESS]: languageSuccessHandler
};

const initialState: string = "";

export const currentLanguageReducer = (
  state = initialState,
  action: ActionStandard<string>
) => {
  return handle(state, action, currentLanguageStateHandlers);
};
