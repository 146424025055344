import { LinkResolver } from "app/components/link-resolver/link-resolver";

import styled, { css } from "styled-components";
import {
  getCssForExtraLargeView,
  getCssForMobileView
} from "styles/responsive";

export const ActionCard = styled(LinkResolver)`
  display: flex;
  text-decoration: none;
  width: 100%;
  padding: 0.75rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.viridian["400"]};
  background: ${({ theme }) => theme.white};

  &:hover,
  &:visited,
  &:active {
    color: inherit;
  }

  ${getCssForExtraLargeView(css`
    padding: 1rem;
  `)}

  ${getCssForMobileView(css`
    padding: 0.5rem;
  `)}
`;

export const ActionCardText = styled.p`
  color: ${({ theme }) => theme.grey["900"]};
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  ${getCssForExtraLargeView(css`
    font-size: 2rem;
    line-height: 2.5rem;
  `)}

  ${getCssForMobileView(css`
    font-size: 1.25rem;
  `)}
`;
