import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import CTAButton from "../cta-button/cta-button";

export const LoginModal = styled.div`
  display: flex;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: normal;
  color: ${({ theme }) => theme.grey["800"]};
  text-align: left;
  line-height: initial;
  letter-spacing: initial;
  padding-left: 20px;

  ${getCssForMobileView(css`
    display: block;
    padding-left: 0px;
  `)}
`;

export const LoginImageContainer = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 16px 0;
  ${getCssForMobileView(css`
    padding-bottom: 2px;
  `)}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  gap: 8px;
  justify-content: flex-end;
  margin: 16px 0;
  ${getCssForMobileView(css`
    padding-top: 16px;
    padding-left: 0px;
  `)}
`;

export const LoginImage = styled.img`
  max-width: 352px;
  max-height: 352px;
  ${getCssForMobileView(css`
    max-width: 150px;
    max-height: 150px;
  `)}
  border-radius: 56% 56% 0px 56%;
`;

// TODO replace CTAButton by Button component
export const LoginModalButton = styled(CTAButton)`
  gap: 10px;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
  ${getCssForMobileView(css`
    width: 100%;
  `)}
`;

export const LoginModalList = styled.ul`
  padding: 0px 0px 18px 44px;
  gap: 8px;

  ${getCssForMobileView(css`
    padding: 0px 0px 4px 40px;
  `)}
`;

export const LoginModalItemList = styled.li`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: left;
  padding-block: 4px;

  &::marker {
    font-size: 14px;
  }

  ${getCssForMobileView(css`
    font-size: 1rem;
    line-height: 1.5rem;
    padding-block: 3px;

    &::marker {
      font-size: 0.75rem;
    }
  `)}
`;

export const LoginModalText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: pre-wrap;
  ${getCssForMobileView(css`
    white-space: inherit;
  `)}
`;

export const LoginModalH2 = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

export const LoginModalH4 = styled.h4`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

export const LoginModalBoldText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0px;
`;
