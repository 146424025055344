import React from "react";
import {
  MenuContent,
  MenuItem,
  LogoutMenuItem,
  MenuTrigger,
  AccountMenuContainer,
  MenuTitle,
  EmailItemContainer,
  EmailContent
} from "./account-dropdown-styles";
import ArrowDownIcon from "../icons/arrow-down-icon";
import ArrowUpIcon from "../icons/arrow-up-icon";
import PersonIcon from "../icons/person-icon";
import FocusTrapElements from "../focus-trap/focus-trap";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { useAccountDropdown } from "./use-account-dropdown";

export const AccountDropdown = () => {
  const {
    user,
    open,
    toggleMenu,
    MenuDescription,
    closeMenu,
    menuContainerRef,
    handleChangePassword,
    logOut,
    email,
    emailAccount,
    emailDomain,
    t
  } = useAccountDropdown();

  return (
    <AccountMenuContainer
      data-testid="account-menu-container"
      onKeyDown={e => callbackOnKeyboardEvent(e, "Escape", closeMenu)}
      ref={menuContainerRef}
    >
      <MenuTrigger data-testid="account-dropdown-trigger" onClick={toggleMenu}>
        <MenuDescription>
          <PersonIcon />
          <MenuTitle data-testid="account-dropdown-title">
            {user?.given_name ?? t("[menu_account]name")}
          </MenuTitle>
        </MenuDescription>

        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </MenuTrigger>
      {open && (
        <FocusTrapElements>
          <MenuContent>
            {email && (
              <MenuItem data-testid="account-email">
                <EmailItemContainer>
                  <EmailContent>{emailAccount}</EmailContent>
                  <span>{emailDomain}</span>
                </EmailItemContainer>
              </MenuItem>
            )}
            <MenuItem
              tabIndex={0}
              data-testid="account-dropdown-change-password-button"
              isClickable={true}
              onKeyDown={e =>
                callbackOnKeyboardEvent(e, "Enter", handleChangePassword)
              }
              onClick={handleChangePassword}
            >
              {t("[menu_account]change-password")}
            </MenuItem>
            <LogoutMenuItem
              tabIndex={0}
              data-testid="account-dropdown-logout-button"
              isClickable={true}
              onKeyDown={e => callbackOnKeyboardEvent(e, "Enter", logOut)}
              onClick={logOut}
            >
              {t("[menu_account]logout")}
            </LogoutMenuItem>
          </MenuContent>
        </FocusTrapElements>
      )}
    </AccountMenuContainer>
  );
};
