import React, { FC } from "react";
import {
  HeaderLanguage,
  HeaderLanguageMobile
} from "app/components/header-language/header-language";
import { Footer } from "app/components/footer/footer";
import ScrollUpButton from "app/components/shared/scroll-up-button/scroll-up-button";

import { useWindowSize } from "hooks/use-window-size";

import LoginModal from "app/components/shared/login-modal/login-modal";
import { InstallationModal } from "app/components/shared/installation-modal";
import { MainPageContainer } from "../layout-styles";
import { Header } from "app/components/header/header";
import { HeaderMobile } from "app/components/header-mobile/header-mobile";
import { GoBackButton } from "app/components/go-back-button/go-back-button";
import { routes } from "constant";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@customer_interactions/i18n";

interface IPageProps {
  children: React.ReactNode;
}

const AlternativeLayoutPage: FC<IPageProps> = ({ children }: IPageProps) => {
  const { isMobile } = useWindowSize();
  const translate = useTranslate();
  const location = useLocation();
  const showGoBackButton = ![routes.HOME, routes.ROOT].includes(
    location.pathname
  );

  const HeaderGoBackSection = (
    <GoBackButton
      href={routes.HOME}
      show={showGoBackButton}
      label={translate("[header] go_back_home")}
    />
  );

  return (
    <MainPageContainer>
      {isMobile ? (
        <>
          <HeaderLanguageMobile>{HeaderGoBackSection}</HeaderLanguageMobile>
          <HeaderMobile displayMenu={false} />
        </>
      ) : (
        <>
          <HeaderLanguage>{HeaderGoBackSection}</HeaderLanguage>
          <Header displayMenu={false} />
        </>
      )}
      {children}
      <Footer />
      <ScrollUpButton />
      <LoginModal />
      <InstallationModal />
    </MainPageContainer>
  );
};

export default AlternativeLayoutPage;
