import React from "react";
import { useHistory } from "react-router-dom";
import {
  MobileHeaderContainer,
  CrossIconStyled,
  MenuIconStyled,
  LogoWrapper
} from "./header-mobile-styles";
import { MobileMenu } from "./menu-mobile/menu-mobile";
import { NAV_MOBILE_CONTAINER_ID, routes } from "constant";
import { useHamburguerMenuActions } from "hooks/use-hamburguer-menu";
import { Logo } from "app/components/logo";
import { HeaderPartnerSection } from "../header-partner-section/header-partner-section";

type HeaderMobileProps = {
  displayMenu?: boolean;
};

export const HeaderMobile = ({ displayMenu = true }: HeaderMobileProps) => {
  if (!displayMenu) {
    return (
      <MobileHeaderContainer
        data-testid={NAV_MOBILE_CONTAINER_ID}
        id={NAV_MOBILE_CONTAINER_ID}
      >
        <HeaderPartnerSection>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </HeaderPartnerSection>
      </MobileHeaderContainer>
    );
  }

  const history = useHistory();
  const {
    handleMenuClick,
    isMenuOpened,
    closeMenu
  } = useHamburguerMenuActions();

  const logMenuClick = !isMenuOpened;

  const menuIconProps = {
    ["data-testid"]: "hamburger-menu",
    onClick: () => handleMenuClick(logMenuClick)
  };

  const getMenuIcon = () =>
    isMenuOpened ? (
      <CrossIconStyled {...menuIconProps} />
    ) : (
      <MenuIconStyled {...menuIconProps} />
    );

  const handleClickOnLogoContainer = () => {
    history.push(routes.HOME);
    closeMenu();
  };

  return (
    <MobileHeaderContainer
      data-testid={NAV_MOBILE_CONTAINER_ID}
      id={NAV_MOBILE_CONTAINER_ID}
    >
      <HeaderPartnerSection>
        <LogoWrapper
          onClick={handleClickOnLogoContainer}
          data-testid="mobile-header-brand-logo"
        >
          <Logo />
        </LogoWrapper>
      </HeaderPartnerSection>
      {getMenuIcon()}
      {isMenuOpened && <MobileMenu />}
    </MobileHeaderContainer>
  );
};
