import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "constant";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { useWindowSize } from "hooks/use-window-size";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "utils/assets-utils";

export const useHome = () => {
  const config = useSelector(selectConfiguration);
  const { isAuthenticated, user } = useAuth0() as any;
  const isEmailVerified = user?.email_verified;
  const {
    digitalCard: { enable: digitalCardEnable }
  } = config;
  const { isMobile } = useWindowSize();
  const imageURL = getImageURL(isMobile);

  const history = useHistory();

  useEffect(() => {
    const emailNotVerified = window.sessionStorage.getItem("email") ?? "";
    const isFirstVisit = !emailNotVerified || emailNotVerified !== user?.email;

    if (isFirstVisit && isAuthenticated && !isEmailVerified) {
      history.push(routes.EMAIL_NOT_VERIFIED);
      window.sessionStorage.setItem("email", user?.email);
    }
  }, []);
  return {
    digitalCardEnable,
    hasVideoSection: config.hasVideoSection,
    imageURL,
    user
  };
};
