import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const CulturaGuidesContainer = styled.div`
  ${getCssForMobileView(
    css`
      margin-top: 65px;
    `
  )}
`;

export const CulturaGuidesDisclaimerContainer = styled.div`
  margin: 0 5% 48px;
  ${getCssForMobileView(
    css`
      margin: 15px;
    `
  )}
`;

export const CulturaGuidesDisclaimerTitle = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: inline;
`;

export const CulturaGuidesDisclaimerText = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  display: inline;
`;
