import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const MenuIcon = ({ theme, ...props }: IconProps) => (
  <svg
    name="hamburger-menu-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4H22V6H2V4ZM2 11H22V13H2V11ZM22 18H2V20H22V18Z"
      fill={theme.blue["300"]}
    />
  </svg>
);

export default withTheme(MenuIcon);
