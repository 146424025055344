import React from "react";
import { useSelector } from "react-redux";

import {
  selectConfiguration,
  selectUseAlternativeHomepage
} from "features/configuration/selectors/configuration-selectors";
import {
  RoutingPageRoutes,
  UnderMaintenanceRoutes
} from "app/components/routes";
import { Auth0Wrapper } from "./auth0-wrapper/auth0-wrapper";
import { RoutesWithAuth0 } from "./routes-with-auth0";

export const ApplicationRouter = () => {
  const { isUnderMaintenance } = useSelector(selectConfiguration);
  const useAlternativeHomepage = useSelector(selectUseAlternativeHomepage);

  if (isUnderMaintenance) {
    return <UnderMaintenanceRoutes />;
  }

  if (useAlternativeHomepage) {
    return <RoutingPageRoutes />;
  }

  return (
    <Auth0Wrapper>
      <RoutesWithAuth0 />
    </Auth0Wrapper>
  );
};
