import { useTranslate } from "@customer_interactions/i18n";
import {
  PartnerInformationSection,
  PartnerTitle,
  PartnerSubtitle,
  PartnerSection
} from "./header-partner-section-styles";
import React, { PropsWithChildren } from "react";

export const HeaderPartnerSection = ({ children }: PropsWithChildren<{}>) => {
  const t = useTranslate();

  const title = t("[header] title", true);
  const subtitle = t("[header] subtitle", true);

  return (
    <PartnerSection>
      {children}
      {(title || subtitle) && (
        <PartnerInformationSection>
          <PartnerTitle>{title}</PartnerTitle>
          <PartnerSubtitle>{subtitle}</PartnerSubtitle>
        </PartnerInformationSection>
      )}
    </PartnerSection>
  );
};
