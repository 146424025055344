import React from "react";
import { useSelector } from "react-redux";
import { selectCustomLanguage } from "features/language/selectors/language-selector";
import defaultTheme from "styles/default-theme";
import {
  BaseSectionLeft,
  BaseSectionSubTitle,
  BaseSectionTitle
} from "pages/home/home-styles";
import {
  Video,
  VideoContainer,
  VideoSectionStyles
} from "./video-section-styles";
import { Analytics } from "@customer_interactions/application-logger";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";
import { selectVideoLocales } from "features/configuration/selectors/configuration-selectors";

interface VideoSectionProps {
  title: string;
  text: string;
}

export const VideoSection = ({ title, text }: VideoSectionProps) => {
  const languageCode = useSelector(
    selectCustomLanguage({
      includeCountryCode: true
    })
  );

  const videoLocale =
    useSelector(selectVideoLocales)?.[languageCode] ?? languageCode;

  const videoURL =
    `${process.env.REACT_APP_VIDEOS_URL}/my_tc_${videoLocale}.mp4` +
    // needed to display the video preview in IOS Safari
    "#t=0.001";

  const handlePlay = () => {
    Analytics.sendEvent("[Video] play-click", "Customer_Space");
    trackNavigationEvent("take a tour into the portal", "play video");
  };

  return (
    <VideoSectionStyles title="video-section">
      <BaseSectionLeft color={defaultTheme.grey["700"]}>
        <BaseSectionTitle color={defaultTheme.white}>{title}</BaseSectionTitle>
        <BaseSectionSubTitle color={defaultTheme.white}>
          {text}
        </BaseSectionSubTitle>
      </BaseSectionLeft>
      <VideoContainer>
        <Video title="video-claim-steps" controls onPlay={handlePlay}>
          <source src={videoURL} type="video/mp4" />
        </Video>
      </VideoContainer>
    </VideoSectionStyles>
  );
};
