import { Action } from "redux";

import { ActionStandard } from "app/store/types";

import { GlobalPartnerParameters } from "../global-partner-parameters-types";

import { createActionType } from "utils/action-utils";

const GLOBAL_PARTNER_PARAMETERS = "GLOBAL_PARTNER_PARAMETERS";

export const GLOBAL_PARTNER_PARAMETERS_FEATURE = createActionType(
  GLOBAL_PARTNER_PARAMETERS
);

export const getGlobalPartnerParametersInit = (): Action => ({
  type: GLOBAL_PARTNER_PARAMETERS_FEATURE.INIT
});

export const getGlobalPartnerParametersSuccess = (
  payload: Partial<GlobalPartnerParameters>
): ActionStandard<Partial<GlobalPartnerParameters>> => ({
  type: GLOBAL_PARTNER_PARAMETERS_FEATURE.SUCCESS,
  payload
});
