import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const RightTriangle = ({ theme, fill = theme.white, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    aria-label="right-triangle"
    className={props.className}
    {...props}
  >
    <path
      d="M17.6292 10.0673C19.2884 11.2648 19.2884 13.7352 17.6292 14.9327L4.75562 24.2234C2.77152 25.6553 6.29335e-07 24.2375 7.36289e-07 21.7907L1.54851e-06 3.20928C1.65546e-06 0.762453 2.77153 -0.655269 4.75562 0.776627L17.6292 10.0673Z"
      fill={fill}
    />
  </svg>
);

export default withTheme(RightTriangle);
