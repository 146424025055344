import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import { useScriptLoader } from "hooks/use-script-loader";
import { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import {
  addCustomEventListener,
  CUSTOM_EVENTS,
  removeCustomEventListener
} from "./cultura-guides-collaborators";
import {
  handleBackEvent,
  handleSelectChapterCreator,
  handleNext,
  handlePrevious,
  handleSearch,
  handleSelectGuideCreator,
  handleDownload
} from "./cultura-guides-event-handlers";
import { useAuth0 } from "@auth0/auth0-react";

export const useCulturaGuides = () => {
  const languagueCode = useSelector(selectCurrentLanguageIsoCode);
  const store = useStore();
  const { isAuthenticated } = useAuth0();
  const { isLoaded } = useScriptLoader(
    process.env.REACT_APP_CULTURA_GUIDES_SCRIPT_URL || ""
  );

  useEffect(() => {
    const handleSelectGuide = handleSelectGuideCreator(
      store.getState(),
      isAuthenticated
    );
    const handleSelectChapter = handleSelectChapterCreator(
      store.getState(),
      isAuthenticated
    );

    addCustomEventListener(CUSTOM_EVENTS.FILTER_SEARCH, handleSearch);
    addCustomEventListener(
      CUSTOM_EVENTS.SELECT_USING_MOBILE_DROPDOWN,
      handleSelectChapter
    );
    addCustomEventListener(CUSTOM_EVENTS.PREVIOUS, handlePrevious);
    addCustomEventListener(CUSTOM_EVENTS.NEXT, handleNext);
    addCustomEventListener(CUSTOM_EVENTS.TABLE_OF_CONTENT, handleSelectChapter);
    addCustomEventListener(CUSTOM_EVENTS.SELECT_GUIDE, handleSelectGuide);
    addCustomEventListener(CUSTOM_EVENTS.BACK_TO_GUIDES, handleBackEvent);
    addCustomEventListener(CUSTOM_EVENTS.DOWNLOAD, handleDownload);

    return () => {
      removeCustomEventListener(CUSTOM_EVENTS.FILTER_SEARCH, handleSearch);
      removeCustomEventListener(
        CUSTOM_EVENTS.SELECT_USING_MOBILE_DROPDOWN,
        handleSelectChapter
      );
      removeCustomEventListener(CUSTOM_EVENTS.PREVIOUS, handlePrevious);
      removeCustomEventListener(CUSTOM_EVENTS.NEXT, handleNext);
      removeCustomEventListener(
        CUSTOM_EVENTS.TABLE_OF_CONTENT,
        handleSelectChapter
      );
      removeCustomEventListener(CUSTOM_EVENTS.SELECT_GUIDE, handleSelectGuide);
      removeCustomEventListener(CUSTOM_EVENTS.BACK_TO_GUIDES, handleBackEvent);
      removeCustomEventListener(CUSTOM_EVENTS.DOWNLOAD, handleDownload);
    };
  }, []);

  return {
    isLoaded,
    languagueCode,
    brandId: process.env.REACT_APP_CULTURA_GO_BRAND_ID
  };
};
