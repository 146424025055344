import React from "react";
import ReactMarkdown from "react-markdown";
import CTAButton from "app/components/shared/cta-button/cta-button";
import { TextContainer } from "app/components/shared/modal/modal-styles";
import Modal from "app/components/shared/modal/modal";
import { useContactUsModal } from "./use-contact-us-modal";
import { ContactUsCollapsable } from "./contact-us-collapsable";

export type ContactUsModalProps = {
  title: string;
  buttonText: string;
  closeOnOutsideClick?: boolean;
  ctaButtonName?: string;
  onClose: () => void;
};

const ContactUsModal = ({
  title,
  buttonText,
  onClose,
  closeOnOutsideClick = true,
  ctaButtonName = "generic-modal-button"
}: ContactUsModalProps) => {
  const { mainContent, collapsableList } = useContactUsModal();

  return (
    <Modal
      title={title}
      onClose={onClose}
      closeOnOutsideClick={closeOnOutsideClick}
      buttonSection={
        <CTAButton
          translationKey={buttonText}
          onClick={onClose}
          to="/"
          name={ctaButtonName}
        />
      }
    >
      {!!mainContent && (
        <TextContainer>
          <ReactMarkdown>{mainContent}</ReactMarkdown>
        </TextContainer>
      )}
      {collapsableList.map(
        ({ expandLabel, collapseLabel, content }) =>
          !!expandLabel && (
            <ContactUsCollapsable
              expandLabel={expandLabel}
              collapseLabel={collapseLabel}
              content={content}
            />
          )
      )}
    </Modal>
  );
};

export default ContactUsModal;
