import { useTranslate } from "@customer_interactions/i18n";
import { useAssistanceServices } from "hooks/use-assistance-services";
import { capitalize } from "lodash";
import React from "react";
import {
  MenuContainer,
  MenuTitle,
  MenuMainContent,
  BagaggeIconStyled,
  MedicalIconStyled,
  ClaimIconStyled,
  MenuHeaderExpanded
} from "./menu-expanded-styles";
import useMenuContainerRef from "hooks/use-menu-container-ref";
import FocusTrapElements from "app/components/focus-trap/focus-trap";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { CrossIconStyled, MenuButtonsContainer } from "../header-styles";
import { Logo } from "app/components/logo";
import useMenuHeader from "hooks/use-menu-header";
import { MenuItem } from "./menu-expanded-item";

const getIconWithStyles = (id?: string) => {
  switch (id) {
    case "travel":
      return <BagaggeIconStyled width={24} height={24} />;
    case "medical":
      return <MedicalIconStyled width={24} height={24} />;
    case "claim":
      return <ClaimIconStyled width={24} height={24} />;
    default:
      return null;
  }
};

export const MenuExpanded = () => {
  const { assistanceServices } = useAssistanceServices();
  const t = useTranslate();
  const { closeMenu } = useMenuHeader();

  const { menuContainerRef } = useMenuContainerRef(closeMenu);
  const menuIconProps = {
    ["data-testid"]: "hamburger-menu"
  };
  return (
    <FocusTrapElements>
      <MenuContainer
        ref={menuContainerRef}
        onKeyDown={e => callbackOnKeyboardEvent(e, "Escape", closeMenu)}
      >
        <MenuHeaderExpanded onClick={closeMenu}>
          <Logo />
          <MenuButtonsContainer>
            <CrossIconStyled {...menuIconProps} />
          </MenuButtonsContainer>
        </MenuHeaderExpanded>
        <MenuMainContent>
          {assistanceServices?.map(service => (
            <React.Fragment key={service.id}>
              <MenuTitle data-testid={`home-menu-title-${service.id}`}>
                {getIconWithStyles(service.id)}
                {t(`[Home_Assistance_service]${capitalize(service.id)}_title`)}
              </MenuTitle>
              {service.subsections?.map(subsection => {
                return (
                  <MenuItem
                    subsection={subsection}
                    sectionId={service.id}
                    key={subsection.id}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </MenuMainContent>
      </MenuContainer>
    </FocusTrapElements>
  );
};
