import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7.99935V9.99935H2.00001V7.99935H0.666672V9.99935C0.666672 10.7327 1.26667 11.3327 2.00001 11.3327H10C10.7333 11.3327 11.3333 10.7327 11.3333 9.99935V7.99935H10ZM9.33334 5.33268L8.39334 4.39268L6.66667 6.11268V0.666016H5.33334V6.11268L3.60667 4.39268L2.66667 5.33268L6 8.66602L9.33334 5.33268Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default DownloadIcon;
