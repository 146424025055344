import { ConfigModel } from "features/configuration/configuration-types";
import { PERSISTED_STATE } from "./persisted-state";

const INDEX_START_SUBSTRING = 0;
const INDEX_END_SUBSTRING = 2;
const TIME_OUT = 900;

export const getLanguageIsoCode = (language: string): string =>
  language.substring(INDEX_START_SUBSTRING, INDEX_END_SUBSTRING);

const getLanguageCodeByBrowser = () => window.navigator.language.split("-")[0];

const getPersistedLanguage = () => {
  const persistedState = window.localStorage.getItem(PERSISTED_STATE);
  return persistedState ? JSON.parse(persistedState).currentLanguage : "";
};

export const getDefaultLanguage = (configuration: ConfigModel) => {
  const supportedLanguages = configuration.languages_iso_codes;
  const persistedLanguageState = getPersistedLanguage();
  const userLanguage = getLanguageCodeByBrowser();
  const supportedUserLanguage = supportedLanguages.find(
    language => language === userLanguage
  )
    ? `${userLanguage}_${configuration.partnerId}`
    : `${supportedLanguages[0]}_${configuration.partnerId}`;

  return persistedLanguageState || supportedUserLanguage;
};

export const isSupportedLanguage = (
  configuration: ConfigModel,
  lngIsoCode: string
) => configuration.languages_iso_codes.includes(lngIsoCode);

export const changeOneTrustLanguage = (language: string) => {
  if (window.OneTrust) {
    window.OneTrust.changeLanguage(language);
  } else {
    setTimeout(() => {
      if (window.OneTrust) {
        window.OneTrust.changeLanguage(language);
      }
    }, TIME_OUT);
  }
};
