import { State } from "app/store/types";
import { RouteId, routes } from "constant";
import {
  selectAnalyticsPartnerId,
  selectAnalyticsPartnerName,
  selectCountry,
  selectGlobalDenomination,
  selectPartnerCountry
} from "features/configuration/selectors/configuration-selectors";
import {
  selectGlobalCountry,
  selectGlobalPartnerId
} from "features/global-partner-parameters/selectors/global-partner-parameters-selectors";
import { selectPartnerName } from "features/query-parameters/selectors/query-parameters-selectors";
import { getPartnerNameById } from "utils/page-view-utils";
import { isTestEnvironment } from "utils/variable";

export enum EVENT_NAMES {
  PAGEVIEW = "pageview",
  START_AUTHENTICATION_JOURNEY = "start_authentication_journey",
  COMPLETED_AUTHENTICATION_JOURNEY = "completed_authentication_journey",
  DISPLAY = "display",
  NAVIGATION = "navigation",
  SEARCH = "search",
  START_CHAT = "start_chat"
}

export const SUBSIDIARY_NAME = "axa_partners";
export const BUSINESS_UNIT = "customer_and_solutions";
export const BUSINESS_LINE = "travel_solutions";
export const DIGITAL_BUSINESS_PROCESS = "assistance_request";
export const GLOBAL_DIGITAL_ASSET = "my_trip_companion";
// routing page
export const ROUTING_PAGE_DIGITAL_BUSINESS_PROCESS = "routing_request";
export const ROUTING_PAGE_GLOBAL_DIGITAL_ASSET = "routing_page";

export const getEnvironmentName = () => (isTestEnvironment() ? "uat" : "prod");

export const mapUrlsToEventNames: Record<
  Partial<typeof routes[RouteId]>,
  string
> = {
  [routes.HOME]: "homepage",
  [routes.ROOT]: "homepage",
  [routes.EMAIL_NOT_VERIFIED]: "confirm email address",
  [routes.COUNTRY_INFORMATION]: "search country information",
  [routes.TRAVEL_TIPS]: "travel tips",
  [routes.FAQS]: "faq landing",
  [routes.HELP_CENTER]: "help center landing",
  [routes.MEDICAL_SEARCH]: "find health professional",
  [routes.COUNTRY_GUIDES]: "country guide"
};

export const mapUrlsToServiceCategory: Record<
  Partial<typeof routes[RouteId]>,
  string
> = {
  [routes.COUNTRY_INFORMATION]: "information",
  [routes.TRAVEL_TIPS]: "information",
  [routes.FAQS]: "information",
  [routes.MEDICAL_SEARCH]: "medical support"
};

export function getPartnerId(state: State) {
  const globalDenomination = selectGlobalDenomination(state);
  const analyticsPartnerId = selectAnalyticsPartnerId(state);

  if (!globalDenomination) {
    return analyticsPartnerId;
  }

  const globalPartnerId = selectGlobalPartnerId(state);

  return globalPartnerId ?? `undefined ${globalDenomination?.toUpperCase()}`;
}

export function getPartnerName(state: State) {
  const globalDenomination = selectGlobalDenomination(state);
  const analyticsPartnerName = selectAnalyticsPartnerName(state);
  const partnerNameParam = selectPartnerName(state);

  const globalPartnerId = selectGlobalPartnerId(state);
  const calculatedPartnerName = globalPartnerId
    ? getPartnerNameById(globalPartnerId)
    : "";

  if (!globalDenomination) {
    return (
      partnerNameParam ||
      calculatedPartnerName ||
      analyticsPartnerName ||
      "undefined"
    );
  }

  return (
    partnerNameParam ||
    calculatedPartnerName ||
    `undefined ${globalDenomination?.toUpperCase()}`
  );
}

export function getCountry(state: State) {
  const globalDenomination = selectGlobalDenomination(state);
  const partnerCountry = selectPartnerCountry(state);
  const configCountry = selectCountry(state);
  const country = partnerCountry?.toUpperCase() || configCountry?.toUpperCase();

  if (!globalDenomination) {
    return country;
  }

  const globalPartnerCountry = selectGlobalCountry(state)?.toUpperCase();

  // If it's global, it looks for country param, then for partnerCountry in the config
  // then for country in the config, and then fallbacks to denomination (eu, us, etc.)
  return (
    globalPartnerCountry || country || `${globalDenomination?.toUpperCase()}`
  );
}
