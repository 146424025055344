import React from "react";
import LoginModalBody from "./login-modal-body";
import { useTranslate } from "@customer_interactions/i18n";
import { useLoginModal } from "./use-login-modal";
import LoginIcon from "app/components/icons/login-icon";
import { LoginModalButton } from "./login-modal-styles";
import { Analytics } from "@customer_interactions/application-logger";
import Modal from "../modal/modal";
import { trackStartAuthenticationJourneyFromZone } from "features/analytics-events/track-start-authentication-journey-event";

const LoginModal = () => {
  const t = useTranslate();
  const { showModal, setShowModal, redirectToSecureUrl } = useLoginModal();
  const bodyText = t("[Login_registration_info]Modal_body");
  const title = t("[Login_registration_info]Modal_title");
  const handleClose = () => {
    Analytics.sendEvent(
      "[Login_registration_modal]Cross_Icon_click",
      "Customer_Space"
    );
    setShowModal(false);
  };
  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    Analytics.sendEvent(
      "[Login_registration_modal]Login_button-click",
      "Customer_Space"
    );
    trackStartAuthenticationJourneyFromZone("popup - log in");
    redirectToSecureUrl();
    setShowModal(false);
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      title={title}
      onClose={handleClose}
      buttonSection={
        <LoginModalButton
          translationKey="[Login_registration_info]Modal_button-click"
          onClick={handleOnClick}
          to="/"
          name="login-register-modal-button"
          data-testid="login-register-modal-button"
        >
          <LoginIcon inverted />
        </LoginModalButton>
      }
    >
      <LoginModalBody text={bodyText} />
    </Modal>
  );
};

export default LoginModal;
