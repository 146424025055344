import { ActionStandard } from "app/store/types";
import { SagaIterator } from "redux-saga";
import { takeLatest, call } from "redux-saga/effects";
import {
  changeOneTrustLanguage,
  getLanguageIsoCode
} from "utils/language-utils";
import { CURRENT_LANGUAGE_SUCCESS } from "../actions/language-action-types";

export function* setOneTrustLanguage(
  action: ActionStandard<string>
): SagaIterator {
  const { payload } = action;
  if (payload) {
    const selectedLanguage = yield call(getLanguageIsoCode, payload);
    changeOneTrustLanguage(selectedLanguage);
  }
}

export default function* main() {
  yield takeLatest(CURRENT_LANGUAGE_SUCCESS, setOneTrustLanguage);
}
