import React, { FC, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@customer_interactions/i18n";
import { useHandleChangePassword } from "hooks/use-handle-change-password";
import { useOutsideClick } from "hooks/use-outside-click";
import { useWindowSize } from "hooks/use-window-size";
import { Flex } from "./account-dropdown-styles";
import { useBuildLogoutParameters } from "features/auth/use-build-logout-parameters";

const emailDomainRegex = /([^.]+)$/g;

export const useAccountDropdown = () => {
  const [open, setOpen] = useState(false);
  const t = useTranslate();

  const toggleMenu = () => setOpen(state => !state);
  const { logout, user } = useAuth0();
  const { isMobile } = useWindowSize();
  const logoutParameters = useBuildLogoutParameters();

  const email = user?.email ?? "";

  const emailDomain = email.match(emailDomainRegex);
  const emailAccount =
    emailDomain &&
    email.slice(0, email.length - emailDomain?.toString().length);

  const MenuDescription: FC = ({ children }) =>
    isMobile ? <Flex>{children}</Flex> : <>{children}</>;

  const closeMenu = () => {
    setOpen(false);
  };

  const { htmlNodeRef: menuContainerRef } = useOutsideClick(closeMenu);

  const handleChangePassword = useHandleChangePassword();

  const handleClickLogout = () => {
    if (!logoutParameters) {
      return;
    }
    logout(logoutParameters);
  };

  return {
    user,
    open,
    toggleMenu,
    MenuDescription,
    closeMenu,
    menuContainerRef,
    handleChangePassword,
    logOut: handleClickLogout,
    email,
    emailAccount,
    emailDomain,
    t
  };
};
