import { useTranslate } from "@customer_interactions/i18n";
import React from "react";
import DownloadPdfButton from "./travel-tips-download-button";
import {
  DownloadContainer,
  DownloadContainerImage,
  DownloadContainerTitle,
  DownloadPadfButtonContainer
} from "./travel-tips-styles";
const TravelTipsDownloadContainer = () => {
  const t = useTranslate();
  return (
    <DownloadContainer>
      <DownloadContainerImage />
      <DownloadContainerTitle>
        {t("[travel_tips][container]text")}
      </DownloadContainerTitle>
      <DownloadPadfButtonContainer>
        <DownloadPdfButton />
      </DownloadPadfButtonContainer>
    </DownloadContainer>
  );
};

export default TravelTipsDownloadContainer;
