export const CUSTOM_EVENTS = {
  FILTER_SEARCH: "culturago.filter",
  SELECT_USING_MOBILE_DROPDOWN: "culturago.nav.dropdown",
  PREVIOUS: "culturago.nav.prev",
  NEXT: "culturago.nav.next",
  TABLE_OF_CONTENT: "culturago.nav.toc",
  SELECT_GUIDE: "culturago.nav.guide",
  DOWNLOAD: "culturago.nav.download",
  BACK_TO_GUIDES: "culturago.nav.guides"
};

export function addCustomEventListener<T>(
  event: string,
  handler: (event: CustomEvent<T>) => void
) {
  window.addEventListener(event, handler as EventListener);
}

export function removeCustomEventListener<T>(
  event: string,
  handler: (event: CustomEvent<T>) => void
) {
  window.removeEventListener(event, handler as EventListener);
}

export const CULTURA_GUIDES_ZONES = {
  DOWNLOAD: "guide",
  BOTTOM: "guide page -> bottom",
  TABLE_OF_CONTENT: "guide page -> table of content",
  HOME_PAGE: "country guide homepage"
};
