import { Action } from "redux";

import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import {
  CLOSE_INSTALLATION_MODAL,
  OPEN_INSTALLATION_MODAL
} from "./installation-modal-action-types";

export type InstallationModalState = {
  showModal: boolean;
};

const initialState: InstallationModalState = {
  showModal: false
};

const installationModalHandler: Mapping<Function> = {
  [OPEN_INSTALLATION_MODAL]: (state: InstallationModalState) => ({
    ...state,
    showModal: true
  }),
  [CLOSE_INSTALLATION_MODAL]: (state: InstallationModalState) => ({
    ...state,
    showModal: false
  })
};

export const installationModalReducer = (
  state: InstallationModalState = initialState,
  action: Action
) => {
  return handle(state, action, installationModalHandler);
};
