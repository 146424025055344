import { all, fork } from "redux-saga/effects";
import appInitSagas from "app/app-sagas";
import { analyticsSagas } from "@customer_interactions/application-logger";
import configurationSagas from "features/configuration/sagas/configuration-sagas";
import globalPartnerParametersSagas from "features/global-partner-parameters/sagas/global-partner-parameters-saga";
import urlParametersSagas from "features/url-parameters/sagas/url-parameters-saga";
import languageSagas from "features/language/sagas/language-sagas";
export default function* rootSaga() {
  yield all([
    fork(appInitSagas),
    fork(analyticsSagas),
    fork(configurationSagas),
    fork(globalPartnerParametersSagas),
    fork(urlParametersSagas),
    fork(languageSagas)
  ]);
}
