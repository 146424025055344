import { imagesBaseURL } from "constant";
import { useTheme } from "styled-components";
import { Theme } from "styles";
import { useTranslate } from "@customer_interactions/i18n";
import SuitcaseIcon from "app/components/icons/suitcase-icon";
import PageCircleIcon from "app/components/icons/page-circle-icon";
import MedicalCircleIcon from "app/components/icons/medical-circle-icon";
import CheckCircleIcon from "app/components/icons/check-circle-icon";
import { useSelector } from "react-redux";
import { selectIsDebugModeActive } from "features/debug-mode/selectors/debug-mode-selectors";

export const ROUTING_CARDS = [
  {
    id: "coverage",
    text: "[routing_page]-button_1",
    icon: SuitcaseIcon,
    href: "[routing_page]-button_href_1"
  },
  {
    id: "claim",
    text: "[routing_page]-button_2",
    icon: PageCircleIcon,
    href: "[routing_page]-button_href_2"
  },
  {
    id: "medical-assistance",
    text: "[routing_page]-button_3",
    icon: MedicalCircleIcon,
    href: "[routing_page]-button_href_3"
  },
  {
    id: "manage-policy",
    text: "[routing_page]-button_4",
    icon: CheckCircleIcon,
    href: "[routing_page]-button_href_4"
  }
];

export const useLanding = () => {
  const t = useTranslate();
  const isDebugModeActive = useSelector(selectIsDebugModeActive);
  const backgroundImageSet = {
    desktop: `"${imagesBaseURL}landing.webp", "${imagesBaseURL}landing.png"`,
    mobile: `"${imagesBaseURL}landing-mobile.webp", "${imagesBaseURL}landing-mobile.png"`
  };

  const theme = useTheme() as Theme;

  const cards = isDebugModeActive
    ? ROUTING_CARDS
    : ROUTING_CARDS.reduce<typeof ROUTING_CARDS[number][]>(
        (cardsArray, cardInfo) => {
          const cardText = t(cardInfo.text, true);
          const cardHref = t(cardInfo.href, true);
          if (cardText && cardHref) {
            cardsArray.push(cardInfo);
          }
          return cardsArray;
        },
        []
      );

  return {
    backgroundImageSet,
    cards,
    theme
  };
};
