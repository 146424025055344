import React, { PropsWithChildren } from "react";
import Loading from "../loading-screen/loading";
import { useIsLoading } from "./use-is-loading";

export const LoadingScreenWrapper = ({
  children,
  externalLoading = false
}: PropsWithChildren<{ externalLoading?: boolean }>) => {
  const { isLoading } = useIsLoading();

  if (isLoading || externalLoading) {
    const subtitle =
      window.sessionStorage.getItem("LoadingSubtitle") ?? "[Loading_Page]Text";
    return <Loading subtitleKey={subtitle} />;
  }

  return <>{children}</>;
};
