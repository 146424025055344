import { useTranslate } from "@customer_interactions/i18n";
import { routes } from "constant";
import React from "react";
import {
  NotFoundContainer,
  NotFoundLink,
  NotFoundMiniText,
  NotFoundTitle,
  Text404,
  NotFoundMiniTextContainer,
  BackHomeButton
} from "./not-found-styles";

const NotFound = () => {
  const t = useTranslate();

  return (
    <NotFoundContainer data-testid="not-found-container">
      <Text404 data-testid="404-text">{t("[Page_Not_Found]Title")}</Text404>
      <NotFoundTitle>{t("[Page_Not_Found]Text")}</NotFoundTitle>
      <NotFoundMiniTextContainer>
        <NotFoundMiniText>{t("[Page_Not_Found]Minitext")}</NotFoundMiniText>
        <NotFoundLink to={t("[Page_Not_Found]Minitext_Url")} target="_blank">
          {t("[Page_Not_Found]Minitext_Url_Label")}
        </NotFoundLink>
      </NotFoundMiniTextContainer>
      <BackHomeButton to={routes.ROOT}>
        {t("[Page_Not_Found]Button_Label")}
      </BackHomeButton>
    </NotFoundContainer>
  );
};

export default NotFound;
