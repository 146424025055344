import React, { useState } from "react";
import {
  CollapsableContainer,
  CollapsableTextContent
} from "../shared/modal/modal-styles";
import ReactMarkdown from "react-markdown";
import { ShowMoreButton } from "../shared/show-more-button/show-more-button";

export type ContactUsCollapsableProps = {
  expandLabel: string;
  collapseLabel: string;
  content: string;
};

export const ContactUsCollapsable = ({
  expandLabel,
  collapseLabel,
  content
}: ContactUsCollapsableProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <CollapsableContainer>
      <ShowMoreButton
        shouldShowMore={showMore}
        showLessText={collapseLabel}
        showMoreText={expandLabel}
        onClick={() => setShowMore(!showMore)}
      />
      {showMore && (
        <CollapsableTextContent>
          <ReactMarkdown linkTarget="_blank" children={content} skipHtml />
        </CollapsableTextContent>
      )}
    </CollapsableContainer>
  );
};
