import { useEffect, useRef } from "react";

export const useOutsideClick = (callback: Function) => {
  const htmlNodeRef = useRef<HTMLObjectElement>(null);

  const handleClickOutside = (event: TouchEvent | MouseEvent) => {
    if (!htmlNodeRef.current?.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, [handleClickOutside]);

  return { htmlNodeRef };
};
