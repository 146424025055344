import { useSelector } from "react-redux";
import { Analytics } from "@customer_interactions/application-logger";
import {
  selectGoBackButton,
  selectRedirectUrls
} from "features/configuration/selectors/configuration-selectors";
import useTranslation from "./use-translation";
import { useEffect, useState } from "react";
import {
  selectUrlParametersBrand,
  selectUrlParametersPolicyNumber
} from "features/url-parameters/selectors/url-parameters-selectors";

const useExternalGoBackButton = () => {
  const isShowButtonEnable = useSelector(selectGoBackButton);
  const [shouldShowButton, setShouldShowButton] = useState<boolean>(false);
  const [href, setHref] = useState<string>("");
  const [label, setLabel] = useState<string>("");

  const defaultLabel = useTranslation("[partner_site_button]Label");
  const customLabel = useTranslation("[partner_site_button]CustomLabel");
  const partnerSiteURL = useTranslation("[partner_site_button]URL");

  const brandNameParameter = useSelector(selectUrlParametersBrand);
  const policyNumberParameter = useSelector(selectUrlParametersPolicyNumber);
  const redirectURLs = useSelector(selectRedirectUrls);

  useEffect(() => {
    if (!isShowButtonEnable) {
      return;
    }

    if (!brandNameParameter || !policyNumberParameter) {
      setHref(partnerSiteURL);
      setLabel(defaultLabel);
      setShouldShowButton(true);
      return;
    }

    const redirectHref = redirectURLs[brandNameParameter];
    if (!redirectHref) {
      return;
    }

    setHref(`${redirectHref}?pno=${policyNumberParameter}`);
    setLabel(`${customLabel} ${brandNameParameter}`);
    setShouldShowButton(true);
  }, []);

  const handleOnClick = () => {
    Analytics.sendEvent(
      "[customer_space_page] Partner_Site_button-Click",
      "Customer_Space"
    );
  };
  return { shouldShowButton, handleOnClick, href, label };
};

export default useExternalGoBackButton;
