import React from "react";
import {
  LoginModal as Modal,
  LoginImage,
  LoginImageContainer,
  TextContainer,
  LoginModalList,
  LoginModalText,
  LoginModalBoldText,
  LoginModalItemList,
  LoginModalH2,
  LoginModalH4
} from "./login-modal-styles";
import ReactMarkdown from "react-markdown";

interface LoginModalBodyProps {
  text: string;
}

const LoginModalBody = ({ text }: LoginModalBodyProps) => {
  const markdownComponents = {
    ul: LoginModalList,
    p: LoginModalText,
    strong: LoginModalBoldText,
    li: LoginModalItemList,
    h2: LoginModalH2,
    h4: LoginModalH4
  };

  return (
    <Modal>
      <LoginImageContainer>
        <LoginImage src="/assets/images/login-register-modal.png" alt="Login" />
      </LoginImageContainer>
      <TextContainer>
        <ReactMarkdown components={markdownComponents}>{text}</ReactMarkdown>
      </TextContainer>
    </Modal>
  );
};

export default LoginModalBody;
