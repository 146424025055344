import React, { useState } from "react";
import { useTheme } from "styled-components";
import {
  CustomWorldIcon,
  CustomDownArrow,
  getSelectStyles,
  SelectLanguage,
  Separator,
  WorldIconContainer
} from "./language-selector-styles";

import { setCurrentLanguageSuccess } from "features/language/actions/language-actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfiguration,
  selectLanguages
} from "features/configuration/selectors/configuration-selectors";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import { useTranslate } from "@customer_interactions/i18n";
import { getLanguageIsoCode } from "utils/language-utils";
import { components } from "react-select";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { useWindowSize } from "hooks/use-window-size";
import { useOutsideClick } from "hooks/use-outside-click";

interface OptionType {
  value: string;
  label: string;
}

const Control = ({ children, ...props }: any) => (
  <components.Control {...props}>
    <Separator />
    <WorldIconContainer>
      <CustomWorldIcon />
    </WorldIconContainer>
    {children}
    <CustomDownArrow
      data-testid="selectable-language-area"
      style={{ transform: props.menuIsOpen ? "rotate(180deg)" : "" }}
    />
  </components.Control>
);

const LanguageSelector = () => {
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const t = useTranslate();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);
  const config = useSelector(selectConfiguration);
  const currentLanguage = useSelector(selectCurrentLanguageIsoCode);
  const selectStyles = getSelectStyles(theme);

  const toggleMenu = () => setMenuIsOpen(prevMenuOpen => !prevMenuOpen);
  const { htmlNodeRef: selectorRef } = useOutsideClick(() =>
    setMenuIsOpen(false)
  );
  const handleChangeLanguage = (option: any) => {
    const selectedLanguage = option.value;

    if (option) {
      dispatch(
        setCurrentLanguageSuccess(`${selectedLanguage}_${config.partnerId}`)
      );
    }
  };

  const getTranslatedLanguageName = (language: string) => {
    return t(`[Language_Selector]_${getLanguageIsoCode(language)}`);
  };

  const translatedLabel = isMobile
    ? currentLanguage
    : getTranslatedLanguageName(currentLanguage);
  const currentLanguageSelected = {
    value: currentLanguage,
    label: translatedLabel
  };

  const options = languages.reduce((acc: OptionType[], item: string) => {
    const option = {
      value: item,
      label: getTranslatedLanguageName(item),
      title: getTranslatedLanguageName(item)
    };
    // react-select focuses first option by default so this is why selected element it's always at the begining
    if (item === currentLanguage) {
      acc.unshift(option);
    } else {
      acc.push(option);
    }
    return acc;
  }, []);

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      callbackOnKeyboardEvent(e, "Enter", toggleMenu);
    }
    if (e.key === "Escape") {
      callbackOnKeyboardEvent(e, "Escape", () => setMenuIsOpen(false));
    }
  };

  const handleOpenCloseMenu = (value: boolean) => {
    if (isMobile) {
      setMenuIsOpen(value);
    }
  };

  return (
    <div
      onClick={toggleMenu}
      onKeyDown={e => handleOnKeyDown(e)}
      ref={selectorRef}
    >
      <SelectLanguage
        onMenuOpen={() => handleOpenCloseMenu(true)}
        onMenuClose={() => handleOpenCloseMenu(false)}
        menuIsOpen={menuIsOpen}
        aria-label={t("[accessibility] language-dropdown-label")}
        styles={selectStyles}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Control
        }}
        isSearchable={false}
        data-testid="select-languages"
        data-action={`${currentLanguage}-clicked`}
        options={options}
        defaultValue={currentLanguageSelected}
        value={currentLanguageSelected}
        onChange={handleChangeLanguage}
        menuPlacement="bottom"
        aria-selected
      />
    </div>
  );
};

export default LanguageSelector;
