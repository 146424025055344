import React from "react";
import { Button } from "./go-back-button-styles";
import ArrowLeftIcon from "app/components/icons/arrow-left-icon";

interface GoBackButtonProps {
  href: string;
  label: string;
  show: boolean;
  handleOnClick?: () => void;
}

export const GoBackButton = ({
  href,
  label,
  show,
  handleOnClick
}: GoBackButtonProps) => {
  return show ? (
    <Button
      onClick={() => handleOnClick?.()}
      to={href}
      aria-label="go back button"
    >
      <ArrowLeftIcon />
      {label}
    </Button>
  ) : null;
};
