import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Analytics } from "@customer_interactions/application-logger";
import { routes } from "constant";
import { trackCompletedAuthenticationJourneyEvent } from "features/analytics-events/track-completed-authentication-journey-event";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import usePrevious from "hooks/use-previous-value";
import { useSelector, useStore } from "react-redux";
import { useLocation } from "react-router-dom";
import { scrollUp } from "utils/window-utils";
import { AppRoutes } from "./routes";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { usePageView } from "hooks/use-pageview";

export const RoutesWithAuth0 = () => {
  const store = useStore();
  const config = useSelector(selectConfiguration);
  const currentLanguage = useSelector(selectCurrentLanguageIsoCode);
  const location = useLocation();
  const prevLocationName = usePrevious(location.pathname);
  const isRefreshPage = location.pathname === routes.REFRESH_SESSION;

  const {
    isAuthenticated,
    user,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();
  const isEmailVerified = user?.email_verified;

  usePageView();

  useEffect(() => {
    const hasAuth0RedirectCode =
      location.search.includes("code") && location.pathname === routes.HOME;

    if (hasAuth0RedirectCode && isEmailVerified) {
      trackCompletedAuthenticationJourneyEvent("log in", store.getState());
    }
  }, [location, isEmailVerified]);

  useEffect(() => {
    // this is needed to refresh the user metadata in case the user verified the email
    if (
      prevLocationName === routes.EMAIL_NOT_VERIFIED &&
      prevLocationName !== location.pathname
    ) {
      getAccessTokenSilently({ ignoreCache: true });
    }

    scrollUp();
    Analytics.pageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (isRefreshPage && config.auth0ClientId) {
      loginWithRedirect({ lang: currentLanguage });
      trackCompletedAuthenticationJourneyEvent(
        "registration",
        store.getState()
      );
    }
  }, [config]);

  return (
    <AppRoutes
      isAuth0Loading={isAuth0Loading || isRefreshPage}
      isAuthenticated={isAuthenticated}
      isEmailVerified={!!isEmailVerified}
    />
  );
};
