import { useWindowSize } from "hooks/use-window-size";
import React, { FC } from "react";
import {
  Card,
  HeaderSubtitle,
  HeaderTextContainer,
  HeaderTitle,
  PageHeaderContainer
} from "./page-header-styles";

interface PageHeaderProps {
  URLDesktop: string;
  URLMobile: string;
  title: string;
  subtitle: string;
  CtaButton?: FC;
}

const PageHeader: FC<PageHeaderProps> = ({
  URLDesktop,
  URLMobile,
  title,
  subtitle,
  CtaButton
}) => {
  const { isMobile } = useWindowSize();

  const HeaderText = () => (
    <>
      <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      <HeaderTitle>{title}</HeaderTitle>
    </>
  );

  const getHeaderContentResponsive = isMobile ? (
    <Card>
      <HeaderText />
      {CtaButton && <CtaButton />}
    </Card>
  ) : (
    <>
      <HeaderTextContainer>
        <HeaderText />
      </HeaderTextContainer>
      {CtaButton && <CtaButton />}
    </>
  );
  return (
    <PageHeaderContainer URLDesktop={URLDesktop} URLMobile={URLMobile}>
      {getHeaderContentResponsive}
    </PageHeaderContainer>
  );
};

export default PageHeader;
