import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import {
  ScrollableSection,
  Section
} from "app/components/shared/scrollable-section/scrollable-section";
import { useWindowSize } from "hooks/use-window-size";
import { BannerTitle, Banner, Card } from "./privacy-policy-styles";
import { LegalSection } from "./sections/legal-section";
import { PrivacyPolicySection } from "./sections/privacy-section";
import { TermsOfServicesSection } from "./sections/terms-of-services-section";
import { usePrivacyPolicy } from "./use-privacy-policy";

const BannerTitleWrapped = ({ isMobile }: { isMobile: boolean }) => {
  const t = useTranslate();
  return isMobile ? (
    <Card>
      <BannerTitle>{t("[privacy_policy]title")}</BannerTitle>
    </Card>
  ) : (
    <BannerTitle>{t("[privacy_policy]title")}</BannerTitle>
  );
};

export const PrivacyPolicyPage = () => {
  const { isMobile } = useWindowSize();

  const sections: Section[] = [
    {
      id: "legal",
      content: <LegalSection />
    },
    {
      id: "terms_of_service",
      content: <TermsOfServicesSection />
    },
    {
      id: "terms_conditions",
      content: <PrivacyPolicySection />
    }
  ];

  const { tabs, sectionsToRender } = usePrivacyPolicy(sections);

  return (
    <div>
      <Banner data-testid="privacy-policy-banner">
        <BannerTitleWrapped isMobile={isMobile} />
      </Banner>
      <ScrollableSection tabs={tabs} sections={sectionsToRender} />
    </div>
  );
};
