import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const CheckCircleIcon = ({ theme, color, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_5498_18782)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0056 9.24395C22.4188 9.21048 22.8272 9.35097 23.1324 9.63155C23.7786 10.2192 23.8566 11.208 23.3104 11.8896L15.3194 22.0304C14.9532 22.4941 14.3948 22.7646 13.8039 22.7646C13.2131 22.7646 12.6547 22.4941 12.2885 22.0304L8.25465 16.7594C7.72152 16.0856 7.79403 15.1156 8.42141 14.5285C8.72634 14.2473 9.13469 14.106 9.54817 14.1386C9.96971 14.1747 10.3566 14.3866 10.6141 14.7223L13.7961 18.862L20.942 9.81859C21.2008 9.48705 21.5864 9.27872 22.0056 9.24395ZM14.9566 21.733L22.9476 11.5921C23.3412 11.1017 23.2891 10.3905 22.8282 9.96282C22.6146 9.76616 22.3288 9.66735 22.0394 9.69014C21.7437 9.71619 21.4723 9.86494 21.2913 10.1003L13.9696 19.3645C13.9271 19.4183 13.8624 19.4498 13.7938 19.4501C13.7241 19.45 13.6583 19.4175 13.6158 19.3623L10.258 14.9949C10.0772 14.7594 9.80572 14.6106 9.50986 14.5848H9.42197C9.16038 14.5853 8.90857 14.6842 8.71662 14.862C8.26213 15.2885 8.21115 15.9924 8.59944 16.48L12.6332 21.733C12.914 22.0883 13.342 22.2956 13.7949 22.2956C14.2478 22.2956 14.6758 22.0883 14.9566 21.733Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 8.06511 26.1875 1.36116 18.4214 0.182062C17.6249 0.0611426 16.8163 0 16 0C7.16344 0 0 7.16344 0 16ZM31.5493 16C31.5493 24.5876 24.5876 31.5493 16 31.5493C7.41236 31.5493 0.450704 24.5876 0.450704 16C0.450704 7.41236 7.41236 0.450704 16 0.450704C16.7936 0.450704 17.5797 0.510137 18.3537 0.62766C25.9004 1.77344 31.5493 8.28872 31.5493 16Z"
        fill={color ?? theme.blue["400"]}
      />
    </g>
    <defs>
      <clipPath id="clip0_5498_18782">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default withTheme(CheckCircleIcon);
