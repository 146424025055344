import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { SectionSubtitle, Header, MarkdownContent } from "./sections-styles";
import { PrivacyTable } from "./privacy-policy-table";
import { IfTranslationExists } from "utils/poeditor-utils";

const SectionTitleAndTextIfExists = ({ section }: { section: string }) => {
  const t = useTranslate();

  return (
    <IfTranslationExists translationKey={`[privacy_site]${section}_title`}>
      <SectionSubtitle>{t(`[privacy_site]${section}_title`)}</SectionSubtitle>
      <MarkdownContent>{t(`[privacy_site]${section}_text`)}</MarkdownContent>
    </IfTranslationExists>
  );
};

export const PrivacyPolicySection = () => {
  const t = useTranslate();

  return (
    <>
      <Header>{t("[legal][privacy_policy]title")}</Header>
      <IfTranslationExists translationKey={"[privacy_site]intro"}>
        <MarkdownContent>{t("[privacy_site]intro")}</MarkdownContent>
      </IfTranslationExists>
      <SectionTitleAndTextIfExists section="section1" />
      <SectionTitleAndTextIfExists section="section2" />
      <SectionTitleAndTextIfExists section="section3" />
      <SectionTitleAndTextIfExists section="section4" />
      <SectionTitleAndTextIfExists section="section5" />
      <SectionTitleAndTextIfExists section="section6" />
      <SectionTitleAndTextIfExists section="section7" />
      <IfTranslationExists translationKey="[privacy_site]section7_table_header1">
        <PrivacyTable />
      </IfTranslationExists>
      <SectionTitleAndTextIfExists section="section8" />
      <SectionTitleAndTextIfExists section="section9" />
      <SectionTitleAndTextIfExists section="section10" />
      <SectionTitleAndTextIfExists section="section11" />
      <SectionTitleAndTextIfExists section="section12" />
      <SectionTitleAndTextIfExists section="section13" />
    </>
  );
};
