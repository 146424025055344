import FocusTrapElements from "app/components/focus-trap/focus-trap";
import { useOutsideClick } from "hooks/use-outside-click";
import React, { FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ButtonContainer,
  ModalInformation,
  CloseModalButton,
  ModalMainContent,
  CloseModalButtonContainer,
  ModalTitle
} from "./modal-styles";

export interface ModalProps {
  title: string;
  onClose: () => void;
  initiatorElement?: HTMLElement | null;
  children?: React.ReactNode;
  buttonSection?: React.ReactNode;
  closeOnOutsideClick?: boolean;
}
const Modal: FunctionComponent<ModalProps> = ({
  title,
  onClose,
  children,
  buttonSection,
  closeOnOutsideClick = true
}) => {
  const { htmlNodeRef: modalRef } = useOutsideClick(() =>
    closeOnOutsideClick ? onClose() : null
  );
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) =>
    callbackOnKeyboardEvent(e, "Escape", onClose);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return ReactDOM.createPortal(
    <FocusTrapElements fallbackFocus={modalRef.current!}>
      <ModalContainer
        data-testid="modal-notifications"
        onKeyDown={handleKeyDown}
      >
        <ModalContent ref={modalRef}>
          <ModalHeader>
            <ModalTitle data-testid="modal-title">{title}</ModalTitle>
            <CloseModalButtonContainer
              onClick={onClose}
              data-testid="close-modal-button"
            >
              <CloseModalButton />
            </CloseModalButtonContainer>
          </ModalHeader>
          <ModalMainContent>
            <ModalInformation>{children}</ModalInformation>
          </ModalMainContent>
          {buttonSection && <ButtonContainer>{buttonSection}</ButtonContainer>}
        </ModalContent>
      </ModalContainer>
    </FocusTrapElements>,
    document.body
  );
};

export default Modal;
