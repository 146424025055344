import React from "react";
import { Description } from "./assistance-services-header-styles";
import { SectionColumnContainer, SectionTitle } from "styles";

interface AssistanceServiceHeaderProps {
  title: string;
  description: string;
}

export const AssistanceServiceHeader = ({
  title,
  description
}: AssistanceServiceHeaderProps) => {
  return (
    <SectionColumnContainer data-testid="assistance-service-section">
      <SectionTitle data-testid="assistance-service-title">
        {title}
      </SectionTitle>
      <Description data-testid="assistance-service-description">
        {description}
      </Description>
    </SectionColumnContainer>
  );
};
