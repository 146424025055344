import styled, { css } from "styled-components";
import { getCssForMobileView, getCssForTabletView } from "styles/responsive";

const WebComponentContainerMobile = css`
  padding: 36px 18px;
`;

const WebComponentContainerTablet = css`
  padding: 8px;
`;

export const WebComponentContainer = styled.div`
  flex: 0 1 50%;
  padding: 68px 56px;
  ${getCssForMobileView(WebComponentContainerMobile)}
  ${getCssForTabletView(WebComponentContainerTablet)}
`;
