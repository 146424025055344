import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { UPDATE_MENU_OPEN } from "../actions/menu-action-types";
import { ActionStandard } from "app/store/types";

const menuStateHandlers: Mapping<Function> = {
  [UPDATE_MENU_OPEN]: (_state: boolean, _action: ActionStandard<boolean>) =>
    _action.payload
};

const initialState = false;

export const menuReducer = (
  state: boolean = initialState,
  action: ActionStandard<boolean>
) => {
  return handle(state, action, menuStateHandlers);
};
