import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";

export const trackSearchEvent = (searchType: string, zone?: string) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.SEARCH,
    search_type: searchType,
    ...(zone && { zone })
  });
};
