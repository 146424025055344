import CloseModalIcon from "app/components/icons/close-modal-icon";
import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContentMobile = css`
  margin: 0 1rem;
`;

const ModalSectionsMobile = css`
  padding-inline: 18px;
`;

export const ModalContent = styled.div`
  width: 788px;
  background: #ffffff;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.2);
  ${getCssForMobileView(ModalContentMobile)};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  padding-left: 24px;
  padding-right: 32px;
  ${getCssForMobileView(ModalSectionsMobile)};
`;

export const ModalTitle = styled.h4`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: ${({ theme }) => theme.grey["800"]};
  letter-spacing: initial;

  ${getCssForMobileView(
    css`
      font-family: ${({ theme }) => theme.secondaryFontFamily};
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0px;
    `
  )};
`;

export const ModalText = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 32px;
  letter-spacing: initial;
  ${getCssForMobileView(ModalSectionsMobile)};
`;

export const ModalInformation = styled.div`
  padding-left: 24px;
  padding-right: 32px;
  ${getCssForMobileView(ModalSectionsMobile)};
`;

export const TextContainer = styled.div`
  margin: 20px 0px;
  white-space: break-spaces;
`;

export const CollapsableContainer = styled.div`
  padding: 8px 0px;
`;

export const CollapsableTextContent = styled.div`
  font-size: 0.875rem;
`;

export const CloseModalButtonContainer = styled.button`
  background: inherit;
`;

export const CloseModalButton = styled(CloseModalIcon)`
  cursor: pointer;
`;

export const ModalMainContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grey["100"]};
  border-bottom: 1px solid ${({ theme }) => theme.grey["100"]};
`;
