export function loadManifestFile(manifestFolder?: string) {
  if (!manifestFolder) {
    return;
  }

  const origin = window.location.origin;
  const link = document.createElement("link");
  link.href = `${origin}/manifests/${manifestFolder}/manifest.webmanifest`;
  link.rel = "manifest";

  document.getElementsByTagName("head")[0].appendChild(link);
}
