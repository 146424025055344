import React from "react";
import { useDispatch } from "react-redux";
import { Link, LinkProps } from "react-router-dom";
import { ExternalLink } from "./link-resolver-styles";
import { appIsLoading, appLoaded } from "features/loading/loading-actions";
import { ButtonVariant } from "../shared/button";

const DELAY_EXTERNAL_REDIRECTION = 300;

export interface LinkResolverProps extends LinkProps {
  isExternalService?: boolean;
  variant?: ButtonVariant;
  shouldRefresh?: boolean;
}

export const LinkResolver = ({
  to,
  children,
  isExternalService = false,
  shouldRefresh,
  innerRef,
  ...props
}: LinkResolverProps) => {
  const dispatch = useDispatch();

  const showRedirectionLoading = (
    delay: number = DELAY_EXTERNAL_REDIRECTION
  ) => {
    window.sessionStorage.setItem(
      "LoadingSubtitle",
      "[Loading_Page]Redirection_text"
    );
    dispatch(appIsLoading());
    setTimeout(() => {
      dispatch(appLoaded());
    }, delay);
  };

  const handleRedirectToExternalURL = (
    e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    showRedirectionLoading();
    if (!!props.onClick) {
      props.onClick(e);
    }
  };

  const url = new URL(to, document.baseURI);
  const isExternalURL = shouldRefresh || url.origin !== location.origin;

  if (isExternalURL) {
    return (
      <ExternalLink
        role="link"
        href={to}
        target={isExternalService ? "_blank" : "_self"}
        rel="noopener noreferrer"
        {...props}
        onClick={e => handleRedirectToExternalURL(e)}
        ref={innerRef}
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link {...props} to={to} innerRef={innerRef}>
      {children}
    </Link>
  );
};
