import { ActionStandard } from "app/store/types";
import {
  SET_INSTALLATION_MODAL_DISPLAYED_ON_FIRST_VISIT,
  OPEN_INSTALLATION_MODAL,
  CLOSE_INSTALLATION_MODAL
} from "../reducer/installation-modal-action-types";

export const setInstallationModalDisplayedOnFirstVisit = (
  payload: boolean
): ActionStandard<boolean> => ({
  type: SET_INSTALLATION_MODAL_DISPLAYED_ON_FIRST_VISIT,
  payload
});

export const openInstallationModal = (): ActionStandard<undefined> => ({
  type: OPEN_INSTALLATION_MODAL,
  payload: undefined
});

export const closeInstallationModal = (): ActionStandard<undefined> => ({
  type: CLOSE_INSTALLATION_MODAL,
  payload: undefined
});
