import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const AssistanceContainerMobileStyles = css`
  flex-direction: column;
  order: unset;
  width: unset;
  background-color: transparent;
  padding: 0px;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.grey["200"]};
  padding: 40px 40px 56px 40px;
  order: 4;
  width: 100%;
  ${getCssForMobileView(AssistanceContainerMobileStyles)}
`;
