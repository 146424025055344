import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "constant";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import { useHamburguerMenuActions } from "hooks/use-hamburguer-menu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const useLoginButton = () => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();

  const { closeMenu } = useHamburguerMenuActions();

  const history = useHistory();

  const currentLanguage = useSelector(selectCurrentLanguageIsoCode);

  const isEmailVerified = user?.email_verified;

  const handleLoginClick = () => {
    if (!isAuthenticated) {
      loginWithRedirect({ lang: currentLanguage });
      return;
    }

    if (!isEmailVerified) {
      closeMenu();
      history.push(routes.EMAIL_NOT_VERIFIED);
    }
  };

  return { handleLoginClick };
};
