import React from "react";
import {
  HeaderContainer,
  HeaderDomain,
  HeaderTextContainer,
  HeaderTitle,
  InstructionsContainer,
  ModalContent,
  ModalContentImage,
  ScreenshotsContainer
} from "./modal-content-styles";
import ReactMarkdown from "react-markdown";
import useTranslation from "hooks/use-translation";

export function ModalContentIOS() {
  const instructions = useTranslation("[Home_PWA_IOS]Text");

  const hostname = window.location.hostname;

  return (
    <ModalContent>
      <HeaderContainer>
        <ModalContentImage
          alt="my trip companion logo"
          src="/assets/images/my-tc-logo.png"
        />
        <HeaderTextContainer>
          <HeaderTitle>My Trip Companion</HeaderTitle>
          <HeaderDomain>{hostname}</HeaderDomain>
        </HeaderTextContainer>
      </HeaderContainer>
      <ReactMarkdown
        children={instructions}
        components={{ ol: InstructionsContainer }}
      />
      <ScreenshotsContainer>
        <ModalContentImage
          alt="ios safari share button"
          src="/assets/images/PWA-modal-ios-1.png"
        />
        <ModalContentImage
          alt="ios safari add to home screen dropdown"
          src="/assets/images/PWA-modal-ios-2.png"
        />
        <ModalContentImage
          alt="ios safari add to home screen name app"
          src="/assets/images/PWA-modal-ios-3.png"
        />
      </ScreenshotsContainer>
    </ModalContent>
  );
}
