import {
  LANGUAGE_SELECTOR_MOBILE_CONTAINER_ID,
  NAV_DESKTOP_CONTAINER_ID,
  NAV_MOBILE_CONTAINER_ID
} from "constant";
import { useWindowSize } from "./use-window-size";

function getElementHeightById(id: string) {
  return document.getElementById(id)?.clientHeight || 0;
}

/**
 *
 * @returns A function that returns the sticky header height depending on the screen size
 */
export const useStickyHeaderHeight = (): (() => number) => {
  const { isMobile } = useWindowSize();

  return () => {
    const navDesktopContainerHeight = getElementHeightById(
      NAV_DESKTOP_CONTAINER_ID
    );

    const navMobileContainerHeight = getElementHeightById(
      NAV_MOBILE_CONTAINER_ID
    );
    const languageSelectorMobileContainerHeight = getElementHeightById(
      LANGUAGE_SELECTOR_MOBILE_CONTAINER_ID
    );

    return isMobile
      ? navMobileContainerHeight + languageSelectorMobileContainerHeight
      : navDesktopContainerHeight;
  };
};
