import React from "react";
import {
  AssistanceContainer,
  AssistanceMainContainer
} from "./assistance-service-list-styles";
import { AssistanceService } from "features/configuration/configuration-types";
import { AssistanceServiceItem } from "../assistance-service-item";

interface AssistanceServiceListProps {
  assistanceServices: AssistanceService[];
  onSelectAssistanceService: (item: AssistanceService | null) => void;
}

export const AssistanceServiceList = ({
  assistanceServices,
  onSelectAssistanceService
}: AssistanceServiceListProps) => {
  return (
    <AssistanceMainContainer data-testid="assistance-section-main-container">
      <AssistanceContainer data-testid="assistance-section-container">
        {assistanceServices?.map(
          (service: AssistanceService, index: number) => (
            <AssistanceServiceItem
              onSelectAssistanceService={onSelectAssistanceService}
              key={service.id}
              order={index + 1}
              item={service}
            ></AssistanceServiceItem>
          )
        )}
      </AssistanceContainer>
    </AssistanceMainContainer>
  );
};
