import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const Button = styled.button`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blue["200"]};
  padding-right: 1em;
  background: inherit;
`;
