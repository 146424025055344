import React, { UIEvent, useEffect, useRef } from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { useWindowSize } from "hooks/use-window-size";
import { useAssistanceServices } from "hooks/use-assistance-services";
import { AssistanceService } from "features/configuration/configuration-types";
import { AssistanceServiceSubsectionList } from "app/components/assistance-service-subsections-list";
import { ShowMoreButton } from "app/components/shared/show-more-button/show-more-button";

import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import capitalize from "lodash/capitalize";
import {
  AssistanceItemCard,
  AssistanceItemContainer,
  ClaimItemIcon,
  ButtonContainer,
  ItemText,
  ItemTitle,
  MedicalItemIcon,
  TravelItemIcon
} from "./assistance-service-item-styles";
import { NAV_MOBILE_CONTAINER_ID } from "constant";

const getIcon = (id: string): React.ReactElement | null => {
  switch (id) {
    case "travel":
      return <TravelItemIcon />;
    case "medical":
      return <MedicalItemIcon />;
    case "claim":
      return <ClaimItemIcon />;
    default:
      return null;
  }
};

interface AssistanceServiceItemProps {
  item: AssistanceService;
  order: number;
  onSelectAssistanceService: (item: AssistanceService | null) => void;
}

export const AssistanceServiceItem = ({
  item,
  order,
  onSelectAssistanceService
}: AssistanceServiceItemProps) => {
  const t = useTranslate();
  const { isMobile } = useWindowSize();
  const { assistanceServiceSelected } = useAssistanceServices();
  const isItemSelected = assistanceServiceSelected?.id === item.id;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobile && isItemSelected && container.current) {
      const header = document.getElementById(NAV_MOBILE_CONTAINER_ID)!;
      const spaceFromHeader = 10;
      const verticalLocation =
        container.current.offsetTop -
        header.offsetHeight -
        header.offsetTop -
        spaceFromHeader;
      window.scrollTo({ top: verticalLocation, behavior: "smooth" });
    }
  }, [isItemSelected]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelectAssistanceService(isItemSelected && isMobile ? null : item);
  };

  return (
    <>
      <AssistanceItemContainer order={order}>
        <AssistanceItemCard
          data-testid={`assistance-item-container-${item.id}`}
          isItemSelected={isItemSelected}
          onClick={e =>
            ((e as unknown) as UIEvent).detail === 1 &&
            onSelectAssistanceService(item)
          }
          onKeyDown={e =>
            callbackOnKeyboardEvent(e, "Enter", () =>
              onSelectAssistanceService(item)
            )
          }
          ref={container}
        >
          {getIcon(item.id)}
          <ItemTitle data-testid={`assistance-item-title-${item.id}`}>
            {t(`[Home_Assistance_service]${capitalize(item.id)}_title`)}
          </ItemTitle>
          <ItemText data-testid={`assistance-item-text-${item.id}`}>
            {t(`[Home_Assistance_service]${capitalize(item.id)}_text`)}
          </ItemText>
          {isMobile && isItemSelected && <AssistanceServiceSubsectionList />}
          {isMobile && (
            <ButtonContainer>
              <ShowMoreButton
                shouldShowMore={isItemSelected}
                showLessText={t("[Home_Assistance_service]See_less_chevron")}
                showMoreText={t("[Home_Assistance_service]See_options_chevron")}
                onClick={handleClick}
              />
            </ButtonContainer>
          )}
        </AssistanceItemCard>
      </AssistanceItemContainer>
      {!isMobile && isItemSelected && <AssistanceServiceSubsectionList />}
    </>
  );
};
