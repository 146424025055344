import { useAuth0 } from "@auth0/auth0-react";
import { useHamburguerMenuActions } from "./use-hamburguer-menu";

const useMenuHeader = () => {
  const { isAuthenticated, user } = useAuth0();
  const {
    isMenuOpened,
    closeMenu,
    handleMenuClick
  } = useHamburguerMenuActions();

  const shouldShowMenuOptions = isAuthenticated && user?.email_verified;

  return {
    isMenuOpened,
    shouldShowMenuOptions,
    closeMenu,
    handleMenuClick
  };
};

export default useMenuHeader;
