import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { routes } from "constant";

interface Props extends RouteProps {
  isAuthenticated: boolean;
  restricted?: boolean;
  isEmailVerified?: boolean;
  component: FC<RouteProps>;
}

export const PublicRoute: FC<Props> = ({
  component: Component,
  restricted,
  isAuthenticated,
  isEmailVerified = false,
  ...rest
}) => {
  const privateRoute = isEmailVerified ? (
    <Redirect to={routes.HOME} />
  ) : (
    <Redirect to={routes.EMAIL_NOT_VERIFIED} />
  );

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && restricted ? privateRoute : <Component {...props} />
      }
    />
  );
};
