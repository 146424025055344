import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import ClaimIcon from "app/components/icons/claim-icon";
import BagaggeIcon from "app/components/icons/bagagge-icon";
import MedicalIcon from "app/components/icons/medical-icon";
import { LinkResolver } from "app/components/link-resolver/link-resolver";

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  left: 0;
  position: fixed;
  height: 100vh;
  z-index: 2500;
  top: 40px;
  background: white;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  overflow-y: auto;
`;

export const MenuMainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ServiceItem = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.grey["200"]};
`;

export const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 64px 24px 24px;
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.teal["200"]};
  word-break: break-all;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.grey["200"]};
`;

const iconStyles = `
  margin-right: 15px;
`;

export const ClaimIconStyled = styled(ClaimIcon)`
  ${iconStyles}
`;

export const BagaggeIconStyled = styled(BagaggeIcon)`
  ${iconStyles}
`;

export const MedicalIconStyled = styled(MedicalIcon)`
  ${iconStyles}
`;

const menuItemVariantStyles = (theme: any, variant = "primary") => {
  const variants: { [key: string]: FlattenSimpleInterpolation } = {
    primary: css`
      color: #333333;
    `,
    secondary: css`
      color: ${theme.blue["300"]};
      font-weight: 600;
      display: flex;
      gap: 8px;
      align-items: center;
    `
  };

  return variants[variant];
};

export const MenuItem = styled(LinkResolver)`
  padding: 24px 24px 24px 64px;
  font-size: 16px;
  line-height: 22px;
  text-decoration: inherit;
  overflow-wrap: break-word;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.grey["200"]};
  width: 100%;
  text-align: initial;
  ${({ theme, variant }) => menuItemVariantStyles(theme, variant)}
`;

export const MenuFooter = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-left: 53px;
  background-color: ${({ theme }) => theme.grey["200"]};
`;

export const MenuFooterItem = styled.div`
  padding-top: 7px;
`;

export const MenuHeaderExpanded = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.grey["200"]}`};
`;
