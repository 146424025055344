import styled from "styled-components";
import CrossIcon from "../icons/cross-icon";
import MenuIcon from "../icons/menu-icon";

export const MobileHeaderContainer = styled.div`
  position: relative;
  top: 40px;
  z-index: 1500;
  height: 65px;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgb(0 0 0 / 6%);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const iconStyles = `
  cursor: pointer;
  margin-right: 12px;
`;

export const CrossIconStyled = styled(CrossIcon)`
  ${iconStyles}
`;

export const MenuIconStyled = styled(MenuIcon)`
  ${iconStyles}
`;

export const LogoWrapper = styled.div`
  z-index: 3;
  margin-left: 20px;
`;
