import { useEffect, useRef } from "react";

import {
  isInstallTriggerSupported,
  isSiteInFullScreenMode,
  getModalTitlePOTag,
  getCloseModalPOTag,
  sendModalCloseEvent,
  sendModalInstallEvent
} from "./installation-modal-helpers";
import { supportsAddToHome } from "utils/user-agent";
import useTranslation from "hooks/use-translation";
import { useIsInstallModalSupported } from "./installation-modal-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInstallationModalDisplayedOnFirstVisit,
  selectShowModal
} from "features/installation-modal/selectors";
import {
  closeInstallationModal,
  openInstallationModal,
  setInstallationModalDisplayedOnFirstVisit
} from "features/installation-modal/actions";

export function useInstallationModal() {
  const dispatch = useDispatch();
  const modalAlreadyDisplayedOnFirstVisit = useSelector(
    selectInstallationModalDisplayedOnFirstVisit
  );

  const installPromptRef = useRef<BeforeInstallPromptEvent | null>(null);

  // need this ref to keep the value updated inside the beforeInstallPromptListener callback
  const modalAlreadyDisplayedOnFirstVisitRef = useRef<boolean>(
    modalAlreadyDisplayedOnFirstVisit
  );

  const showModal = useSelector(selectShowModal);

  const isModalSupported = useIsInstallModalSupported();

  const isInstallable = isInstallTriggerSupported();

  useEffect(() => {
    if (!isModalSupported) {
      return;
    }

    if (isInstallable) {
      // PWA installation is supported, add installation event listeners
      window.addEventListener(
        "beforeinstallprompt",
        beforeInstallPromptListener
      );
      window.addEventListener("appinstalled", appInstalledListener);
    } else {
      // trigger modal with manual instructions if "Add to Home" is supported by browser and wasnt displayed before
      if (
        supportsAddToHome() &&
        !isSiteInFullScreenMode() &&
        !modalAlreadyDisplayedOnFirstVisit
      ) {
        openModalFirstTime();
      }
    }

    return cleanupInstallEventListeners;
  }, []);

  const beforeInstallPromptListener = (event: BeforeInstallPromptEvent) => {
    event.preventDefault();
    installPromptRef.current = event;

    // prevent triggering modal automatically if it was already displayed before
    if (!modalAlreadyDisplayedOnFirstVisitRef.current) {
      openModalFirstTime();
    }
  };

  // detect if user installs the site
  const appInstalledListener = () => {
    dispatch(closeInstallationModal());
  };

  useEffect(() => {
    modalAlreadyDisplayedOnFirstVisitRef.current = modalAlreadyDisplayedOnFirstVisit;
  }, [modalAlreadyDisplayedOnFirstVisit]);

  const cleanupInstallEventListeners = () => {
    if (!isInstallable) {
      return;
    }
    window.removeEventListener(
      "beforeinstallprompt",
      beforeInstallPromptListener
    );
    window.removeEventListener("appinstalled", appInstalledListener);
  };

  const openModalFirstTime = () => {
    dispatch(setInstallationModalDisplayedOnFirstVisit(true));
    dispatch(openInstallationModal());
  };

  const onClose = () => {
    sendModalCloseEvent();
    dispatch(closeInstallationModal());
  };

  const handleInstall = () => {
    sendModalInstallEvent();
    if (!installPromptRef.current) {
      return;
    }
    // display native installation prompt
    installPromptRef.current.prompt();
    dispatch(closeInstallationModal());
  };

  const modalTitleText = useTranslation(getModalTitlePOTag());
  const closeModalButtonText = useTranslation(getCloseModalPOTag());
  const installButtonText = useTranslation("[HOME_PWA_Android]Add_button");

  return {
    showModal,
    onClose,
    handleInstall,
    isInstallable,
    modalTitleText,
    closeModalButtonText,
    installButtonText
  };
}
