import React, { useRef } from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { Analytics } from "@customer_interactions/application-logger";
import Phone from "../icons/phone";
import { TextContainer, Container } from "./contact-us.styles";
import { useSelector } from "react-redux";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import GenericContactUsIcon from "../icons/generic-contact-us-icon";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { useModal } from "hooks/use-modal";
import ContactUsModal from "./contact-us-modal";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";

export const ContactUs = () => {
  const { openModal, showModal, closeModal } = useModal();
  const t = useTranslate();
  const containerRef = useRef(null);
  const configuration = useSelector(selectConfiguration);
  const { hasContactUsTlfNumber } = configuration;
  const handleContactUsClick = () => {
    trackNavigationEvent("header", "contact");
    Analytics.sendEvent("[header]contact", "Customer_Space");
    if (!hasContactUsTlfNumber) {
      openModal();
    }
  };

  return (
    <>
      <Container
        ref={containerRef}
        data-testid="contact-us-phone-icon"
        href={hasContactUsTlfNumber ? `tel:${t("[phone]number")}` : undefined}
        onClick={handleContactUsClick}
        onKeyDown={e =>
          callbackOnKeyboardEvent(e, "Enter", handleContactUsClick)
        }
        tabIndex={0}
      >
        {hasContactUsTlfNumber ? <Phone /> : <GenericContactUsIcon />}
        <TextContainer>
          <span>{t("[phone]text")}</span>
          {hasContactUsTlfNumber && <span>{t("[phone]number")}</span>}
        </TextContainer>
      </Container>
      {showModal && (
        <ContactUsModal
          title={t("[Contact]Modal_title")}
          buttonText="[Contact]Modal_button"
          ctaButtonName="generic-contact-us-button"
          onClose={closeModal}
        />
      )}
    </>
  );
};
