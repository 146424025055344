/**
 * Removes all null and undefined values from an object
 * @param obj the object to sanitize
 * @returns a version of obj with all null and undefined values removed
 */
export const sanitizeObject = (obj: any) => {
  const sanitizedObject: any = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (value !== null && value !== undefined) {
      sanitizedObject[key] = value;
    }
  });

  return sanitizedObject;
};
