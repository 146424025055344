import { Auth0Provider } from "@auth0/auth0-react";
import { routes } from "constant";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";

const SSOConnection = "PartnerSSO";

export const Auth0Wrapper: FC = ({ children }) => {
  const config = useSelector(selectConfiguration);

  return config.auth0ClientId && config.auth0Domain ? (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={`${window.location.origin}${routes.HOME}`}
      cacheLocation="localstorage"
      useRefreshTokens
      connection={config.ssoConfig ? SSOConnection : undefined}
    >
      {children}
    </Auth0Provider>
  ) : (
    <> {children} </>
  );
};
