import { ActionStandard } from "app/store/types";

const DEBUG_MODE = "DEBUG_MODE";
export const TOGGLE_DEBUG_MODE: string = `${DEBUG_MODE}/TOGGLE_DEBUG_MODE`;
export const ENABLE_DEBUG_MODE: string = `${DEBUG_MODE}/ENABLE_DEBUG_MODE`;

export const toggleDebugMode = (): ActionStandard<boolean> => ({
  type: TOGGLE_DEBUG_MODE
});

export const enableDebugMode = (): ActionStandard<boolean> => ({
  type: ENABLE_DEBUG_MODE
});
