import { LinkResolver } from "app/components/link-resolver/link-resolver";
import { CTAButton } from "app/components/shared/cta-button/cta-button-styles";
import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const NotFoundContainerMobile = css`
  margin-top: 65px;
  align-items: start;
  padding: 0 25px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  ${getCssForMobileView(NotFoundContainerMobile)}
`;

const NotFoundTextBase = styled.p`
  color: ${({ theme }) => theme.grey["800"]};
  padding: 8px 0;
`;

export const Text404 = styled(NotFoundTextBase)`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const NotFoundTitle = styled(NotFoundTextBase)`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
`;

export const NotFoundMiniText = styled(NotFoundTextBase)`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-size: 16px;
  line-height: 24px;
  display: inline;
`;

export const NotFoundMiniTextContainer = styled.div`
  display: inline-block;
`;

export const NotFoundLink = styled(LinkResolver)`
  color: ${({ theme }) => theme.blue};
  font-weight: 600;
`;

const BackHomeMobile = css`
  width: 100%;
`;

export const BackHomeButton = styled(CTAButton)`
  justify-content: center;
  color: ${({ theme }) => theme.blue["300"]};
  background-color: ${({ theme }) => theme.white};
  width: unset;
  max-width: none;
  ${getCssForMobileView(BackHomeMobile)}
`;
