import { subsectionIdToTagName } from "constant";
import { DropdownItem } from "./trip-section-styles";
import { AssistanceServiceSubsection } from "features/configuration/configuration-types";
import LoginIcon from "app/components/icons/login-icon";
import { useTranslate } from "@customer_interactions/i18n";
import { Analytics } from "@customer_interactions/application-logger";
import React from "react";
import { useLoginModal } from "app/components/shared/login-modal/use-login-modal";
import { withLoginModal } from "app/components/shared/login-modal/withLoginModal";
import { trackFeatureNavigationEvent } from "features/analytics-events/track-feature-navigation-event";
import { isUrlThatRequireLogin } from "pages/home/constants";

interface TripCardSubSectionProps {
  sectionId: string;
  isAuthenticated: boolean;
  subsection: AssistanceServiceSubsection;
  tripStageCapitalized: string;
}

export const TripCardSubSection = ({
  sectionId,
  isAuthenticated,
  subsection,
  tripStageCapitalized
}: TripCardSubSectionProps) => {
  const t = useTranslate();
  const title = t(
    `[Home_Assistance_service]${subsectionIdToTagName[subsection.id]}_title`
  );
  const url = t(
    `[Home_Assistance_service]${
      subsectionIdToTagName[subsection.id]
    }_button_url`
  );
  const { shouldRequireLogin } = useLoginModal();
  const requiresLogin = shouldRequireLogin(url);

  const onClick = () => {
    Analytics.sendEvent(
      `[customer_space${
        isAuthenticated ? "" : "_pre"
      }_home]trip_${tripStageCapitalized}_${subsection.id}_button-click`,
      "Customer_Space"
    );

    trackFeatureNavigationEvent({
      zone: "support stages of your trip",
      elementClicked: subsection.id,
      serviceCategory: sectionId,
      isAuthenticated,
      requiresLogin: isUrlThatRequireLogin(url)
    });
  };

  const buttonType = requiresLogin ? "secondary" : "primary";
  const loginIcon = requiresLogin ? <LoginIcon /> : undefined;

  const DropdownItemWithLoginModal = withLoginModal(DropdownItem);

  return (
    <DropdownItemWithLoginModal
      key={subsection.id}
      data-testid={`trip-card-dropdown-item-${subsection.id}`}
      isExternalService={subsection.external}
      to={url}
      onClick={onClick}
      variant={buttonType}
    >
      {title}
      {loginIcon}
    </DropdownItemWithLoginModal>
  );
};
