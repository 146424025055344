import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { Header, P, SectionSubtitle } from "./sections-styles";
import ReactMarkdown from "react-markdown";

export const TermsOfServicesSection = () => {
  const t = useTranslate();
  const TERMS_OF_SERVICE_SECTIONS = 5;

  const renderSection = (section: number) => {
    const title = t(`[terms_of_service_site]section${section}_title`);
    const text = t(`[terms_of_service_site]section${section}_text`);

    if (!title || !text) {
      return null;
    }

    const components = { p: P };

    return (
      <div key={`[cookies_site]section${section}`}>
        <SectionSubtitle>{title}</SectionSubtitle>
        <ReactMarkdown components={components}>{text}</ReactMarkdown>
      </div>
    );
  };

  return (
    <>
      <Header>{t("[terms_of_service_site]title")}</Header>
      <ReactMarkdown components={{ p: P }}>
        {t("[terms_of_service_site]intro")}
      </ReactMarkdown>
      {[...Array(TERMS_OF_SERVICE_SECTIONS).keys()].map(index =>
        renderSection(index + 1)
      )}
    </>
  );
};
