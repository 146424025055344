import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";
import { updateMenuAction } from "features/hamburguer-menu/actions/menu-actions";
import { selectMenuIsOpened } from "features/hamburguer-menu/selectors/menu-selectors";
import { useDispatch, useSelector } from "react-redux";

export const useHamburguerMenuActions = () => {
  const isMenuOpened = useSelector(selectMenuIsOpened);
  const dispatch = useDispatch();

  const setMenuState = () => dispatch(updateMenuAction(!isMenuOpened));

  const handleMenuClick = (logEvent = true) => {
    logEvent && trackNavigationEvent("header", "menu");
    setMenuState();
  };

  const closeMenu = () => {
    isMenuOpened && dispatch(updateMenuAction(false));
  };

  return { handleMenuClick, closeMenu, isMenuOpened };
};
