import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const ContainerMobile = css`
  padding: 25px;
`;
export const Container = styled.div`
  padding: 20px 125px;
  display: flex;
  flex-direction: column;
  ${getCssForMobileView(ContainerMobile)}
`;

const BannerMobile = css`
  background: url("/assets/images/privacy-policy-mobile.png") no-repeat left top;
  background-size: contain;
  color: ${({ theme }) => theme.grey["800"]};
`;

const BannerTitleMobile = css`
  font-size: 36px;
`;

export const Banner = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  color: ${({ theme }) => theme.white};
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 29.53%,
      rgba(0, 0, 0, 0.25) 76.61%
    ),
    url("/assets/images/privacy-policy.png") no-repeat left top;
  background-size: cover;
  mix-blend-mode: darken;
  opacity: 0.95;
  ${getCssForMobileView(BannerMobile)}
`;

export const BannerTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: bold;
  font-size: 43px;
  line-height: 51px;
  ${getCssForMobileView(BannerTitleMobile)}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 90%;
  top: 100px;
  margin: auto;
  position: relative;
  padding: 15px;
`;
