import { Analytics } from "@customer_interactions/application-logger";
import { useTranslate } from "@customer_interactions/i18n";
import {
  Section,
  Tab
} from "app/components/shared/scrollable-section/scrollable-section";
import { selectPrivacyPolicySections } from "features/configuration/selectors/configuration-selectors";
import { useSelector } from "react-redux";

type sectionTab = {
  [key: string]: Pick<Tab, "to" | "name">;
};

export const usePrivacyPolicy = (allSections: Section[]) => {
  const t = useTranslate();
  const configSections = useSelector(selectPrivacyPolicySections);

  const trackAnalyticEvent = (sectioName: string) => () => {
    Analytics.sendEvent(
      `[privacy_policy][${sectioName}] section-click`,
      "Customer_Space"
    );
  };

  const mapSectionsTab: sectionTab = {
    ["legal"]: {
      to: "legal",
      name: t("[privacy_policy]section1")
    },
    ["terms_conditions"]: {
      to: "terms_conditions",
      name: t("[privacy_policy]section2")
    },
    ["terms_of_service"]: {
      to: "terms_of_service",
      name: t("[terms_of_service_site]title")
    }
  };

  const tabs: Tab[] = configSections.map((section: string, i: number) => ({
    ...mapSectionsTab[section as keyof sectionTab],
    onClick: trackAnalyticEvent(section),
    testId: `tab-${i}`
  }));

  const sectionsToRender = allSections.filter(section =>
    configSections.includes(section.id)
  );

  return { tabs, sectionsToRender, t };
};
