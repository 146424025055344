import React from "react";
import { Analytics } from "@customer_interactions/application-logger";
import LoginIcon from "app/components/icons/login-icon";
import { LoginButtonStyled } from "./login-button-styles";
import { CTAButtonProps } from "../cta-button/cta-button";
import { trackStartAuthenticationJourneyFromZone } from "features/analytics-events/track-start-authentication-journey-event";
import { useLoginButton } from "./use-login-button";

export interface LoginButtonProps
  extends Omit<CTAButtonProps, "translationKey" | "name" | "to"> {}

export const LoginButton = ({ ...props }: LoginButtonProps) => {
  const { handleLoginClick } = useLoginButton();
  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    Analytics.sendEvent(
      `[customer_space_pre_home] login_button-click`,
      "Customer_Space"
    );
    trackStartAuthenticationJourneyFromZone("header");
    handleLoginClick();
  };

  return (
    <LoginButtonStyled
      {...props}
      translationKey="[Home_header]Login_button"
      onClick={handleOnClick}
      to="/"
      name="login-button"
    >
      <LoginIcon inverted />
    </LoginButtonStyled>
  );
};
