import React from "react";
import { Analytics } from "@customer_interactions/application-logger";
import { useTranslate } from "@customer_interactions/i18n";
import QuestionMarkIcon from "../icons/question-mark-icon";
import { FaqsButtonContainer, FaqsButtonText } from "./faqs-button.styles";
import { useHamburguerMenuActions } from "hooks/use-hamburguer-menu";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";

export const FaqsButton = () => {
  const t = useTranslate();
  const { closeMenu } = useHamburguerMenuActions();

  const onClickFaqsLink = () => {
    Analytics.sendEvent("[header] faqs", "Customer_Space");
    trackNavigationEvent("header", "faq");
    closeMenu();
  };
  return (
    <FaqsButtonContainer
      data-testid="faqs-question-mark-icon"
      to={t("[Landing_footer]FAQs_url")}
      onClick={onClickFaqsLink}
    >
      <QuestionMarkIcon />
      <FaqsButtonText>{t("[Landing_footer]FAQs")}</FaqsButtonText>
    </FaqsButtonContainer>
  );
};
