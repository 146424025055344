import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const MedicalWebComponentContainerMobile = css`
  margin-top: 100px;
`;

export const MedicalWebComponentContainer = styled.div`
  height: 100%;
  flex: 1;
  ${getCssForMobileView(MedicalWebComponentContainerMobile)}
`;
