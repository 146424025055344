import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import {
  TipsSectionHeader,
  TipTitle,
  TipDescription,
  Tip,
  CheckIconStyled
} from "./travel-tips-styles";
import CheckIcon from "app/components/icons/check-icon";
import { Analytics } from "@customer_interactions/application-logger";
import {
  ScrollableSection,
  Section,
  Tab
} from "app/components/shared/scrollable-section/scrollable-section";
import { TipsByStages } from "constant";
import TravelTipsDownloadContainer from "./travel-tips-download-container";
import DownloadPdfButton from "./travel-tips-download-button";
import PageHeader from "app/components/shared/page-header/page-header";

const TravelTips = () => {
  const t = useTranslate();

  const trackAnalyticEvent = (stage: string) => () => {
    Analytics.sendEvent(
      `[travel-tips][${stage}] section-click`,
      "Customer_Space"
    );
  };

  const tabs: Tab[] = TipsByStages.map((tipsByStage, _index) => ({
    to: tipsByStage.stage,
    name: t(`[travel_tips][main]${tipsByStage.stage}`),
    onClick: trackAnalyticEvent(tipsByStage.stage),
    testId: `tips-tab-${tipsByStage.stage}`
  }));

  const sections: Section[] = TipsByStages.map((tipsByStage, tipsIndex) => ({
    id: tipsByStage.stage,
    content: (
      <>
        <TipsSectionHeader data-testid={`tips-header-${tipsIndex}`}>
          {t(`[travel_tips][${tipsByStage.stage}]title`)}
        </TipsSectionHeader>
        {Array(tipsByStage.items)
          .fill("")
          .map((_value, itemIndex) => {
            const title = t(`${tipsByStage.prefix}TT${itemIndex + 1}`, true);
            if (!title) {
              return null;
            }
            return (
              <Tip key={itemIndex}>
                <CheckIconStyled>
                  <CheckIcon />
                </CheckIconStyled>
                <div>
                  <TipTitle>{title}</TipTitle>
                  <TipDescription>
                    {t(
                      `${tipsByStage.prefix}TT${itemIndex + 1}-minitext`,
                      true
                    )}
                  </TipDescription>
                </div>
              </Tip>
            );
          })}
      </>
    )
  }));

  return (
    <div>
      <PageHeader
        URLDesktop="/assets/images/travel-tips.png"
        URLMobile="/assets/images/travel-tips-mobile.png"
        title={t("[travel_tips]title")}
        subtitle={t("[travel_tips]sub_title")}
        CtaButton={DownloadPdfButton}
      />
      <ScrollableSection tabs={tabs} sections={sections} />
      <TravelTipsDownloadContainer />
    </div>
  );
};

export default TravelTips;
