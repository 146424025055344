import React, { FC, forwardRef } from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { CTAButton as StyledCTAButton } from "./cta-button-styles";
import { LinkResolverProps } from "app/components/link-resolver/link-resolver";

export interface CTAButtonProps extends LinkResolverProps {
  translationKey: string;
  name: string;
  to: string;
  isExternal?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

const CTAButton: FC<CTAButtonProps> = forwardRef<any, CTAButtonProps>(
  (props, ref) => {
    const {
      translationKey,
      name,
      to,
      isExternal,
      variant = "primary",
      onClick,
      children,
      ...rest
    } = props;
    const t = useTranslate();
    const buttonText = t(translationKey);
    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <StyledCTAButton
        {...rest}
        onClick={handleOnClick}
        tabIndex={0}
        data-testid={name}
        to={to}
        isExternalService={isExternal}
        variant={variant}
        innerRef={ref}
      >
        {children}
        {buttonText}
      </StyledCTAButton>
    );
  }
);

export default CTAButton;
