import React from "react";
import {
  TimelineContainer,
  TimelineList,
  TimelineItem,
  TimelineItemTitle,
  TimelineItemDescription
} from "./timeline-styles";

type TimelineItemProps = {
  title: string;
  text: string;
};

type TimelineProps = {
  items: TimelineItemProps[];
};

export const Timeline = ({ items }: TimelineProps) => {
  return (
    <TimelineContainer>
      <TimelineList>
        {items.map((item: TimelineItemProps, index: number) => (
          <TimelineItem key={index} data-testid={`timeline-item-${index}`}>
            <TimelineItemTitle>{item.title}</TimelineItemTitle>
            <TimelineItemDescription>{item.text}</TimelineItemDescription>
          </TimelineItem>
        ))}
      </TimelineList>
    </TimelineContainer>
  );
};
