import React, { FC, ReactElement, useRef, useEffect, useState } from "react";
import { callbackOnKeyboardEvent } from "utils/user-events-utils";
import { Container, Nav, TabOption } from "./scrollable-section-styles";
import { useStickyHeaderHeight } from "hooks/use-sticky-header-height";

export interface Tab {
  to: string;
  name: string;
  onClick: () => void;
  testId: string;
}

export interface Section {
  id: string;
  content: ReactElement;
}

interface ScrollableSectionProps {
  tabs: Tab[];
  sections: Section[];
  threshold?: number | number[];
}

const MIN_THRESHOLD = 0.1;

export const ScrollableSection: FC<ScrollableSectionProps> = ({
  tabs,
  sections,
  threshold = MIN_THRESHOLD
}) => {
  const navRef = useRef<HTMLObjectElement>(null);
  const tabRefs = useRef<HTMLAnchorElement[]>([]);
  const sectionRefs = useRef<HTMLObjectElement[]>([]);
  const [currentSections, setCurrentSections] = useState<string[]>([]);
  const [isNavOnTop, setIsNavOnTop] = useState<boolean>();
  const getStickyHeaderHeight = useStickyHeaderHeight();
  const currentSection = currentSections.slice(-1).pop();
  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current && navRef.current.getBoundingClientRect().top <= 0) {
        setIsNavOnTop(true);
      } else {
        setIsNavOnTop(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (currentSection) {
      const tab = tabRefs.current.find(
        tabRef => tabRef.getAttribute("data-to") === currentSection
      );
      if (tab && navRef.current && navRef.current.scrollTo) {
        navRef.current.scrollTo({ left: tab.offsetLeft, behavior: "smooth" });
      }
    }
  }, [currentSection]);

  useEffect(() => {
    const callbackInterception: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCurrentSections(prev => {
            const nextCurrentSections = [...prev];
            nextCurrentSections.push(entry.target.id);
            return nextCurrentSections;
          });
        } else {
          if (entry.boundingClientRect.top > 0) {
            setCurrentSections(prev => {
              return prev.filter(section => section !== entry.target.id);
            });
          }
        }
      });
    };

    const observer = new IntersectionObserver(callbackInterception, {
      threshold
    });

    if (sectionRefs.current.length) {
      sectionRefs.current.forEach(section => observer.observe(section));
    }

    return () => {
      observer.disconnect();
    };
  }, [tabs, sections]);

  const handleTabOnClick = (tab: Tab) => () => {
    const target = sectionRefs.current.find(section => {
      return section.id === tab.to;
    });
    if (target) {
      tab.onClick();
      const y =
        target.getBoundingClientRect().top +
        window.scrollY -
        getStickyHeaderHeight();

      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
    }
  };

  return (
    <div>
      <Nav ref={navRef}>
        {tabs.map((tab: Tab, i) => (
          <TabOption
            onKeyDown={e =>
              callbackOnKeyboardEvent(e, "Enter", handleTabOnClick(tab))
            }
            tabIndex={0}
            data-to={tab.to}
            key={tab.testId}
            data-testid={tab.testId}
            className={isNavOnTop && currentSection === tab.to ? "active" : ""}
            onClick={handleTabOnClick(tab)}
            ref={(element: HTMLAnchorElement) => (tabRefs.current[i] = element)}
          >
            {tab.name}
          </TabOption>
        ))}
      </Nav>
      <Container>
        {sections.map((section: Section, i: number) => (
          <section key={section.id}>
            <div
              id={section.id}
              data-testid={`section-${section.id}`}
              ref={(element: HTMLObjectElement) =>
                (sectionRefs.current[i] = element)
              }
            ></div>
            {section.content}
          </section>
        ))}
      </Container>
    </div>
  );
};
