import uaParser from "ua-parser-js";

export const WINDOWS_OS_NAME = "Windows";
export const MAC_OS_NAME = "Mac OS";
export const ANDROID_OS_NAME = "Android";
export const IOS_OS_NAME = "iOS";

export const CHROME_BROWSER_NAME = "Chrome";
export const FIREFOX_BROWSER_NAME = "Firefox";
export const SAFARI_BROWSER_NAME = "Safari";
export const MOBILE_SAFARI_BROWSER_NAME = "Mobile Safari";
export const EDGE_BROWSER_NAME = "Edge";

const userAgentDetails = uaParser(window.navigator.userAgent);

export const getOsName = () => userAgentDetails.os.name;
export const getBrowserName = () => userAgentDetails.browser.name;

export const isAndroidOS = () => getOsName() === ANDROID_OS_NAME;
export const isIOS = () => getOsName() === IOS_OS_NAME;

export const getOsBrowserCombination = () =>
  `${getOsName()}-${getBrowserName()}`;

export const supportsAddToHome: () => boolean = () => {
  const osName = getOsName();
  const browserName = getBrowserName();

  switch (osName) {
    case WINDOWS_OS_NAME:
    case MAC_OS_NAME:
      return (
        browserName === CHROME_BROWSER_NAME || browserName === EDGE_BROWSER_NAME
      );
    case ANDROID_OS_NAME:
      return (
        browserName === CHROME_BROWSER_NAME ||
        browserName === FIREFOX_BROWSER_NAME
      );
    case IOS_OS_NAME:
      return (
        browserName === MOBILE_SAFARI_BROWSER_NAME ||
        browserName === CHROME_BROWSER_NAME
      );
    default:
      return false;
  }
};
