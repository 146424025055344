import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { LinkResolver } from "app/components/link-resolver/link-resolver";

const mainContainerMobile = css`
  flex-direction: column;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: stretch;
  color ${({ theme }) => theme.white};
  ${getCssForMobileView(mainContainerMobile)};
`;

export const CardSection = styled.div`
  flex: 1;
  min-width: 0;
`;

export const DigitalCardContainer = styled.div`
  background: ${({ theme }) => theme.teal["300"]};
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 57px 0 0;
`;

const digitalCardTitleMobile = css`
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  padding: 0 24px;
`;

export const DigitalCardTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 43px;
  line-height: 51px;
  padding: 0 78px;
  ${getCssForMobileView(digitalCardTitleMobile)}
`;

const stepsContainerMobile = css`
  padding: 57px 24px;
`;

export const StepsContainer = styled.div`
  background: ${({ theme }) => theme.teal["200"]};
  padding: 57px 74px;
  height: 100%;
  ${getCssForMobileView(stepsContainerMobile)}
`;

const StepsContainerHeaderMobile = css`
  padding-bottom: 36px;
`;

export const StepsContainerHeader = styled.div`
  padding-bottom: 60px;
  ${getCssForMobileView(StepsContainerHeaderMobile)}
`;

export const StepTitle = styled.h3`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
`;

const stepSubtitleMobile = css`
  font-size: 16px;
  line-height: 21px;
`;

export const StepSubtitle = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  ${getCssForMobileView(stepSubtitleMobile)}
`;

const digitalCardMobile = css`
  width: 172px;
  height: unset;
`;

export const DigitalCardImage = styled.img`
  padding-top: 71px;
  height: 512px;
  ${getCssForMobileView(digitalCardMobile)}
`;

export const DigitalCardQrCode = styled.img`
  height: 103px;
  width: 103px;
`;

export const DownloadContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 28px;
`;

export const DownloadText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

export const DownloadLink = styled(LinkResolver)`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.white};
`;

export const DownloadButton = styled(LinkResolver)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
  height: 48px;
  background: ${({ theme }) => theme.white};
  box-shadow: inset 0px -3px 0px #f0f0f0;
  color: ${({ theme }) => theme.grey["800"]};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
`;
