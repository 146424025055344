import { State } from "app/store/types";

export const selectGlobalParameters = (state: State) =>
  state.globalPartnerParameters || {};

export const selectGlobalCountry = (state: State) =>
  selectGlobalParameters(state).country;

export const selectGlobalPartnerId = (state: State) =>
  selectGlobalParameters(state).partnerId;
