import React from "react";
import { FooterContainer, ModalButton } from "./modal-footer-styles";

interface Props {
  installButtonText: string;
  closeModalText: string;
  onClose: () => void;
  onInstall?: () => void;
}

export const ModalFooter: React.FC<Props> = ({
  installButtonText,
  closeModalText,
  onClose,
  onInstall
}) => {
  return (
    <FooterContainer>
      <ModalButton onClick={onClose} variant="outlined">
        {closeModalText}
      </ModalButton>
      {onInstall && (
        <ModalButton
          onClick={onInstall}
          variant="primary"
          data-testid="install-modal-add-button"
        >
          {installButtonText}
        </ModalButton>
      )}
    </FooterContainer>
  );
};
