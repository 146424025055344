import { ActionStandard } from "app/store/types";
import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { GlobalPartnerParameters } from "../global-partner-parameters-types";
import { GLOBAL_PARTNER_PARAMETERS_FEATURE } from "../actions/global-partner-paramenters-actions";
import { globalPartnerParametersInitialState } from "../global-partner-parameters-initial-state";

const globalPartnerParametersStateHandlers: Mapping<Function> = {
  [GLOBAL_PARTNER_PARAMETERS_FEATURE.SUCCESS]: (
    state: GlobalPartnerParameters,
    action: ActionStandard<Partial<GlobalPartnerParameters>>
  ) => ({
    ...state,
    ...action.payload
  })
};

export const globalPartnerParametersReducer = (
  state: GlobalPartnerParameters = globalPartnerParametersInitialState,
  action: ActionStandard<GlobalPartnerParameters>
) => {
  return handle(state, action, globalPartnerParametersStateHandlers);
};
