import React from "react";

import { ModalContentIOS } from "./modal-content-IOS";
import { getOsBrowserCombination } from "utils/user-agent";
import { ModalContentAndroid } from "./modal-content-android";

interface ModalContentProps {
  isInstallable: boolean;
}

export const ModalContent = ({ isInstallable }: ModalContentProps) => {
  if (isInstallable) {
    return <ModalContentAndroid />;
  }

  const osBrowserCombination = getOsBrowserCombination();

  switch (osBrowserCombination) {
    case "iOS-Mobile Safari":
    case "iOS-Chrome":
      return <ModalContentIOS />;
    case "Android-Chrome":
    case "Android-Firefox":
      return <ModalContentAndroid />;
    default:
      return null;
  }
};
