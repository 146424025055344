import {
  getPageViewEventData,
  PageEventProps
} from "./get-page-view-event-data";
import { Analytics } from "@customer_interactions/application-logger";

export const trackPageViewEvent = ({
  route,
  isLoggedIn,
  state,
  pageName,
  serviceCategory,
  isRoutingPage = false,
  countryGuide,
  chapter
}: PageEventProps) => {
  const pageViewEventData = getPageViewEventData({
    isLoggedIn,
    state,
    isRoutingPage,
    route,
    pageName,
    serviceCategory,
    countryGuide,
    chapter
  });

  if (pageViewEventData) {
    Analytics.sendEventData(pageViewEventData);
  }
};
