import React from "react";
import { useMedicalProviderSearch } from "./use-medical-provider-search";

export const MedProvSearchPage = () => {
  const {
    config,
    currentCustomLanguage,
    theme,
    wcScriptLoaded
  } = useMedicalProviderSearch();

  return wcScriptLoaded ? (
    <medical-search
      theme={JSON.stringify(theme)}
      language={currentCustomLanguage}
      host_tracking_id={process.env.REACT_APP_GLOBAL_GA_TRACKING_ID}
      partner_tracking_id={config.partnerTrackingId}
      bing_maps_api_key={config.bingMapsApiKey}
      medical_search_client={
        process.env.REACT_APP_MEDICAL_PROVIDERS_SEARCH_CLIENT
      }
    ></medical-search>
  ) : null;
};
