import { useTheme } from "styled-components";
import { Theme } from "styles";

export const useWCTheme = () => {
  const theme = useTheme() as Theme;

  return {
    ...theme,
    primaryColor: theme.blue["400"],
    secondaryColor: theme.teal["200"]
  };
};
