import {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
  Interpolation
} from "styled-components";
import defaultTheme from "./default-theme";

type Theme = typeof defaultTheme;

type ThemeInterpolation =
  | FlattenSimpleInterpolation
  | Interpolation<ThemeProps<Theme>>;

export const BREAKPOINTS = {
  mobileBreakPoint: 680,
  mediumBreakPoint: 850,
  expandedBreakPoint: 1024,
  largeBreakPoint: 1280
};

export const getMobileBreakpoint = () => `${BREAKPOINTS.mobileBreakPoint}px`;
const getMediumBreakpoint = () => `${BREAKPOINTS.mediumBreakPoint}px`;
export const getExpandedBreakpoint = () =>
  `${BREAKPOINTS.expandedBreakPoint}px`;
const getLargeBreakpoint = () => `${BREAKPOINTS.largeBreakPoint}px`;

export const getCssForMobileView = <T>(
  cssForMobileView: T & ThemeInterpolation
) => css`
  @media screen and (max-width: ${getMobileBreakpoint()}) {
    ${cssForMobileView}
  }
`;

export const getCssForTabletView = (
  cssForTabletView: ThemeInterpolation
) => css`
  @media (min-width: ${getMobileBreakpoint()}) and (max-width: ${getMediumBreakpoint()}) {
    ${cssForTabletView}
  }
`;

export const getCssForExpandedView = (
  cssForExpandedView: ThemeInterpolation
) => css`
  @media (min-width: ${getMediumBreakpoint()}) and (max-width: ${getExpandedBreakpoint()}) {
    ${cssForExpandedView}
  }
`;

export const getCssForLargeView = (cssForLargeView: ThemeInterpolation) => css`
  @media (min-width: ${getExpandedBreakpoint()}) and (max-width: ${getLargeBreakpoint()}) {
    ${cssForLargeView}
  }
`;

export const getCssForExtraLargeView = (
  cssForExtraLargeView: ThemeInterpolation
) => css`
  @media screen and (min-width: ${getLargeBreakpoint()}) {
    ${cssForExtraLargeView}
  }
`;
