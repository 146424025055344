import { Action } from "redux";

import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { SET_ASSISTANCE_SERVICE_SELECTED } from "./assistance-section-action-types";
import { ActionStandard } from "app/store/types";
import { AssistanceService } from "features/configuration/configuration-types";

const assistanceServiceSelectedHandler: Mapping<Function> = {
  [SET_ASSISTANCE_SERVICE_SELECTED]: (
    state: AssistanceService,
    action: ActionStandard<AssistanceService>
  ) => action.payload ?? initialState
};

const initialState = null;

export const setAssistanceServiceSelectedReducer = (
  state: AssistanceService | null = initialState,
  action: Action
) => {
  return handle(state, action, assistanceServiceSelectedHandler);
};
