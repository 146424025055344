import Loading from "app/components/loading-screen/loading";
import { SherpaAllowedLanguages } from "constant";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import { useMutationObserver } from "hooks/use-mutation-observer";
import { useScriptLoader } from "hooks/use-script-loader";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import defaultTheme from "styles/default-theme";
import { getSherpaConfig } from "utils/sherpa-utils";
import { SherpaContainer } from "./sherpa-widget-styles";

const SherpaWidget = () => {
  const { scriptURL, appID } = getSherpaConfig();
  const selectedLanguage = useSelector(selectCurrentLanguageIsoCode);
  const [isIframeLoaded, setIsIframeLoaded] = useState<boolean>(false);
  const { isLoaded } = useScriptLoader(`${scriptURL}?appId=${appID}`, "sherpa");
  const widgetRef = useRef<HTMLObjectElement>(null);
  const isLanguageAllowed =
    SherpaAllowedLanguages[
      selectedLanguage as keyof typeof SherpaAllowedLanguages
    ];
  const sherpaConfig = {
    language: !!isLanguageAllowed
      ? selectedLanguage
      : SherpaAllowedLanguages.en,
    features: {
      showTitle: false
    }
  };

  const onWidgetAdded = useCallback(
    (mutationList: MutationRecord[]) => {
      const addedChildren = mutationList[0].target.firstChild;
      setIsIframeLoaded(!addedChildren);
    },
    [setIsIframeLoaded]
  );

  useMutationObserver(widgetRef.current, onWidgetAdded);

  useEffect(() => {
    if (isLoaded) {
      setIsIframeLoaded(false);
      window.$sherpa.V2.createElement("trip", sherpaConfig).mount(
        "#sherpa-widget"
      );
    }
  }, [isLoaded, selectedLanguage]);

  return (
    <>
      <SherpaContainer ref={widgetRef} id="sherpa-widget" />
      {!isIframeLoaded && (
        <Loading
          titleColor={defaultTheme.grey["600"]}
          titleKey="[Country_Information]Loading_message"
        />
      )}
    </>
  );
};

export default SherpaWidget;
