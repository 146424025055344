import { ActionStandard } from "app/store/types";
import { ToasterState } from "./toaster-types";
export const SET_TOASTER: string = "toaster/SET_TOASTER";

export const setToaster = (
  payload: ToasterState
): ActionStandard<ToasterState> => ({
  type: SET_TOASTER,
  payload
});
