import { ActionStandard } from "app/store/types";
import { AssistanceService } from "features/configuration/configuration-types";
import { SET_ASSISTANCE_SERVICE_SELECTED } from "./assistance-section-action-types";

export const setAssistanceServiceSelected = (
  payload: AssistanceService | null
): ActionStandard<AssistanceService | null> => ({
  type: SET_ASSISTANCE_SERVICE_SELECTED,
  payload
});
