import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export type ButtonVariant = "primary" | "outlined";

export interface ButtonProps {
  variant?: ButtonVariant;
}

export const ButtonMobileStyles = css`
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  width: 100%;
  align-self: auto;
`;

export const ButtonStyles = css`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  min-width: 67px;
  max-width: 370px;
  justify-content: center;
  text-transform: uppercase;
  min-height: 48px;
  cursor: pointer;
  padding: 16px 24px;
  text-align: center;
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  line-height: 18px;
`;

export const variantStyles = (
  theme: any,
  variant: ButtonVariant = "primary"
) => {
  const variants = {
    primary: css`
      background-color: ${theme.blue["300"]};
      border: 2px solid ${theme.blue["300"]};
      color: ${theme.white};
    `,
    outlined: css`
      background-color: inherit;
      border: solid 1px;
      border-color: ${theme.blue["300"]};
      color: ${theme.blue["300"]};
      &:disabled {
        border: unset;
        border-bottom: 2px solid #ccc;
      }
    `
  };

  return variants[variant];
};

export const Button = styled.button<ButtonProps>`
  ${ButtonStyles}
  ${({ theme, variant }) => variantStyles(theme, variant)}
  ${getCssForMobileView(ButtonMobileStyles)}
`;
