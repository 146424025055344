import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
`;

const CardContentMobile = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 90%;
  top: 33px;
  margin: auto;
  position: relative;
  padding: 15px 15px;
`;

export const CardContent = styled.div`
  width: 35%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${getCssForMobileView(CardContentMobile)}
`;

const MainTitleMobile = css`
  font-size: 38px;
  line-height: 45px;
`;

export const MainTitle = styled.h1`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 50px;
  line-height: 60px;
  font-weight: bold;
  ${getCssForMobileView(MainTitleMobile)}
`;

const MainTextMobile = css`
  font-size: 18px;
  line-height: 24px;
`;

export const MainText = styled.p`
  font-size: 20px;
  line-height: 29px;
  ${getCssForMobileView(MainTextMobile)}
`;
