import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const MARGIN_X = 129;

const countryInformationContainerMobile = css`
  margin-top: 65px;
`;

export const CountryInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${getCssForMobileView(countryInformationContainerMobile)}
`;

export const CountryInformationTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  margin: 48px auto 16px auto;
  font-size: 43px;
  line-height: 51px;
  ${getCssForMobileView(css`
    font-size: 24px;
    line-height: 32px;
    margin: 64px auto 16px auto;
  `)}
`;

export const CountryInformationContent = styled.div`
  font-size: 1.25rem;
  margin: 0 ${MARGIN_X}px 16px;
  ${getCssForMobileView(css`
    font-size: 1rem;
    margin: 0 16px;
  `)}
`;

const countryInformationDisclaimerContainerMobile = css`
  margin: 15px;
`;

export const CountryInformationDisclaimerContainer = styled.div`
  margin: 0 ${MARGIN_X}px 48px;
  ${getCssForMobileView(countryInformationDisclaimerContainerMobile)}
`;

export const DisclaimerTitle = styled.span`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: inline;
`;

export const DisclaimerText = styled.span`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  display: inline;
`;
