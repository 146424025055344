import React from "react";
import { Helmet } from "react-helmet";
import { useTranslate } from "@customer_interactions/i18n";
import { useSelector } from "react-redux";
import { selectFavicon } from "features/configuration/selectors/configuration-selectors";

export const HeadTags = () => {
  const t = useTranslate();
  const favicon = useSelector(selectFavicon);
  return (
    <Helmet>
      <title>{t("[HTML]Title")}​​​​</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={t("[HTML]Description")} />
    </Helmet>
  );
};
