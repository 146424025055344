import { useAuth0 } from "@auth0/auth0-react";
import { Analytics } from "@customer_interactions/application-logger";
import { routes } from "constant";
import { getPageViewEventData } from "features/analytics-events/get-page-view-event-data";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { selectCustomLanguage } from "features/language/selectors/language-selector";
import { useScriptLoader } from "hooks/use-script-loader";
import { useWCTheme } from "hooks/use-wc-theme";
import { useCallback, useEffect } from "react";
import { useSelector, useStore } from "react-redux";

const MEDICAL_SEARCH_RESULTS_LOADED_PAGE_NAME = "result health professional";

export const useMedicalProviderSearch = () => {
  const theme = useWCTheme();
  const config = useSelector(selectConfiguration);
  const store = useStore();
  const { isAuthenticated } = useAuth0();

  const currentCustomLanguage = useSelector(
    selectCustomLanguage({ includeCountryCode: true })
  );

  const handleWCLoaded = useCallback((e: Event) => {
    e.stopImmediatePropagation();
    const pageViewEventData = getPageViewEventData({
      route: routes.MEDICAL_SEARCH,
      isLoggedIn: isAuthenticated,
      state: store.getState()
    });

    if (pageViewEventData) {
      Analytics.sendEventData(pageViewEventData);
    }
  }, []);

  const handleSearchResultsLoaded = useCallback((e: Event) => {
    e.stopImmediatePropagation();
    const pageViewEventData = getPageViewEventData({
      pageName: MEDICAL_SEARCH_RESULTS_LOADED_PAGE_NAME,
      isLoggedIn: isAuthenticated,
      state: store.getState()
    });

    if (pageViewEventData) {
      Analytics.sendEventData(pageViewEventData);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("medical-search-loaded", handleWCLoaded);
    document.addEventListener(
      "medical-search-results-loaded",
      handleSearchResultsLoaded
    );

    return () => {
      document.removeEventListener("medical-search-loaded", handleWCLoaded);
      document.removeEventListener(
        "medical-search-results-loaded",
        handleSearchResultsLoaded
      );

      const mapScript = document.getElementById("bingmap-script");
      mapScript && document.body.removeChild(mapScript);
    };
  }, []);

  const { isLoaded } = useScriptLoader(
    process.env.REACT_APP_MEDICAL_PROVIDERS_SEARCH_WC_URL!,
    "medical-providers-search"
  );

  return {
    wcScriptLoaded: isLoaded,
    theme,
    config,
    currentCustomLanguage
  };
};
