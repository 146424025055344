import React from "react";
import ArrowDownIcon from "app/components/icons/arrow-down-icon";
import ArrowUpIcon from "app/components/icons/arrow-up-icon";
import { ButtonContainer, Button } from "./show-more-button-styles";

interface ShowMoreButtonProps {
  shouldShowMore: boolean;
  showLessText: string;
  showMoreText: string;
  onClick(e?: React.MouseEvent<HTMLDivElement>): void;
}

export const ShowMoreButton = ({
  shouldShowMore,
  showLessText,
  showMoreText,
  onClick
}: ShowMoreButtonProps) => {
  return (
    <ButtonContainer onClick={onClick} data-testid="show-more-btn">
      <Button data-testid="show-more-text">
        {shouldShowMore ? showLessText : showMoreText}
      </Button>
      {shouldShowMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </ButtonContainer>
  );
};
