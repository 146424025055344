import { useTranslate } from "@customer_interactions/i18n";
import React, { FC } from "react";
import {
  GenericPage,
  Title,
  Text
} from "app/components/shared/generic-page/generic-page-styles";
import { Spinner } from "./spinner";

interface LoadingProps {
  titleKey?: string;
  subtitleKey?: string;
  titleColor?: string;
}

const defaultTitleKey = "[Loading_Page]Title";

const Loading: FC<LoadingProps> = ({
  titleKey = defaultTitleKey,
  subtitleKey,
  titleColor
}) => {
  const t = useTranslate();
  return (
    <GenericPage>
      <Title colorText={titleColor}>{t(titleKey, true)}</Title>
      {subtitleKey && <Text>{t(subtitleKey, true)}</Text>}
      <Spinner />
    </GenericPage>
  );
};

export default Loading;
