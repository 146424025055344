import React from "react";
import { IconProps } from "./icon-types";
import { withTheme } from "styled-components";

const SpinnerIcon = ({ theme, ...otherProps }: IconProps) => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "block" }}
    {...otherProps}
  >
    <title>Loading spinner</title>
    <path
      d="M2 26C2 39.2548 12.7452 50 26 50C39.2548 50 50 39.2548 50 26C50 12.7452 39.2548 2 26 2"
      stroke={theme.blue["400"]}
      strokeWidth="4px"
    />
  </svg>
);

export default withTheme(SpinnerIcon);
