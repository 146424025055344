import React from "react";

import { ModalContent } from "./modal-content";
import { useInstallationModal } from "./use-installation-modal";
import Modal from "app/components/shared/modal/modal";
import { ModalFooter } from "./modal-footer";

export const InstallationModal = () => {
  const {
    showModal,
    onClose,
    handleInstall,
    isInstallable,
    closeModalButtonText,
    installButtonText,
    modalTitleText
  } = useInstallationModal();

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      title={modalTitleText}
      closeOnOutsideClick={false}
      buttonSection={
        <ModalFooter
          closeModalText={closeModalButtonText}
          installButtonText={installButtonText}
          onInstall={isInstallable ? handleInstall : undefined}
          onClose={onClose}
        />
      }
    >
      <ModalContent isInstallable={isInstallable} />
    </Modal>
  );
};
