import { useTranslate } from "@customer_interactions/i18n";
import SherpaWidget from "pages/sherpa-widget/sherpa-widget";
import React from "react";
import {
  CountryInformationContainer,
  CountryInformationContent,
  CountryInformationDisclaimerContainer,
  CountryInformationTitle,
  DisclaimerText,
  DisclaimerTitle
} from "./country-information-styles";
import { MarkdownContent } from "pages/privacy-policy/sections/sections-styles";

const CountryInformation = () => {
  const t = useTranslate();

  return (
    <CountryInformationContainer data-testid="country-information">
      <CountryInformationTitle data-testid="country-information-title">
        {t("[Country_Information]Title")}
      </CountryInformationTitle>
      <CountryInformationContent>
        <MarkdownContent>
          {t("[Country_Information]Information_text")}
        </MarkdownContent>
      </CountryInformationContent>
      <SherpaWidget />
      <CountryInformationDisclaimerContainer data-testid="country-disclaimer-container">
        <DisclaimerTitle data-testid="country-disclaimer-title">
          {t("[Country_Information]Disclaimer_bold")}
        </DisclaimerTitle>
        <DisclaimerText data-testid="country-disclaimer-text">
          {t("[Country_Information]Disclaimer_text")}
        </DisclaimerText>
      </CountryInformationDisclaimerContainer>
    </CountryInformationContainer>
  );
};

export default CountryInformation;
