import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { rotate } from "app/components/keyframes";

const SpinnerContainerMobile = css`
  margin-top: 4rem;
`;

export const SpinnerContainer = styled.div`
  margin: 3.75rem auto 3rem;
  height: 3.25rem;
  width: 3.25rem;
  ${getCssForMobileView(SpinnerContainerMobile)};
`;

export const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`;
