import styled, {
  createGlobalStyle,
  GlobalStyleComponent,
  css
} from "styled-components";

import { Theme } from "./types";
import defaultTheme from "./default-theme";
import { getCssForMobileView } from "./responsive";
import { ConfigModel } from "../features/configuration/configuration-types";

const getGlobalStyles = (theme?: Theme) => css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
  }
  html,
  body {
    background: ${theme?.white};
    height: 100%;
    margin: 0;
    font-size: 16px;
    font-family: '${theme?.secondaryFontFamily}';
    font-weight: normal;
    color: ${theme?.grey["800"]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    height: 100%;
  }

  /*TODO: Remove this temp fix when we style the one trust cookies.*/
  div#ot-sdk-btn-floating {
    display: none !important;
  }

  button {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
`;
export const GlobalStyles: GlobalStyleComponent<
  { theme?: Theme },
  {}
> = createGlobalStyle`

${({ theme }: { theme?: Theme }) => getGlobalStyles(theme)}`;

export const getTheme = ({ theme }: ConfigModel): Theme => ({
  ...defaultTheme,
  ...theme
});

const MainAppContainerMobileStyles = css`
  width: 100%;
  margin: 0;
`;

export const MainAppContainer = styled.div`
  margin-top: 2em;
  ${getCssForMobileView(MainAppContainerMobileStyles)}
`;

const ParagraphContainerMobileStyles = css`
  margin: 0;
`;
export const ParagraphContainer = styled.div`
  margin-bottom: 40px ${getCssForMobileView(ParagraphContainerMobileStyles)};
`;

const MainImageContainerMobileStyles = css`
  height: 190px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 70px;
  background-position: center;
`;

interface MainImage {
  imageURL: string;
}

export const MainImageContainer = styled.section<MainImage>`
  width: 100%;
  height: 85vh;
  display: flex;
  background-image: url(${props => props.imageURL});
  background-repeat: no-repeat;
  background-origin: center;
  background-size: cover;
  background-position: 100% 30%;
  ${getCssForMobileView(MainImageContainerMobileStyles)};
`;

const ContainerMobileStyles = css`
  flex-wrap: wrap;
  margin: 2em 1.5em;
`;

export const SectionRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${getCssForMobileView(ContainerMobileStyles)};
`;

export const SectionColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 5em;
  ${getCssForMobileView(ContainerMobileStyles)};
`;

const SectionTitleMobile = css`
  font-size: 38px;
  line-height: 45px;
  margin: 5% auto;
`;

export const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 43px;
  line-height: 51px;
  text-align: center;
  margin: 2% auto;
  ${getCssForMobileView(SectionTitleMobile)};
`;

export const ExternalButtonLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;
