import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";
import { routes } from "constant";

const mapRoutesToChatZones = {
  [routes.HOME]: "homepage",
  [routes.ROOT]: "homepage",
  [routes.HELP_CENTER]: "help_center"
};

export const getChatZoneByRoute = (route: string) =>
  mapRoutesToChatZones[route] || route;

export const trackStartChat = (elementClicked: string, zone?: string) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.START_CHAT,
    element_clicked: elementClicked,
    ...(zone && { zone })
  });
};
