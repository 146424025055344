import React from "react";
import { ActionCard, ActionCardText } from "./card-styles";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";
import { mapElementClickedName } from "utils/analytics-mapper-utils";

type CardProps = {
  text: string;
  redirection: string;
  trackingElementName: string;
  children?: React.ReactNode;
};

const ZONE = "page";

const trackEvent = (buttonName: string) => {
  const elementClicked = mapElementClickedName[buttonName];
  if (elementClicked) {
    trackNavigationEvent(ZONE, elementClicked);
  }
};

export const Card = ({
  text,
  redirection,
  trackingElementName,
  children
}: CardProps) => (
  <ActionCard
    to={redirection}
    onClick={() => trackEvent(trackingElementName)}
    aria-label={text}
  >
    {children}
    <ActionCardText>{text}</ActionCardText>
  </ActionCard>
);
