import { useEffect } from "react";
import { trackPageViewEvent } from "features/analytics-events/track-page-view-event";
import { useAuth0 } from "@auth0/auth0-react";
import { selectUseAlternativeHomepage } from "features/configuration/selectors/configuration-selectors";
import { useSelector, useStore } from "react-redux";
import { useLocation } from "react-router-dom";

export const usePageView = () => {
  const store = useStore();
  const useAlternativeHomepage = useSelector(selectUseAlternativeHomepage);
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    trackPageViewEvent({
      isLoggedIn: isAuthenticated,
      state: store.getState(),
      isRoutingPage: useAlternativeHomepage,
      route: location.pathname
    });
  }, [location.pathname]);
};
