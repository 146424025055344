import React from "react";
import { useAssistanceServices } from "hooks/use-assistance-services";
import { SubsectionItem } from "../assistance-service-subsection-item";
import { SubsectionContainer } from "./assistance-service-subsection-list-styles";

export const AssistanceServiceSubsectionList = () => {
  const { assistanceServiceSelected } = useAssistanceServices();
  const subsections = assistanceServiceSelected?.subsections;
  const sectionId = assistanceServiceSelected?.id;

  return !!subsections && !!subsections.length && sectionId ? (
    <SubsectionContainer data-testid="subsection-container">
      {subsections.map(subsection => (
        <SubsectionItem
          key={subsection.id}
          id={subsection.id}
          sectionId={sectionId}
          external={subsection.external}
        ></SubsectionItem>
      ))}
    </SubsectionContainer>
  ) : null;
};
