import { State } from "app/store/types";

const INDEX_START_SUBSTRING = 0;
const INDEX_END_SUBSTRING = 2;

export const selectCurrentLanguage = (state: State) => state.currentLanguage;

export const selectCurrentLanguageIsoCode = (state: State) =>
  selectCurrentLanguage(state).substring(
    INDEX_START_SUBSTRING,
    INDEX_END_SUBSTRING
  );

// state.currentLanguage format can be set like languageIsoCode_partnerId or languageIsoCode-country_partnerId
export const selectCustomLanguage = ({
  includeCountryCode = false,
  includePartnerId = false
}: {
  includeCountryCode?: boolean;
  includePartnerId?: boolean;
} = {}) => (state: State) => {
  const [language, partner] = state.currentLanguage.split("_");
  const [languageIsoCode, country] = language.split("-");

  return (
    languageIsoCode +
    (includeCountryCode && country ? `-${country}` : "") +
    (includePartnerId && partner ? `_${partner}` : "")
  );
};
