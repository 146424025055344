import { useTranslate } from "@customer_interactions/i18n";
import { useSelector } from "react-redux";
import { selectIsDebugModeActive } from "features/debug-mode/selectors/debug-mode-selectors";
import { selectUseAlternativeHomepage } from "features/configuration/selectors/configuration-selectors";
import { FooterItem } from "./footer-types";

const cookiesItemConfig = {
  testId: "cookies",
  text: "[Landing_footer]Cookies",
  url: "[Landing_footer]Cookies_url",
  analyticsId: "cookies"
};

const ROUTING_PAGE_FOOTER_ITEMS = [
  {
    testId: "terms-and-conditions",
    text: "[routing_page_footer]-terms_conditions",
    url: "[routing_page_footer]-terms_conditions_url",
    analyticsId: "terms_and_conditions"
  },
  {
    testId: "data-privacy",
    text: "[routing_page_footer]-data_privacy_policy",
    url: "[routing_page_footer]-data_privacy_policy_url",
    analyticsId: "data_privacy"
  },
  cookiesItemConfig
];

const MTC_FOOTER_ITEMS = [
  {
    testId: "terms-and-conditions",
    text: "[Landing_footer]Terms_conditions",
    url: "[Landing_footer]Terms_conditions_url",
    analyticsId: "terms_conditions"
  },
  cookiesItemConfig
];

const getTranslatedItems = (
  items: FooterItem[],
  t: ReturnType<typeof useTranslate>,
  allowEmptyTranslation: boolean
) =>
  items.map(item => ({
    ...item,
    text: t(item.text, allowEmptyTranslation),
    url: t(item.url, allowEmptyTranslation)
  }));

export const useFooter = () => {
  const t = useTranslate();
  const isDebugModeActive = useSelector(selectIsDebugModeActive);
  const useAlternativeHomepage = useSelector(selectUseAlternativeHomepage);

  const footerItems = useAlternativeHomepage
    ? getTranslatedItems(ROUTING_PAGE_FOOTER_ITEMS, t, !isDebugModeActive)
    : getTranslatedItems(MTC_FOOTER_ITEMS, t, !isDebugModeActive);

  const filteredFooterItems = isDebugModeActive
    ? footerItems
    : footerItems.filter(item => item.text && item.url);

  return {
    footerItems: filteredFooterItems,
    t
  };
};
