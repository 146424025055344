import { Action } from "redux";

import { Mapping } from "utils/types";
import { handle } from "utils/reducer-handler";

import { SET_SHOW_MODAL, SET_URL } from "./require-login-action-types";
import { ActionStandard } from "app/store/types";

export interface RequireLoginState {
  showModal: boolean;
  url: string;
}

const initialState: RequireLoginState = {
  showModal: false,
  url: ""
};

const requireLoginHandler: Mapping<Function> = {
  [SET_SHOW_MODAL]: (
    state: RequireLoginState,
    action: ActionStandard<boolean>
  ) => ({ ...state, showModal: action.payload }),
  [SET_URL]: (state: RequireLoginState, action: ActionStandard<string>) => ({
    ...state,
    url: action.payload
  })
};

export const requireLoginReducer = (
  state: RequireLoginState | null = initialState,
  action: Action
) => {
  return handle(state, action, requireLoginHandler);
};
