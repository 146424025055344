const defaultTheme = {
  primaryFontFamily: "PublicoHeadline",
  secondaryFontFamily: "SourceSansPro",
  primaryColor: "#00006D", // default: blue 500
  primaryContrastColor: "FFFFFF", // Not used, remove this comment when branding is implemented
  secondaryColor: "", // Not used, remove this comment when branding is implemented
  secondaryContrastColor: "", // Not used, remove this comment when branding is implemented
  // Palette
  white: "#FFFFFF",
  grey: {
    "100": "#FAFAFA",
    "200": "#F0F0F0",
    "300": "#E5E5E5",
    "400": "#CCCCCC",
    "500": "#999999",
    "600": "#757575",
    "700": "#5F5F5F",
    "800": "#343C3D",
    "900": "#111B1D"
  },
  blue: {
    "100": "#5C5CB7",
    "200": "#3D3DAA",
    "300": "#1F1F9C",
    "400": "#00008F",
    "500": "#00006D"
  },
  teal: {
    "100": "#41949F",
    "200": "#027180",
    "300": "#015A66",
    "400": "#014750",
    "500": "#002126"
  },
  viridian: {
    "400": "#32433F"
  },
  red: "#C91432"
};

export default defaultTheme;
