import React from "react";
import ReactMarkdown from "react-markdown";
import {
  DisclaimerContainer,
  DisclaimerText
} from "./safety-and-policy-styles";
import { useTranslate } from "@customer_interactions/i18n";
import { useSafetyAndAlerts } from "./use-safety-and-alerts";

export const SafetyAndAlerts = () => {
  const t = useTranslate();
  const { widgetHeight } = useSafetyAndAlerts();
  return (
    <>
      <div>
        <iframe
          title="riskline-widget"
          src={process.env.REACT_APP_RISKLINE_WIDGET_URL}
          height={widgetHeight}
          width="100%"
          style={{ border: "none" }}
        />
      </div>
      <DisclaimerContainer>
        <DisclaimerText>
          <ReactMarkdown>{t("[Safety_Alerts]Disclaimer_text")}</ReactMarkdown>
        </DisclaimerText>
      </DisclaimerContainer>
    </>
  );
};
