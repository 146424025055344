import React from "react";
import { Link } from "react-router-dom";
import { useLogo } from "hooks/use-logo";
import { routes } from "constant";
import useTranslation from "hooks/use-translation";

interface LogoProps {
  onClick?: Function;
}

export const Logo = ({ onClick }: LogoProps) => {
  const { pathLogo } = useLogo();
  return (
    <Link onClick={() => onClick?.()} to={routes.HOME}>
      <img
        height={48}
        src={pathLogo}
        data-testid="partner-logo"
        alt={useTranslation("[accessibility]Alt-main-logo")}
      />
    </Link>
  );
};
