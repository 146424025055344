import { openInstallationModal } from "features/installation-modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { isSiteInFullScreenMode } from "../shared/installation-modal/installation-modal-helpers";
import { useIsInstallModalSupported } from "../shared/installation-modal/installation-modal-hooks";
import { Analytics } from "@customer_interactions/application-logger";
import useTranslation from "hooks/use-translation";
import { selectIsDebugModeActive } from "features/debug-mode/selectors/debug-mode-selectors";

export const usePWAInstallationButton = () => {
  const isInstallModalSupported = useIsInstallModalSupported();
  const isDebugModeActive = useSelector(selectIsDebugModeActive);
  const dispatch = useDispatch();
  const buttonText = useTranslation("[Home_PWA_Menu]Add_to_Home_Screen_button");

  const handlePWAInstallationClick = () => {
    Analytics.sendEvent(
      "[Home_PWA_Menu]Add_to_Home_Screen_button-click",
      "Customer_Space"
    );
    dispatch(openInstallationModal());
  };

  return {
    handlePWAInstallationClick,
    isInstallModalSupported,
    alreadyInstalled: isSiteInFullScreenMode(),
    buttonText,
    isDebugModeActive
  };
};
