import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";

export const trackNavigationEvent = (
  zone: string,
  elementClicked: string,
  elementDisplayed?: string
) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.NAVIGATION,
    element_clicked: elementClicked,
    zone,
    element_displayed: elementDisplayed
  });
};
