import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import DownloadIcon from "app/components/icons/download-icon";
import { DownloadButton } from "./travel-tips-styles";
import { Analytics } from "@customer_interactions/application-logger";
import { useHistory } from "react-router-dom";
import { routes } from "constant";

const DownloadPdfButton = () => {
  const t = useTranslate();
  const history = useHistory();
  const handleDownloadClick = () => {
    Analytics.sendEvent(
      "[travel-tips][container] button-click",
      "Customer_Space"
    );
    history.push(routes.TRAVEL_TIPS_PDF);
  };

  return (
    <DownloadButton
      data-testid="travel-tips-download-button"
      onClick={handleDownloadClick}
    >
      <DownloadIcon />
      {t("[travel_tips]download_button")}
    </DownloadButton>
  );
};

export default DownloadPdfButton;
