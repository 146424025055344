import { ActionStandard } from "app/store/types";
import { Mapping } from "utils/types";
import {
  ENABLE_DEBUG_MODE,
  TOGGLE_DEBUG_MODE
} from "../actions/debug-mode-actions";
import { handle } from "utils/reducer-handler";

export interface DebugModeState {
  isDebugModeActive: boolean;
  isDebuggable: boolean;
}

const debugModeStateHandlers: Mapping<Function> = {
  [TOGGLE_DEBUG_MODE]: (
    _state: DebugModeState,
    _action: ActionStandard<undefined>
  ) => ({
    ..._state,
    isDebugModeActive: !_state.isDebugModeActive
  }),
  [ENABLE_DEBUG_MODE]: (
    _state: DebugModeState,
    _action: ActionStandard<undefined>
  ) => ({
    ..._state,
    isDebuggable: true
  })
};

const initialState = {
  isDebugModeActive: false,
  isDebuggable: false
};

export const debugModeReducer = (
  state: DebugModeState = initialState,
  action: ActionStandard<DebugModeState>
) => {
  return handle(state, action, debugModeStateHandlers);
};
