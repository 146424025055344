import { selectCustomLanguage } from "features/language/selectors/language-selector";
import React from "react";
import { useSelector } from "react-redux";

export const TravelTipsPDF = () => {
  const languageCode = useSelector(
    selectCustomLanguage({
      includeCountryCode: true
    })
  );
  const pdfStorageURL = process.env.REACT_APP_PDFS_URL;
  return (
    <iframe
      data-testid="travel-tips-pdf"
      src={`${pdfStorageURL}/travel_tips_${languageCode}.pdf`}
      style={{ width: "100vw", height: "100vh" }}
    />
  );
};
