import { LinkResolver } from "app/components/link-resolver/link-resolver";
import styled from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { ButtonMobileStyles, ButtonStyles, variantStyles } from "../button";

export const CTAButton = styled(LinkResolver)`
  ${ButtonStyles}
  ${({ theme, variant }) => variantStyles(theme, variant)}
  ${getCssForMobileView(ButtonMobileStyles)}
`;
