import React, { useState } from "react";
import WhatsappIcon from "app/components/icons/whatsapp-icon";
import {
  CloseFloatingIcon,
  FloatingBanner,
  FloatingBannerText,
  FloatingCircle,
  FloatingContainer
} from "./floating-whatsapp-styles";
import RightTriangle from "app/components/icons/right-triangle";
import CloseModalIcon from "app/components/icons/close-modal-icon";
import { useTranslate } from "@customer_interactions/i18n";
import {
  getChatZoneByRoute,
  trackStartChat
} from "features/analytics-events/track-start-chat-event";
import { useLocation } from "react-router-dom";

export interface FloatingWhatsappProps {
  link: string;
}

export function FloatingWhatsapp({ link }: Readonly<FloatingWhatsappProps>) {
  const t = useTranslate();
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();

  const redirectToWhatsapp = () => {
    trackStartChat("chat_icon", getChatZoneByRoute(location.pathname));
    window.open(link, "_blank");
  };

  const closeFloating = () => {
    setShowBanner(false);
  };

  return (
    <FloatingContainer>
      <FloatingCircle onClick={redirectToWhatsapp}>
        <WhatsappIcon />
      </FloatingCircle>
      {showBanner && (
        <FloatingBanner>
          <CloseFloatingIcon onClick={closeFloating}>
            <CloseModalIcon width={8} height={9} />
          </CloseFloatingIcon>
          <FloatingBannerText onClick={redirectToWhatsapp}>
            {t("[routing_page]-whatsapp-tooltip")}
          </FloatingBannerText>
          <RightTriangle className="triangle" />
        </FloatingBanner>
      )}
    </FloatingContainer>
  );
}
