import { isAndroidOS, isIOS } from "utils/user-agent";
import { Analytics } from "@customer_interactions/application-logger";

export const isInstallTriggerSupported = () =>
  "onbeforeinstallprompt" in window;

export const isSiteInFullScreenMode: () => boolean = () => {
  return window.matchMedia("(display-mode: fullscreen)").matches;
};

export const getModalTitlePOTag = () => {
  if (isIOS()) {
    return "[Home_PWA_IOS]Title";
  } else {
    return "[HOME_PWA_Android]Title";
  }
};

export const getCloseModalPOTag = () => {
  if (isIOS()) {
    return "[Home_PWA_IOS]Cancel_button";
  } else {
    return "[HOME_PWA_Android]Cancel_button";
  }
};

export const sendModalCloseEvent = () => {
  if (isIOS()) {
    Analytics.sendEvent("[Home_PWA_IOS]cancel_button-click", "Customer_Space");
  } else if (isAndroidOS()) {
    Analytics.sendEvent(
      "[Landing_PWA_android]cancel_button-click",
      "Customer_Space"
    );
  }
};

export const sendModalInstallEvent = () => {
  if (isAndroidOS()) {
    Analytics.sendEvent(
      "[Landing_PWA_android]add_button-click",
      "Customer_Space"
    );
  }
};
