import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "@customer_interactions/i18n";
import { useWindowSize } from "hooks/use-window-size";
import { useAssistanceServices } from "hooks/use-assistance-services";

import { AssistanceServiceMainSection } from "./assistance-services-section-styles";
import { AssistanceServiceHeader } from "app/components/assistance-service-header";
import { AssistanceServiceList } from "app/components/assistance-service-list/assistance-service-list";
import { setAssistanceServiceSelected } from "features/assistance-section/assistance-section-actions";
import { AssistanceService } from "features/configuration/configuration-types";

export const AssistanceServiceSection = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { assistanceServices } = useAssistanceServices();
  const { isMobile } = useWindowSize();

  const handleSelectAssistanceService = (service: AssistanceService | null) => {
    dispatch(setAssistanceServiceSelected(service));
  };

  useEffect(() => {
    if (!isMobile) {
      handleSelectAssistanceService(assistanceServices[0]);
    }
  }, []);

  return (
    <AssistanceServiceMainSection title="assistance-service-section">
      <AssistanceServiceHeader
        title={t("[Home_Assistance_service]Title")}
        description={t("[Home_Assistance_service]Text")}
      />
      <AssistanceServiceList
        assistanceServices={assistanceServices}
        onSelectAssistanceService={handleSelectAssistanceService}
      />
    </AssistanceServiceMainSection>
  );
};
