import { getTheme } from "./global-styles";
import { ConfigModel } from "../features/configuration/configuration-types";
import defaultTheme from "./default-theme";
import { Theme } from "./types";

export const useGlobalStyle = (configuration: ConfigModel) => {
  let theme: Theme = defaultTheme;
  if (!!configuration?.theme) {
    theme = getTheme(configuration);
  }
  return { theme };
};
