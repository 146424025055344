import { Action } from "redux";

import { APP_LOADED, APP_LOADING } from "./loading-action-types";

export const appLoaded = (): Action => ({
  type: APP_LOADED
});

export const appIsLoading = (): Action => ({
  type: APP_LOADING
});
