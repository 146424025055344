import { useDispatch, useSelector } from "react-redux";
import {
  selectIsDebugModeActive,
  selectIsDebuggable
} from "./selectors/debug-mode-selectors";
import { toggleDebugMode as toggleDebugModeActionCreator } from "./actions/debug-mode-actions";

export const useDebugMode = () => {
  const dispatch = useDispatch();
  const isDebugMode = useSelector(selectIsDebugModeActive);
  const isDebuggable = useSelector(selectIsDebuggable);

  const toggleDebugMode = () => {
    dispatch(toggleDebugModeActionCreator());
  };

  return {
    toggleDebugMode,
    isDebugMode: isDebugMode && isDebuggable,
    isDebuggable
  };
};
