import React from "react";
import { useScriptLoader } from "hooks/use-script-loader";
import { useSelector } from "react-redux";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { useWCTheme } from "hooks/use-wc-theme";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";

const CustomerSatifaction = () => {
  const theme = useWCTheme();
  const currentLanguage = useSelector(selectCurrentLanguageIsoCode);
  const customTheme = {
    ...theme,
    primaryFontFamily: theme.secondaryFontFamily
  };
  const config = useSelector(selectConfiguration);
  const { isLoaded } = useScriptLoader(
    process.env.REACT_APP_CUSTOMER_SATISFACTION_WC_URL!,
    "customer-satisfaction"
  );

  return isLoaded ? (
    <customer-satisfaction
      language_code={currentLanguage}
      theme={JSON.stringify(customTheme)}
      host_tracking_id={process.env.REACT_APP_GLOBAL_GA_TRACKING_ID}
      partner_tracking_id={config.partnerTrackingId}
    ></customer-satisfaction>
  ) : null;
};

export default CustomerSatifaction;
