import React, { useState } from "react";
import { AssistanceService } from "features/configuration/configuration-types";
import capitalize from "lodash/capitalize";
import { useTranslate } from "@customer_interactions/i18n";
import BagaggeIcon from "app/components/icons/bagagge-icon";
import MedicalIcon from "app/components/icons/medical-icon";
import ClaimIcon from "app/components/icons/claim-icon";
import ArrowDownIcon from "app/components/icons/arrow-down-icon";
import {
  MenuItem,
  MenuTitle,
  MenuTitleText
} from "./menu-mobile-assistance-service-styles";
import ArrowUpIcon from "app/components/icons/arrow-up-icon";
import { useOutsideClick } from "hooks/use-outside-click";
import { MenuItem as SubMenuItem } from "app/components/header/menu/menu-expanded-item";

const iconProps = {
  height: 24,
  width: 24,
  style: {
    marginRight: "10px"
  }
};

const getIcon = (id?: string): React.ReactElement | null => {
  switch (id) {
    case "travel":
      return <BagaggeIcon {...iconProps} />;
    case "medical":
      return <MedicalIcon {...iconProps} />;
    case "claim":
      return <ClaimIcon {...iconProps} />;
    default:
      return null;
  }
};

export const MobileMenuAssistanceService: React.FC<AssistanceService> = ({
  id,
  subsections
}) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const hasSubsection = Boolean(subsections?.length);

  const { htmlNodeRef: menuItemRef } = useOutsideClick(() => setIsOpen(false));
  return (
    <MenuItem isOpen={isOpen} ref={menuItemRef}>
      <MenuTitle
        isOpen={isOpen}
        data-testid={`home-mobile-menu-title-${id}`}
        onClick={() => {
          if (hasSubsection) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <MenuTitleText>
          {getIcon(id)}
          {t(`[Home_Assistance_service]${capitalize(id)}_title`)}
        </MenuTitleText>
        {!isOpen ? (
          <ArrowDownIcon width={16} height={16} />
        ) : (
          <ArrowUpIcon width={16} height={16} />
        )}
      </MenuTitle>
      {isOpen &&
        subsections?.map(subsection => (
          <SubMenuItem
            sectionId={id}
            subsection={subsection}
            key={subsection.id}
          />
        ))}
    </MenuItem>
  );
};
