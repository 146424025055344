import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { useWindowSize } from "hooks/use-window-size";
import { AssistanceServiceSection } from "pages/sections/assistance-service-section";
import { GradientContainer, MainContainer } from "./home-styles";
import { TripSection } from "pages/sections/trip-section";
import { CustomerSatifactionSection } from "pages/sections/customer-satisfaction";
import { MainImageContainer } from "styles";
import DigitalCard from "app/components/digital-card-section/digital-card";
import { VideoSection } from "pages/sections/video";
import { Card } from "app/components/card";
import { useHome } from "./useHome";

const HomePage = () => {
  const t = useTranslate();
  const { digitalCardEnable, hasVideoSection, imageURL, user } = useHome();
  const { isMobile } = useWindowSize();
  const TEXTS = {
    homeTitle: t(
      user?.given_name ? "[Home_TopSection]Title" : "[Landing_TopSection]Title"
    ),
    homeText: t("[Home_TopSection]Text"),
    videoSectionTitle: t("[Video]Title"),
    videoSectionText: t("[Video]Text"),
    customerSatisfactionTitle: t("[feedback]content_title"),
    customerSatisfactionText: t("[feedback]content_text")
  };
  const mainCard = (
    <Card
      title={`${user?.given_name ?? ""}${TEXTS.homeTitle}`}
      text={TEXTS.homeText}
    />
  );

  return (
    <>
      {isMobile ? (
        <MainContainer>
          <MainImageContainer
            imageURL={imageURL}
            data-testid="background-image"
          />
          {mainCard}
        </MainContainer>
      ) : (
        <MainContainer>
          <MainImageContainer
            imageURL={imageURL}
            data-testid="background-image"
          >
            <GradientContainer>{mainCard}</GradientContainer>
          </MainImageContainer>
        </MainContainer>
      )}

      <AssistanceServiceSection />
      {hasVideoSection && (
        <VideoSection
          title={TEXTS.videoSectionTitle}
          text={TEXTS.videoSectionText}
        />
      )}
      <TripSection />
      {digitalCardEnable && <DigitalCard />}
      <CustomerSatifactionSection
        title={TEXTS.customerSatisfactionTitle}
        text={TEXTS.customerSatisfactionText}
      />
    </>
  );
};

export default HomePage;
