import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { Button } from "../../button";

const FooterMobileStyles = css`
  width: 100%;
  justify-content: space-between;
`;

export const FooterContainer = styled.div`
  display: flex;
  gap: 1rem;
  ${getCssForMobileView(FooterMobileStyles)}
`;

const ButtonMobileStyles = css`
  flex-grow: 1;
`;

export const ModalButton = styled(Button)`
  border-radius: 4px;
  ${getCssForMobileView(ButtonMobileStyles)}
`;
