import styled from "styled-components";

export const FaqsErrorContainer = styled.section`
  display: flex;
  padding: 100px 50px;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grey["100"]};

  svg {
    overflow: unset;
  }
`;

export const FaqsErrorText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-top: 8px;
  width: 520px;
  max-width: 100%;
  text-align: center;
`;
