import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

export interface IconCustomColorProps extends IconProps {
  fillMain?: string;
}

const CheckIcon = ({
  theme,
  inverted = false,
  fillMain,
  ...props
}: IconCustomColorProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H32V32H0V0Z"
        fill={fillMain ?? (inverted ? theme.teal["200"] : theme.white)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 16.0003C2.66675 8.64033 8.64008 2.66699 16.0001 2.66699C23.3601 2.66699 29.3334 8.64033 29.3334 16.0003C29.3334 23.3603 23.3601 29.3337 16.0001 29.3337C8.64008 29.3337 2.66675 23.3603 2.66675 16.0003ZM6.66675 16.0003L13.3334 22.667L25.3334 10.667L23.4534 8.77366L13.3334 18.8937L8.54675 14.1203L6.66675 16.0003Z"
        fill={inverted ? theme.white : theme.teal["200"]}
      />
    </svg>
  );
};

export default withTheme(CheckIcon);
