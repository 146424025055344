import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const DEFAULT_WIDTH = 15;
const DEFAULT_HEIGHT = 14;

const CloseModalIcon = ({
  theme,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 14"
    aria-label="close-modal-icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 1.41L13.09 0L7.5 5.59L1.91 0L0.5 1.41L6.09 7L0.5 12.59L1.91 14L7.5 8.41L13.09 14L14.5 12.59L8.91 7L14.5 1.41Z"
      fill={theme.grey["600"]}
    />
  </svg>
);

export default withTheme(CloseModalIcon);
