import styled from "styled-components";

const viewHeight = `${window.innerHeight}px`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 105px;
  height: calc(${viewHeight} - 105px);
  width: 100vw;
  background: ${({ theme }) => theme.white};
  overflow-y: auto;
  box-shadow: 0px 4px 8px 0px #00000033;
`;

export const MenuBottomSection = styled.div``;

export const MenuFooter = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-left: 53px;
  background-color: ${({ theme }) => theme.grey["200"]};
`;

export const MenuFooterItem = styled.div`
  padding-top: 15px;
`;

export const LoginButtonContainerNotLogged = styled.div`
  display: flex;
  justify-content: center;
  padding: 9px 22px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.grey["200"]};
`;
