import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@customer_interactions/i18n";
import { selectConfiguration } from "features/configuration/selectors/configuration-selectors";
import { setSuccessToaster } from "features/toaster";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

type Auth0ClientData = {
  client_id: string;
  email: string;
  domain: string;
};

const handleChangePassword = (auth0ClientData: Auth0ClientData) => {
  const { client_id, email, domain } = auth0ClientData;

  const data = {
    client_id,
    email,
    connection: "Username-Password-Authentication"
  };

  const requestResetPasswordURL = `https://${domain}/dbconnections/change_password`;

  fetch(requestResetPasswordURL, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const useHandleChangePassword = () => {
  const [isEmailAlreadySent, setIsEmailAlreadySent] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslate();
  const { user } = useAuth0();
  const config = useSelector(selectConfiguration);
  const changePasswordValue = {
    client_id: config.auth0ClientId,
    domain: config.auth0Domain,
    email: user?.email
  } as Auth0ClientData;

  const successToasterMessage = (message: string) =>
    t(message, false, { mail: user?.email });

  return () => {
    if (isEmailAlreadySent) {
      dispatch(
        setSuccessToaster(
          successToasterMessage("[account_change_password] message_2")
        )
      );
      return;
    }

    handleChangePassword(changePasswordValue);
    dispatch(
      setSuccessToaster(
        successToasterMessage("[account_change_password] message_1")
      )
    );
    setIsEmailAlreadySent(true);
  };
};
