import React from "react";
import styled, { css } from "styled-components";
import { TOASTER_CATEGORY } from ".";
import { getCssForLargeView } from "styles/responsive";
import { ToasterWrapperProps } from "./toaster-types";
import CheckIcon from "app/components/icons/check-icon";

const successColor = "#014750";

export const ToasterContainer = styled.div<ToasterWrapperProps>`
  display: flex;
  grid-template-columns: 11px auto;
  position: fixed;
  bottom: 13px;
  left: 9px;
  right: 9px;
  overflow: auto;
  z-index: 10000;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 13px;
  margin-left: 9px;
  margin-right: 9px;
  align-items: center;
  font-size: 14px;
  background-color: ${({ category }) =>
    category === TOASTER_CATEGORY.SUCCESS ? successColor : "#c91432"};

  ${getCssForLargeView(css`
    width: 43%;
    margin: auto;
  `)}
`;

export const ToasterIcon = styled.div`
  margin: 0 18px;
`;

export const ToasterMessage = styled.div`
  color: ${({ theme }) => theme.white};
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const getIconByCategory = (category: string) => {
  return category === TOASTER_CATEGORY.SUCCESS ? (
    <CheckIcon inverted={true} fillMain={successColor} width={22} />
  ) : (
    <></>
  );
};
