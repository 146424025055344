import React, { FC } from "react";
import { MainPageContainer } from "../layout-styles";
import {
  HeaderLanguage,
  HeaderLanguageMobile
} from "app/components/header-language/header-language";
import { Header } from "app/components/header/header";
import { HeaderMobile } from "app/components/header-mobile/header-mobile";
import { Footer } from "app/components/footer/footer";
import ScrollUpButton from "app/components/shared/scroll-up-button/scroll-up-button";

import { useWindowSize } from "hooks/use-window-size";
import { useHamburguerMenuActions } from "hooks/use-hamburguer-menu";

import LoginModal from "app/components/shared/login-modal/login-modal";
import { InstallationModal } from "app/components/shared/installation-modal";

interface IPageProps {
  children: React.ReactNode;
}

const LayoutPage: FC<IPageProps> = ({ children }: IPageProps) => {
  const { isMobile } = useWindowSize();
  const { isMenuOpened } = useHamburguerMenuActions();

  const MobileHeaderSection = (
    <>
      <HeaderLanguageMobile />
      <HeaderMobile />
    </>
  );

  const DesktopHeaderSection = (
    <>
      <HeaderLanguage />
      <Header />
    </>
  );

  const shouldRenderChildren = !isMobile || (isMobile && !isMenuOpened);

  return (
    <MainPageContainer>
      {isMobile ? MobileHeaderSection : DesktopHeaderSection}
      {shouldRenderChildren && children}
      <Footer />
      <ScrollUpButton />
      <LoginModal />
      <InstallationModal />
    </MainPageContainer>
  );
};

export default LayoutPage;
