export const isTheSameEnvironmentVariable = (
  environmentVariable?: string,
  comparator?: string
): boolean =>
  JSON.stringify(environmentVariable) === JSON.stringify(comparator);

export const isTestEnvironment = (): boolean =>
  isTheSameEnvironmentVariable(process.env.REACT_APP_ENVIRONMENT, "ppa");

export const PRA_DOMAIN = "customerspaceglobal.com";

export const isPRADomain = (env?: "prod" | "test") => {
  const { origin } = window.location;
  const isPRA = origin.includes(PRA_DOMAIN);

  if (env) {
    return isPRA && origin.includes(env);
  }

  return isPRA;
};
