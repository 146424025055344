import { useSelector } from "react-redux";
import { selectAssistanceServiceSelected } from "features/assistance-section/assistance-section-selectors";
import { selectAssistanceServices } from "features/configuration/selectors/configuration-selectors";

export const useAssistanceServices = () => {
  const assistanceServices = useSelector(selectAssistanceServices);
  const assistanceServiceSelected = useSelector(
    selectAssistanceServiceSelected
  );
  return { assistanceServices, assistanceServiceSelected };
};
