import { useTranslate } from "@customer_interactions/i18n";

export const CONTACT_US_MODAL_COLLAPSABLE_LIST = [
  {
    expandLabel: "[Contact]Modal_expand_label",
    collapseLabel: "[Contact]Modal_collapse_label",
    title: "[Contact]Modal_collapse_label",
    content: "[Contact]Modal_expanded_text2"
  },
  {
    expandLabel: "[Contact]Modal_expand_label2",
    collapseLabel: "[Contact]Modal_collapse_label2",
    title: "[Contact]Modal_collapse_label2",
    content: "[Contact]Modal_expanded_text3"
  },
  {
    expandLabel: "[Contact]Modal_expand_label3",
    collapseLabel: "[Contact]Modal_collapse_label3",
    title: "[Contact]Modal_collapse_label3",
    content: "[Contact]Modal_expanded_text4"
  },
  {
    expandLabel: "[Contact]Modal_expand_label4",
    collapseLabel: "[Contact]Modal_collapse_label4",
    title: "[Contact]Modal_collapse_label4",
    content: "[Contact]Modal_expanded_text5"
  }
];

export const useContactUsModal = () => {
  const t = useTranslate();

  const mainContent = t("[Contact]Modal_collapsed_text1", true);

  const collapsableList = CONTACT_US_MODAL_COLLAPSABLE_LIST.map(
    ({ expandLabel, collapseLabel, title, content }) => ({
      expandLabel: t(expandLabel, true),
      collapseLabel: t(collapseLabel, true),
      title: t(title, true),
      content: t(content, true)
    })
  );

  return {
    mainContent,
    collapsableList
  };
};
