import React from "react";
import { Button, IconButton } from "./scroll-up-button-styles";
import { useScrollThreshold } from "hooks/use-scroll-threshold";
import { routesWithScrollUpButton } from "constant";
import { scrollUp } from "utils/window-utils";

const SCROLL_THRESHOLD_PIXELS = 200;

const ScrollUpButton = () => {
  const { isReached: isScrollReached } = useScrollThreshold(
    SCROLL_THRESHOLD_PIXELS
  );

  const shouldShowScrollUpButton =
    routesWithScrollUpButton.includes(location.pathname) && isScrollReached;

  return shouldShowScrollUpButton ? (
    <Button data-testid="scroll-up-icon" onClick={scrollUp}>
      <IconButton />
    </Button>
  ) : null;
};

export default ScrollUpButton;
