import React from "react";
import { MedicalWebComponentContainer } from "./medical-providers-search-styles";
import { MedProvSearchPage } from "./medical-providers-search";

export const MedicalProviderSearchPage = () => {
  return (
    <MedicalWebComponentContainer id="medical-web-component-container">
      <MedProvSearchPage></MedProvSearchPage>
    </MedicalWebComponentContainer>
  );
};
