const TIME_OUT = 700;

export const initializeDynamicContent = () => {
  if (window.OneTrust) {
    window.OneTrust.initializeCookiePolicyHtml();
  } else {
    setTimeout(() => {
      if (window.OneTrust) {
        window.OneTrust.initializeCookiePolicyHtml();
      }
    }, TIME_OUT);
  }
};
