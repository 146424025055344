import { useState, useEffect, useCallback } from "react";
import { BREAKPOINTS } from "styles/responsive";

export const useWindowSize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowsSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  const isMobile: boolean = width <= BREAKPOINTS.mobileBreakPoint;
  const isExtraLargeScreen = width >= BREAKPOINTS.largeBreakPoint;

  useEffect(() => {
    window.addEventListener("resize", handleWindowsSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowsSizeChange);
    };
  }, []);

  return { width, isMobile, isExtraLargeScreen };
};
