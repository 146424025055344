import { selectIsAppLoaded } from "features/loading/loading-selectors";
import { useSelector } from "react-redux";

export const useIsLoading = () => {
  const isAppLoaded = useSelector(selectIsAppLoaded);

  return {
    isLoading: !isAppLoaded
  };
};
