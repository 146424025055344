import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const PageCircleIcon = ({ theme, color, ...props }: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2055_10614)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5172 10.2265C22.5055 10.1994 22.4887 10.1749 22.4676 10.1544L19.3127 7.05352H19.2969C19.267 7.0156 19.2232 6.99125 19.1752 6.98592H10.2152C9.80375 6.98466 9.4685 7.31588 9.46479 7.72732V24.2727C9.4685 24.6841 9.80375 25.0153 10.2152 25.0141H21.7848C22.1962 25.0153 22.5315 24.6841 22.5352 24.2727V10.3121C22.5349 10.2827 22.5287 10.2536 22.5172 10.2265ZM19.3803 7.74986L21.76 10.0868H19.68C19.6017 10.088 19.5261 10.058 19.4699 10.0035C19.4137 9.94897 19.3815 9.87435 19.3803 9.79606V7.74986ZM21.9949 24.4801C22.0511 24.4256 22.0833 24.351 22.0845 24.2727V10.5375H19.68C19.2685 10.5387 18.9333 10.2075 18.9296 9.79606V7.43662H10.2152C10.1369 7.43542 10.0613 7.46537 10.0051 7.51989C9.94893 7.57441 9.91668 7.64903 9.91549 7.72732V24.2727C9.91668 24.351 9.94893 24.4256 10.0051 24.4801C10.0613 24.5346 10.1369 24.5646 10.2152 24.5634H21.7848C21.8631 24.5646 21.9387 24.5346 21.9949 24.4801Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M11.7927 9.76225H15.4749C15.5994 9.76225 15.7003 9.66136 15.7003 9.5369C15.7003 9.41244 15.5994 9.31155 15.4749 9.31155H11.7927C11.6682 9.31155 11.5673 9.41244 11.5673 9.5369C11.5673 9.66136 11.6682 9.76225 11.7927 9.76225Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M11.7927 11.3149H13.8952C14.0197 11.3149 14.1206 11.214 14.1206 11.0896C14.1206 10.9651 14.0197 10.8642 13.8952 10.8642H11.7927C11.6682 10.8642 11.5673 10.9651 11.5673 11.0896C11.5673 11.214 11.6682 11.3149 11.7927 11.3149Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M11.5673 14.7065C11.5673 14.8309 11.6682 14.9318 11.7927 14.9318H20.2073C20.3318 14.9318 20.4327 14.8309 20.4327 14.7065C20.4327 14.582 20.3318 14.4811 20.2073 14.4811H11.7927C11.6682 14.4811 11.5673 14.582 11.5673 14.7065Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M20.2073 16.0338H11.7927C11.6682 16.0338 11.5673 16.1347 11.5673 16.2592C11.5673 16.3836 11.6682 16.4845 11.7927 16.4845H20.2073C20.3318 16.4845 20.4327 16.3836 20.4327 16.2592C20.4327 16.1347 20.3318 16.0338 20.2073 16.0338Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M20.2073 17.5775H11.7927C11.6682 17.5775 11.5673 17.6784 11.5673 17.8028C11.5673 17.9273 11.6682 18.0282 11.7927 18.0282H20.2073C20.3318 18.0282 20.4327 17.9273 20.4327 17.8028C20.4327 17.6784 20.3318 17.5775 20.2073 17.5775Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        d="M16 19.1346H11.7927C11.6682 19.1346 11.5673 19.2355 11.5673 19.36C11.5673 19.4845 11.6682 19.5854 11.7927 19.5854H16C16.1245 19.5854 16.2254 19.4845 16.2254 19.36C16.2254 19.2355 16.1245 19.1346 16 19.1346Z"
        fill={color ?? theme.blue["400"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 8.06511 26.1875 1.36116 18.4214 0.182062C17.6249 0.0611426 16.8163 0 16 0C7.16344 0 0 7.16344 0 16ZM31.5493 16C31.5493 24.5876 24.5876 31.5493 16 31.5493C7.41236 31.5493 0.450704 24.5876 0.450704 16C0.450704 7.41236 7.41236 0.450704 16 0.450704C16.7936 0.450704 17.5797 0.510137 18.3537 0.627659C25.9004 1.77344 31.5493 8.28872 31.5493 16Z"
        fill={color ?? theme.blue["400"]}
      />
    </g>
    <defs>
      <clipPath id="clip0_2055_10614">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default withTheme(PageCircleIcon);
