import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { useAssistanceServices } from "hooks/use-assistance-services";
import { SectionTitle } from "styles";
import TripCard from "./trip-card";
import {
  TripSectionContainer,
  TripSectionFlexContainer,
  TripSectionRowContainer
} from "./trip-section-styles";

export const TripSection = () => {
  const t = useTranslate();
  const { assistanceServices } = useAssistanceServices();

  return (
    <TripSectionContainer title="trip-section">
      <TripSectionFlexContainer data-testid="trip-section-container">
        <SectionTitle data-testid="trip-section-title">
          {t("[Landing_Trip]Title")}
        </SectionTitle>
        <TripSectionRowContainer>
          {assistanceServices?.map(item => (
            <TripCard
              key={item.id}
              id={item.id}
              subsections={item.subsections}
            />
          ))}
        </TripSectionRowContainer>
      </TripSectionFlexContainer>
    </TripSectionContainer>
  );
};
