import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const AccountMenuContainerMobile = css`
  padding: 22px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  display: flex;
  flex-direction: column;
`;

export const AccountMenuContainer = styled.div`
  position: relative;
  margin-right: 32px;
  direction: rtl;
  ${getCssForMobileView(AccountMenuContainerMobile)}
`;

export const MenuTrigger = styled.button`
  display: flex;
  align-items: center;
  direction: initial;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid ${({ theme }) => theme.blue["300"]};
  cursor: pointer;
  background: ${({ theme }) => theme.white};
`;

const MenuTitleMobile = css`
  margin-left: 16px;
`;

export const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blue["300"]};
  text-transform: uppercase;
  margin-left: 8px;
  margin-right: 8px;
  ${getCssForMobileView(MenuTitleMobile)}
`;

const MenuContentMobile = css`
  top: 64px;
  left: 22px;
  right: 22px;
`;

export const MenuContent = styled.ul`
  position: absolute;
  direction: initial;
  z-index: 2000;
  list-style-type: none;
  margin: 0;
  padding: 0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  background: ${({ theme }) => theme.white};
  ${getCssForMobileView(MenuContentMobile)}
`;

interface MenuItemProps {
  isClickable?: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  padding: 16px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
  &:hover {
    background: ${({ isClickable, theme }) =>
      isClickable ? theme.grey["200"] : "initial"};
  }
`;

export const EmailItemContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
`;

export const LogoutMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.red};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const EmailContent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
