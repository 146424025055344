import { useTranslate } from "@customer_interactions/i18n";
import React, { PropsWithChildren } from "react";

export const IfTranslationExists = ({
  translationKey,
  children
}: PropsWithChildren<{ translationKey: string }>) => {
  const translate = useTranslate();
  const translation = translate(translationKey, true);
  return translation ? <>{children}</> : null;
};
