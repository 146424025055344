import styled, { css } from "styled-components";

const Container = css`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.grey["100"]};
`;
export const HeaderLanguageContainer = styled.header`
  ${Container}
  justify-content: flex-end;
  align-items: normal;
  padding-left: 0.5rem;
`;

export const HeaderLanguageContainerMobile = styled.header`
  ${Container}
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grey["400"]};
  position: fixed;
  top: 0;
  z-index: 2000;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
