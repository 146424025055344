import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const QuestionMark: React.FC<IconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.994141C5.47439 0.994141 0.994995 5.47353 0.994995 10.9991C0.994995 16.5247 5.47439 21.0041 11 21.0041C16.5256 21.0041 21.005 16.5247 21.005 10.9991C21.005 8.34565 19.9509 5.80084 18.0746 3.92454C16.1983 2.04824 13.6535 0.994141 11 0.994141ZM9.995 17.9891V15.9941H12.005V17.9891H9.995ZM13.1001 11.1643L14.0001 10.2493C14.6183 9.66228 14.975 8.85164 14.9901 7.99926C15.0812 6.51265 14.3398 5.09828 13.0654 4.32742C11.791 3.55656 10.1941 3.55656 8.91972 4.32742C7.64532 5.09828 6.90396 6.51265 6.99506 7.99926H9.00506C9.00506 6.89745 9.89825 6.00426 11.0001 6.00426C12.1019 6.00426 12.9951 6.89745 12.9951 7.99926C12.9998 8.52923 12.7886 9.03829 12.4101 9.40926L11.1651 10.6693C10.4157 11.4225 9.99509 12.4418 9.99506 13.5043V13.9993H12.0051C11.9047 12.9348 12.3102 11.8849 13.1001 11.1643Z"
        fill={theme.teal["200"]}
      />
    </svg>
  );
};

export default withTheme(QuestionMark);
