import { Analytics } from "@customer_interactions/application-logger";
import { EVENT_NAMES } from "./common";

export const trackDisplayEvent = (
  elementDisplayed: string,
  elementName: string
) => {
  Analytics.sendEventData({
    event: EVENT_NAMES.DISPLAY,
    element_displayed: elementDisplayed,
    element_name: elementName
  });
};
