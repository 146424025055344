import React, { PropsWithChildren } from "react";
import {
  ButtonContainer,
  HeaderLanguageContainer,
  HeaderLanguageContainerMobile
} from "./header-language-styles";
import { ExternalGoBackButton } from "app/components/external-go-back-button/external-go-back-button";
import LanguageSelector from "app/components/language-selector/language-selector";
import {
  LANGUAGE_SELECTOR_DESKTOP_CONTAINER_ID,
  LANGUAGE_SELECTOR_MOBILE_CONTAINER_ID
} from "constant";

export const HeaderLanguage = ({ children }: PropsWithChildren<{}>) => {
  return (
    <HeaderLanguageContainer id={LANGUAGE_SELECTOR_DESKTOP_CONTAINER_ID}>
      {children}
      <ExternalGoBackButton />
      <LanguageSelector />
    </HeaderLanguageContainer>
  );
};

export const HeaderLanguageMobile = ({ children }: PropsWithChildren<{}>) => {
  return (
    <HeaderLanguageContainerMobile id={LANGUAGE_SELECTOR_MOBILE_CONTAINER_ID}>
      <ButtonContainer>
        {children}
        <ExternalGoBackButton />
      </ButtonContainer>
      <LanguageSelector />
    </HeaderLanguageContainerMobile>
  );
};
