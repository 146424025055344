import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const ClaimIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="52"
    height="50"
    viewBox="0 0 52 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.9285 4.16602H13.0162C10.6907 4.16602 8.78809 6.04102 8.78809 8.33268V41.666C8.78809 43.9577 10.6907 45.8327 13.0162 45.8327H38.3846C40.7101 45.8327 42.6127 43.9577 42.6127 41.666V16.666L29.9285 4.16602ZM13.0162 41.666V8.33268H27.8144V16.666H38.3846V41.666H13.0162ZM23.5864 39.5827H27.8144V37.4993H29.9285C31.0912 37.4993 32.0425 36.5618 32.0425 35.416V29.166C32.0425 28.0202 31.0912 27.0827 29.9285 27.0827H23.5864V24.9993H32.0425V20.8327H27.8144V18.7493H23.5864V20.8327H21.4723C20.3096 20.8327 19.3583 21.7702 19.3583 22.916V29.166C19.3583 30.3118 20.3096 31.2493 21.4723 31.2493H27.8144V33.3327H19.3583V37.4993H23.5864V39.5827Z"
      fill={theme.teal["200"]}
    />
  </svg>
);

export default withTheme(ClaimIcon);
