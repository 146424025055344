import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const AlertIcon = ({ theme, fill, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    {...props}
  >
    <path
      d="M20.9996 14.4363C21.7245 14.4363 22.3121 15.024 22.3121 15.7488V22.7488C22.3121 23.4737 21.7245 24.0613 20.9996 24.0613C20.2747 24.0613 19.6871 23.4737 19.6871 22.7488V15.7488C19.6871 15.024 20.2747 14.4363 20.9996 14.4363Z"
      fill={fill ?? theme.blue["300"]}
    />
    <path
      d="M20.9996 29.7488C21.9661 29.7488 22.7496 28.9653 22.7496 27.9988C22.7496 27.0323 21.9661 26.2488 20.9996 26.2488C20.0331 26.2488 19.2496 27.0323 19.2496 27.9988C19.2496 28.9653 20.0331 29.7488 20.9996 29.7488Z"
      fill={fill ?? theme.blue["300"]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1969 6.16164C18.2053 4.89456 19.3839 3.92188 20.9996 3.92188C22.6153 3.92188 23.7939 4.89457 24.8023 6.16165C25.7886 7.401 26.8382 9.21402 28.1362 11.4562L35.3596 23.933C36.6625 26.1833 37.7159 28.0028 38.3014 29.4796C38.8997 30.989 39.1572 32.4996 38.3482 33.9026C37.5393 35.3056 36.103 35.8397 34.497 36.0781C32.9255 36.3114 30.8233 36.3114 28.2228 36.3114H13.7764C11.1759 36.3114 9.07372 36.3114 7.50224 36.0781C5.89619 35.8397 4.45991 35.3056 3.65099 33.9026C2.84206 32.4996 3.09949 30.989 3.69783 29.4796C4.2833 28.0027 5.33662 26.1834 6.63958 23.933L13.863 11.4562C15.161 9.21402 16.2106 7.401 17.1969 6.16164ZM19.2509 7.79624C18.4005 8.8648 17.4464 10.5058 16.0775 12.8702L8.96863 25.1492C7.59484 27.5221 6.64313 29.173 6.13808 30.447C5.63736 31.7101 5.724 32.2427 5.92509 32.5915C6.12618 32.9402 6.54369 33.2821 7.88771 33.4816C9.24331 33.6829 11.1488 33.6864 13.8907 33.6864H28.1085C30.8504 33.6864 32.7559 33.6829 34.1115 33.4816C35.4555 33.2821 35.873 32.9402 36.0741 32.5915C36.2752 32.2427 36.3619 31.7101 35.8611 30.447C35.3561 29.173 34.4044 27.5221 33.0306 25.1492L25.9217 12.8703C24.5528 10.5058 23.5987 8.86481 22.7483 7.79624C21.9055 6.73723 21.4019 6.54688 20.9996 6.54688C20.5973 6.54688 20.0937 6.73723 19.2509 7.79624Z"
      fill={fill ?? theme.blue["300"]}
    />
  </svg>
);

export default withTheme(AlertIcon);
