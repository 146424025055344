declare global {
  interface Window {
    liveChat: Function;
  }
}

const ERROR_CODE = {
  ALREADY_AUTHENTICATED: 1121,
  ALREADY_LOGGED_OUT: 1321
};

const LIVE_CHAT_SCRIPT_ID = "live-chat-script";

// DO NOT TOUCH
// See: https://www.infobip.com/docs/live-chat/getting-started#installation-web-widget
function polyfill(a: any, b: string) {
  a[b] =
    a[b] ||
    function() {
      (a[b].q = a[b].q || []).push(arguments);
    };
  a[b].t = 1 * Date.now();
}

const createScriptTag = (url: string, onLoad: () => void) => {
  const script = document.createElement("script");

  script.type = "text/javascript";
  script.src = url;
  script.async = true;
  script.id = LIVE_CHAT_SCRIPT_ID;
  script.onload = onLoad;

  return script;
};

export class LiveChat {
  static ERROR_CODE = ERROR_CODE;

  static init(
    parentNode: HTMLElement,
    url: string,
    widgetId: string,
    onLoad: () => void,
    onResult?: (error: any, result: any) => void
  ) {
    polyfill(window, "liveChat");

    parentNode.appendChild(
      createScriptTag(url, () => {
        window.liveChat("init", widgetId, onResult);
        onLoad();
      })
    );
  }

  static stop(parentNode: HTMLElement) {
    const liveChat = document.getElementById(LIVE_CHAT_SCRIPT_ID);
    if (liveChat) {
      parentNode.removeChild(liveChat);
    }
  }
}
