import React from "react";
import { CookiesButtonContainer } from "./cookies-styles";

interface CookiesSettingsProps {
  onClick?: () => void;
}

export const CookiesSettings = ({ onClick }: CookiesSettingsProps) => {
  return (
    <CookiesButtonContainer>
      <button
        id="ot-sdk-btn"
        className="ot-sdk-show-settings"
        onClick={onClick}
      ></button>
    </CookiesButtonContainer>
  );
};
