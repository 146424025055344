import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { useWindowSize } from "hooks/use-window-size";
import {
  MarkdownContent,
  PersonalDataTable,
  TableData,
  TableHeadData,
  TableHeader
} from "./sections-styles";

export const PrivacyTable = () => {
  const t = useTranslate();
  const { isMobile } = useWindowSize();

  return isMobile ? (
    <>
      <PersonalDataTable>
        <TableHeader>
          <tr>
            <TableHeadData>
              <MarkdownContent>
                {t(`[privacy_site]section7_table_header1`)}
              </MarkdownContent>
            </TableHeadData>
          </tr>
        </TableHeader>
        <tbody>
          <tr>
            <TableData>
              <MarkdownContent>
                {t(`[privacy_site]section7_table_row1_text1`)}
              </MarkdownContent>
            </TableData>
          </tr>
        </tbody>
      </PersonalDataTable>
      <PersonalDataTable>
        <TableHeader>
          <tr>
            <TableHeadData>
              <MarkdownContent>
                {t(`[privacy_site]section7_table_header2`)}
              </MarkdownContent>
            </TableHeadData>
          </tr>
        </TableHeader>
        <tbody>
          <tr>
            <TableData>
              <MarkdownContent>
                {t(`[privacy_site]section7_table_row1_text2`)}
              </MarkdownContent>
            </TableData>
          </tr>
        </tbody>
      </PersonalDataTable>
    </>
  ) : (
    <PersonalDataTable>
      <TableHeader>
        <tr>
          <TableHeadData>
            <MarkdownContent>
              {t(`[privacy_site]section7_table_header1`)}
            </MarkdownContent>
          </TableHeadData>
          <TableHeadData>
            <MarkdownContent>
              {t(`[privacy_site]section7_table_header2`)}
            </MarkdownContent>
          </TableHeadData>
        </tr>
      </TableHeader>
      <tbody>
        <tr>
          <TableData>
            <MarkdownContent>
              {t(`[privacy_site]section7_table_row1_text1`)}
            </MarkdownContent>
          </TableData>
          <TableData>
            <MarkdownContent>
              {t(`[privacy_site]section7_table_row1_text2`)}
            </MarkdownContent>
          </TableData>
        </tr>
      </tbody>
    </PersonalDataTable>
  );
};
