import { State } from "app/store/types";

export const selectQueryParameters = (state: State) =>
  state.queryParameters || {};

export const selectMedium = (state: State) =>
  selectQueryParameters(state).medium;

export const selectSource = (state: State) =>
  selectQueryParameters(state).source;

export const selectPartnerName = (state: State) =>
  selectQueryParameters(state).partnerName;
