import { SagaIterator } from "redux-saga";
import { put, call, takeLatest, take } from "redux-saga/effects";
import { appLoaded } from "../features/loading/loading-actions";
import { initAnalytics } from "@customer_interactions/application-logger";
import {
  oneTrustCookiesInitialize,
  oneTrustCookiesInitializedActionType
} from "@contact_services/one-trust-cookies";
import { INIT_APP } from "./app-actions";
import { getConfigInit } from "features/configuration/actions/configuration-actions";
import { ConfigModel } from "features/configuration/configuration-types";
import { getURLKey } from "utils/route-utils";
import { getConfigFileByURL } from "features/configuration/configuration-collaborators";
import { isTestEnvironment } from "utils/variable";
import { getGlobalPartnerParametersInit } from "features/global-partner-parameters/actions/global-partner-paramenters-actions";
import { enableDebugMode } from "features/debug-mode";
import { getUrlParameters } from "features/url-parameters";
import { sanitizeObject } from "utils/sanitize-object";
import { getQueryParamValue } from "utils/query-params-utils";
import { setQueryParameters } from "features/query-parameters/actions/query-paramenters-actions";
import { QueryParameters } from "features/query-parameters/query-parameters-types";

function* getNonGlobalQueryParameters() {
  const MEDIUM_QUERY_PARAM = "utm_medium";
  const SOURCE_QUERY_PARAM = "utm_source";
  const PARTNER_NAME_PARAM = "utm_term";

  try {
    const newQueryParams: Partial<QueryParameters> = sanitizeObject({
      medium: getQueryParamValue(MEDIUM_QUERY_PARAM),
      source: getQueryParamValue(SOURCE_QUERY_PARAM),
      partnerName: getQueryParamValue(PARTNER_NAME_PARAM)
    });

    if (Object.keys(newQueryParams).length !== 0) {
      yield put(setQueryParameters(newQueryParams));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* initializeApp(): SagaIterator {
  const brandURL = getURLKey();
  const configuration: ConfigModel = yield call(getConfigFileByURL, brandURL);
  const partnerGATrackingId = configuration.partnerTrackingId;
  const payload = {
    googleAnalyticsPrefix: "customer_space",
    googleAnalyticsTrackingIds: [
      partnerGATrackingId,
      process.env.REACT_APP_GLOBAL_GA_TRACKING_ID
    ],
    appInsightsId: process.env.REACT_APP_CONNECTION_STRING,
    gtmId: process.env.REACT_APP_GTM_ID,
    enabledConsoleLog: true
  };

  if (configuration.isDebuggable) {
    yield put(enableDebugMode());
  }

  if (configuration.persistQueryParameters) {
    yield put(getUrlParameters());
  }

  yield put(initAnalytics(payload));
  yield put(getConfigInit());
  yield put(getGlobalPartnerParametersInit());
  yield call(getNonGlobalQueryParameters);
  yield put(
    oneTrustCookiesInitialize(
      configuration.one_trust_data_domain_id,
      {
        hideCloseButton: true
      },
      isTestEnvironment()
    )
  );
  yield take(oneTrustCookiesInitializedActionType);
  yield put(appLoaded());
}

export default function* main() {
  yield takeLatest(INIT_APP, initializeApp);
}
