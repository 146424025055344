import React from "react";
import {
  FlexContainer,
  FlexContainerExpanded,
  LeftSection,
  RightSection
} from "./header-styles";
import { MenuCollapsed } from "./menu/menu-collapsed";
import { MenuExpanded } from "./menu/menu-expanded";
import { AccountDropdown } from "app/components/account-dropdown/account-dropdown";
import { ContactUs } from "app/components/contact-us/contact-us";
import { FaqsButton } from "app/components/faqs-button/faqs-button";
import { Logo } from "app/components/logo";

import useMenuHeader from "hooks/use-menu-header";
import { LoginButton } from "../shared/login-button/login-button";
import { NAV_DESKTOP_CONTAINER_ID } from "constant";
import { ToggleDebugMode } from "features/debug-mode";
import { HeaderPartnerSection } from "../header-partner-section/header-partner-section";

type HeaderProps = {
  displayMenu?: boolean;
};

export const Header = ({ displayMenu = true }: HeaderProps) => {
  const { isMenuOpened, shouldShowMenuOptions, closeMenu } = useMenuHeader();

  if (!displayMenu) {
    return (
      <FlexContainer>
        <LeftSection data-testid="left-section">
          <HeaderPartnerSection>
            <Logo />
          </HeaderPartnerSection>
        </LeftSection>
        <RightSection>
          <ToggleDebugMode />
        </RightSection>
      </FlexContainer>
    );
  }

  const RightSectionHeader = (
    <RightSection>
      <ToggleDebugMode />
      <FaqsButton />
      <ContactUs />
      {shouldShowMenuOptions && <AccountDropdown />}
      {!shouldShowMenuOptions && <LoginButton />}
    </RightSection>
  );

  return (
    <div id={NAV_DESKTOP_CONTAINER_ID}>
      {isMenuOpened ? (
        <FlexContainerExpanded>
          <MenuExpanded />
          {RightSectionHeader}
        </FlexContainerExpanded>
      ) : (
        <FlexContainer>
          <LeftSection data-testid="left-section">
            <HeaderPartnerSection>
              <Logo onClick={closeMenu} />
            </HeaderPartnerSection>
            <MenuCollapsed />
          </LeftSection>
          {RightSectionHeader}
        </FlexContainer>
      )}
    </div>
  );
};
