import { ActionStandard } from "app/store/types";
import {
  CURRENT_LANGUAGE_ERROR,
  CURRENT_LANGUAGE_SUCCESS
} from "./language-action-types";

export const setCurrentLanguageSuccess = (
  payload: string
): ActionStandard<string> => ({
  type: CURRENT_LANGUAGE_SUCCESS,
  payload
});

export const setCurrentLanguageError = (
  payload: string
): ActionStandard<string> => ({
  type: CURRENT_LANGUAGE_ERROR,
  payload
});
