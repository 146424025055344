import { debounce } from "lodash";
import { trackSearchEvent } from "features/analytics-events/track-search-event";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";
import { CULTURA_GUIDES_ZONES } from "./cultura-guides-collaborators";
import { mapElementClickedName } from "utils/analytics-mapper-utils";
import { trackPageViewEvent } from "features/analytics-events/track-page-view-event";
import { State } from "app/store/types";

const EVENT_DEBOUNCE_TIME = 300;
const COUNTRY_START_POSITION = 7;
const COUNTRY_END_POSITION = 9;
const CHAPTER_START_POSITION = 13;
const COUNTRY_DOWNLOAD_START_POSITION = 35;
const COUNTRY_DOWNLOAD_END_POSITION = 37;

export type CulturaGuidesEvent = {
  detail: {
    payload: {
      url: string;
    };
  };
};

export const handleSelectGuideCreator = (state: State, isLoggedIn: boolean) => (
  e: CulturaGuidesEvent
) => {
  const url = e?.detail?.payload?.url;

  // We're totally tied up to Cultura Guide widget url structure to get the values
  const countryGuide = url
    ? url.substring(COUNTRY_START_POSITION, COUNTRY_END_POSITION).toLowerCase()
    : undefined;

  trackPageViewEvent({
    isLoggedIn,
    state,
    pageName: CULTURA_GUIDES_ZONES.HOME_PAGE,
    isRoutingPage: false,
    countryGuide,
    chapter: "welcome"
  });
};

export const handleSelectChapterCreator = (
  state: State,
  isLoggedIn: boolean
) => (e: CulturaGuidesEvent) => {
  const url = e?.detail?.payload?.url;

  // We're totally tied up to Cultura Guide widget url structure to get the values
  const countryGuide = url
    ? url.substring(COUNTRY_START_POSITION, COUNTRY_END_POSITION).toLowerCase()
    : undefined;
  const chapter = url
    ? url.substring(CHAPTER_START_POSITION, url.length - 1).toLowerCase()
    : undefined;

  trackPageViewEvent({
    isLoggedIn,
    state,
    pageName: CULTURA_GUIDES_ZONES.HOME_PAGE,
    isRoutingPage: false,
    countryGuide,
    chapter
  });
};

export const handleSearch = debounce(
  () =>
    trackSearchEvent(
      mapElementClickedName["search"],
      CULTURA_GUIDES_ZONES.HOME_PAGE
    ),
  EVENT_DEBOUNCE_TIME
);

export const handleBackEvent = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.TABLE_OF_CONTENT,
    mapElementClickedName["back"]
  );

export const handlePrevious = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.BOTTOM,
    mapElementClickedName["previous"]
  );

export const handleNext = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.BOTTOM,
    mapElementClickedName["next"]
  );

export const handleDownload = (e: CulturaGuidesEvent) => {
  const url = e?.detail?.payload?.url;

  // We're totally tied up to Cultura Guide widget url structure to get the values
  const country = url
    ? url
        .substring(
          COUNTRY_DOWNLOAD_START_POSITION,
          COUNTRY_DOWNLOAD_END_POSITION
        )
        .toLowerCase()
    : undefined;

  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.DOWNLOAD,
    mapElementClickedName["download"],
    country
  );
};
