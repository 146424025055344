import { useEffect } from "react";
import { useOutsideClick } from "./use-outside-click";
import { useScrollThreshold } from "./use-scroll-threshold";

const SCROLL_THRESHOLD = 40;

const useMenuContainerRef = (callback: Function) => {
  const { htmlNodeRef: menuContainerRef } = useOutsideClick(callback);
  const { isReached: isScrollReached } = useScrollThreshold(SCROLL_THRESHOLD);

  useEffect(() => {
    if (menuContainerRef.current) {
      const top = isScrollReached ? "0px" : "40px";
      const height = isScrollReached ? "100vh" : "95vh";
      menuContainerRef.current.style.top = top;
      menuContainerRef.current.style.height = height;
    }
  }, [isScrollReached]);

  return { menuContainerRef };
};

export default useMenuContainerRef;
