import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import useMenuHeader from "hooks/use-menu-header";
import {
  MenuButtonsContainer,
  MenuIconStyled,
  MenuLabel
} from "../header-styles";

export const MenuCollapsed = () => {
  const t = useTranslate();
  const { handleMenuClick } = useMenuHeader();

  const menuIconProps = {
    ["data-testid"]: "hamburger-menu"
  };

  return (
    <MenuButtonsContainer onClick={() => handleMenuClick()}>
      <MenuIconStyled {...menuIconProps} />
      <MenuLabel data-testid="menu-label">{t(`[Home]Menu`)}</MenuLabel>
    </MenuButtonsContainer>
  );
};
