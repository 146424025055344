import { ActionStandard } from "app/store/types";
import { SET_SHOW_MODAL, SET_URL } from "./require-login-action-types";

export const setShowModal = (payload: boolean): ActionStandard<boolean> => ({
  type: SET_SHOW_MODAL,
  payload
});

export const setUrl = (payload: string): ActionStandard<string> => ({
  type: SET_URL,
  payload
});
