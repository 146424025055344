import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import { P, SectionSubtitle, Header } from "./sections-styles";
import { IfTranslationExists } from "utils/poeditor-utils";

export const LegalSection = () => {
  const t = useTranslate();
  return (
    <>
      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_title">
        <Header data-testid="privacy-policy-main-title">
          {t("[legal][legal_policy_and_terms]section1_title")}
        </Header>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text1">
        <P>{t("[legal][legal_policy_and_terms]section1_text1")}</P>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text2">
        <P>{t("[legal][legal_policy_and_terms]section1_text2")}</P>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text3">
        <P>{t("[legal][legal_policy_and_terms]section1_text3")}</P>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_subtitle1">
        <SectionSubtitle>
          {t("[legal][legal_policy_and_terms]section1_subtitle1")}
        </SectionSubtitle>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text4">
        <P>{t("[legal][legal_policy_and_terms]section1_text4")}</P>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text5">
        <P>{t("[legal][legal_policy_and_terms]section1_text5")}</P>
      </IfTranslationExists>

      <IfTranslationExists translationKey="[legal][legal_policy_and_terms]section1_text6">
        <P>{t("[legal][legal_policy_and_terms]section1_text6")}</P>
      </IfTranslationExists>

      <Header>{t("[legal][legal_policy_and_terms]section2_title")}</Header>
      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle1")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text1")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text2")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle2")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text3")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text4")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text5")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text6")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text7")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text8")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text9")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text10")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle3")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text11")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text12")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text13")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle4")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text14")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle5")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text15")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle6")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text16")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle7")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text17")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text18")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text19")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle8")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text20")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle9")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text21")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle10")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text22")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle11")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text23")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle12")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text24")}</P>

      <SectionSubtitle>
        {t("[legal][legal_policy_and_terms]section2_subtitle13")}
      </SectionSubtitle>
      <P>{t("[legal][legal_policy_and_terms]section2_text25")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text26")}</P>
      <P>{t("[legal][legal_policy_and_terms]section2_text27")}</P>
    </>
  );
};
