import { useTranslate } from "@customer_interactions/i18n";
import PageHeader from "app/components/shared/page-header/page-header";
import { selectIsAppLoaded } from "features/loading/loading-selectors";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { initializeDynamicContent } from "utils/onetrust-utils";
import {
  CookieSectionContainer,
  CookiesContainer,
  CookiesInfoContainer,
  CookiesIntroText,
  CookiesSectionText,
  CookiesSectionTitle,
  StyledUl
} from "./cookies-styles";
import ReactMarkdown from "react-markdown";
import { fillArrayWithNumbers } from "utils/array-utils";
import { selectIsDebugModeActive } from "features/debug-mode/selectors/debug-mode-selectors";

const INITIAL_NUMBER_SECTION_BEFORE_OT = 1;
const SECTIONS_QUANTITY_BEFORE_OT = 6;
const INITIAL_NUMBER_SECTION_AFTER_OT = 7;
const SECTIONS_QUANTITY_AFTER_OT = 3;

const COOKIES_SECTIONS_BEFORE_OT = fillArrayWithNumbers(
  INITIAL_NUMBER_SECTION_BEFORE_OT,
  SECTIONS_QUANTITY_BEFORE_OT
);
const COOKIES_SECTIONS_AFTER_OT = fillArrayWithNumbers(
  INITIAL_NUMBER_SECTION_AFTER_OT,
  SECTIONS_QUANTITY_AFTER_OT
);

export const CookiesPage = () => {
  const t = useTranslate();
  const isAppLoaded = useSelector(selectIsAppLoaded);
  const isDebugModeActive = useSelector(selectIsDebugModeActive);
  const allowEmptyTranslation = !isDebugModeActive;

  const renderSection = (section: number) => {
    const title = t(
      `[cookies_site]section${section}_title`,
      allowEmptyTranslation
    );
    const text = t(
      `[cookies_site]section${section}_text`,
      allowEmptyTranslation
    );

    if (!title || !text) {
      return null;
    }

    const components = { ul: StyledUl, p: CookiesSectionText };

    return (
      <CookieSectionContainer key={`[cookies_site]section${section}`}>
        <CookiesSectionTitle>{title}</CookiesSectionTitle>
        <ReactMarkdown children={text} components={components} />
      </CookieSectionContainer>
    );
  };

  useEffect(() => {
    if (isAppLoaded) {
      initializeDynamicContent();
    }
  }, [isAppLoaded]);
  return (
    <CookiesContainer>
      <PageHeader
        URLDesktop="/assets/images/cookies-banner-desktop.png"
        URLMobile="/assets/images/cookies-banner-mobile.png"
        title={t("[cookies_site]title")}
        subtitle={t("[cookies_site]sub_title")}
      />
      <CookiesInfoContainer>
        <CookieSectionContainer data-testid="cookies-first-paragraph">
          <ReactMarkdown
            children={t("[cookies_site]intro", true)}
            components={{ p: CookiesIntroText }}
          />
          {COOKIES_SECTIONS_BEFORE_OT.map(section => renderSection(section))}
          <div id="ot-sdk-cookie-policy"></div>
          {COOKIES_SECTIONS_AFTER_OT.map(section => renderSection(section))}
        </CookieSectionContainer>
      </CookiesInfoContainer>
    </CookiesContainer>
  );
};
